import React, { FC } from "react";
import styled from "styled-components";

import { Text } from "../../../uikit";

const Header = styled.div`
    display: flex;
    align-items: center;
`;

const RightLabel = styled(Text).attrs({ variant: "subheading" })`
    color: ${({ theme }) => theme.palette.grey.base};
    margin-left: ${({ theme }) => theme.spacing[4]};
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

type Props = {
    title: string;
    className?: string;
    rightText?: string;
    outlined?: boolean;
};

const MetricsCard: FC<Props> = ({ title, rightText, children, className }) => {
    return (
        <div className={className}>
            <Header>
                <Text as="h2" variant="label">
                    {title}
                </Text>
                {rightText && <RightLabel>{rightText}</RightLabel>}
            </Header>
            <Content>{children}</Content>
        </div>
    );
};

export default styled(MetricsCard)`
    border: ${({ outlined, theme }) => outlined && `1px solid ${theme.palette.grey.lightest}`};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    background-color: ${({ theme }) => theme.palette.white.base};
    padding: ${({ theme }) => theme.spacing[5]};
    height: 100%;
    width: 100%;

    > ${Header} {
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;
