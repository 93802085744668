import React, { useMemo, useCallback, ComponentPropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Merge } from "type-fest";

import { Button } from "@sol/uikit";

import SegmentedControls from "../../../../uikit/SegmentedControls";
import { Scale } from "./units";

type Props = Merge<
    {
        scale: Scale;
        onScaleChange: (scale: Scale) => void;
        onTodayClick?: () => void;
        cardTitleId?: string;
    },
    ComponentPropsWithoutRef<"div">
>;

const Toolbar = ({ scale, onScaleChange, onTodayClick, cardTitleId, ...props }: Props) => {
    const [t] = useTranslation();

    const options = useMemo(() => {
        return [
            { id: "month", label: t("component.home.PedagogicalScenarioCard.Timeline.Toolbar.scale.month") },
            { id: "week", label: t("component.home.PedagogicalScenarioCard.Timeline.Toolbar.scale.week") },
            { id: "day", label: t("component.home.PedagogicalScenarioCard.Timeline.Toolbar.scale.day") },
        ];
    }, [t]);

    const handleScaleChange = useCallback((id: string) => onScaleChange(id as Scale), [onScaleChange]);
    const handleTodayClick = useCallback(() => onTodayClick?.(), [onTodayClick]);

    return (
        <div {...props}>
            <SegmentedControls
                variant="primary"
                data-cy="scale"
                orientation="horizontal"
                onChange={handleScaleChange}
                value={scale}
                title={t("component.home.PedagogicalScenarioCard.Timeline.Toolbar.scale.title")}
                options={options}
                itemDescribedBy={cardTitleId}
            />
            <Button variant="primary" outlined small onClick={handleTodayClick} disabled={!onTodayClick}>
                {t("component.home.PedagogicalScenarioCard.Timeline.Toolbar.today")}
            </Button>
        </div>
    );
};

export default styled(Toolbar)`
    display: flex;
    gap: ${({ theme }) => theme.spacing[3]};

    ${SegmentedControls} {
        box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.palette.purple.lighter};
    }

    > ${Button} {
        margin: 0;
        padding-left: ${({ theme }) => theme.spacing[4]};
        padding-right: ${({ theme }) => theme.spacing[4]};

        &:not(:hover) {
            border-color: ${({ theme }) => theme.palette.purple.lighter};
            color: ${({ theme }) => theme.palette.grey.base};
        }
    }
`;
