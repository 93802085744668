import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

const TargetedDomainBox = styled.div`
    margin-bottom: 18px;
`;

const Card = styled.div`
    flex: 1;
    overflow: auto;
    padding: ${({ theme }) => theme.spacing[5]};
    background: ${({ theme }) => theme.palette.purple.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    h3 {
        --text-color: ${({ theme }) => theme.palette.purple.base};
        margin-bottom: ${({ theme }) => theme.spacing[5]};
    }
`;

type ProfessionalSituationDescription = {
    problematic?: string;
    targetedDomain?: string;
    description?: string;
    className?: string;
};

const ProfessionalSituationDescription = ({
    problematic,
    targetedDomain,
    description,
    className,
}: ProfessionalSituationDescription) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <Card>
                <TargetedDomainBox>
                    <Text as="h3" variant="h4" lines={1}>
                        {t("component.home.PedagogicalScenarioCard.professionalSituationTargetedDomain")}
                    </Text>
                    <Text>{targetedDomain}</Text>
                </TargetedDomainBox>
                <div>
                    <Text as="h3" variant="h4" lines={1}>
                        {t("component.home.PedagogicalScenarioCard.professionalSituationProblematic")}
                    </Text>
                    <Text preserve>{problematic}</Text>
                </div>
            </Card>
            <Card>
                <Text as="h3" variant="h4" lines={1}>
                    {t("component.home.PedagogicalScenarioCard.professionalSituationDescription")}
                </Text>
                <Text preserve>{description}</Text>
            </Card>
        </div>
    );
};

export default styled(ProfessionalSituationDescription)`
    display: flex;
    height: 100%;
    gap: 29px;
    padding-bottom: ${({ theme }) => theme.spacing[5]};

    ${Text} {
        overflow-wrap: break-word;
    }
`;
