import { motion } from "framer-motion";
import { AriaMeterProps, useMeter, useNumberFormatter } from "react-aria";
import { useTranslation } from "react-i18next";

import { Typography } from "@sol/uikit/core/Typography";

export type Props = {
    value: number;
    total: number;
    minValue?: number;
    maxValue?: number;
    showDetails?: boolean;
    progressRingColor?: string;
    labelColor?: string;
} & AriaMeterProps;

export const CircularGauge = ({
    value,
    total,
    showDetails,
    minValue = 0,
    maxValue = 100,
    progressRingColor = "yellow-base",
    labelColor = "blue-base",
    ...props
}: Props) => {
    const [t] = useTranslation();
    const { meterProps } = useMeter(props);

    const size = 190;
    const center = size / 2;
    const strokeWidth = 12;
    const r = center - strokeWidth;
    const c = 2 * r * Math.PI;
    const percentage = (value - minValue) / (maxValue - minValue);
    const offset = c + percentage * c;

    const formatter = useNumberFormatter(props.formatOptions);
    const parts = formatter.formatToParts(value);
    const valueString = parts.find(p => p.type === "integer");
    const unit = parts.find(p => p.type === "unit");

    const progressRingStrokeClasses = `stroke-${progressRingColor}`;
    const labelColorClasses = `text-${labelColor}`;

    return (
        <div className="relative flex items-center justify-center">
            <svg
                {...meterProps}
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                fill="none"
                strokeWidth={strokeWidth}
                role="img"
                aria-label={`${valueString?.value}${unit?.value}`}
            >
                <circle
                    role="presentation"
                    cx={center}
                    cy={center}
                    r={r}
                    strokeOpacity={0.2}
                    strokeDasharray={`${c} ${c}`}
                    strokeLinecap="round"
                    transform={`rotate(0 ${center} ${center})`}
                    className="stroke-grey-lighter"
                />
                <motion.circle
                    initial={{ strokeDashoffset: c }}
                    animate={{ strokeDashoffset: offset }}
                    transition={{
                        duration: 0.6,
                        ease: "easeInOut",
                    }}
                    role="presentation"
                    cx={center}
                    cy={center}
                    r={r}
                    strokeDasharray={c}
                    strokeLinecap="round"
                    transform={`rotate(270 ${center} ${center})`}
                    className={progressRingStrokeClasses}
                />
            </svg>
            <div className="absolute flex h-full w-full flex-col items-center justify-center">
                <Typography variant="h2" className={labelColorClasses}>
                    {valueString?.value}
                    {unit?.value}
                </Typography>
                {showDetails && (
                    <Typography variant="subHeading" className="text-grey-light">
                        {t("component.MetricsCircularProgressbar.sublabel", {
                            value: Math.round((value * total) / 100),
                            total,
                        })}
                    </Typography>
                )}
            </div>
        </div>
    );
};
