import React, { ComponentPropsWithoutRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import ExpansionPanel, { ExpansionPanelDetails, ExpansionPanelSummary } from "src/uikit/ExpansionPanel";
import styled from "styled-components";

import { ISkillEducationalScenario } from "@sol/sdk/skills/ISkillEducationalScenario";
import { Typography } from "@sol/uikit/core/Typography";

const LeftSide = styled(Text)`
    --text-color: ${({ theme }) => theme.palette.purple.base};
`;
const OfficialTitle = styled.span`
    margin-left: ${({ theme }) => theme.spacing[6]};
`;
const RightSide = styled.ul`
    display: flex;
    gap: ${({ theme }) => theme.spacing[5]};
    margin-right: ${({ theme }) => theme.spacing[4]};
`;
const BriefList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
    padding: ${({ theme }) => theme.spacing[5]};
    background: ${({ theme }) => theme.palette.purple.lightest};
    border-top: 1px solid ${({ theme }) => theme.palette.purple.lighter};
`;
const BriefItem = styled.li`
    display: flex;
    justify-content: space-between;
    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: 28px 26px;
    h3 {
        --text-color: ${({ theme }) => theme.palette.purple.base};
    }
`;

type Props = {
    skill: ISkillEducationalScenario;
    className?: string;
} & ComponentPropsWithoutRef<typeof ExpansionPanel>;

const ProfessionalSituationSkillListItem = ({ skill, ...rest }: Props) => {
    const { code, officialTitle, skillLevel1, skillLevel2, skillLevel3 } = skill;

    const [t] = useTranslation();

    const { skillLevels, briefsLevelSkill } = useMemo(() => {
        const skillLevels = [skillLevel1, skillLevel2, skillLevel3];

        return {
            skillLevels,
            briefsLevelSkill: skillLevels.flatMap(skillLevel => {
                const { briefs, level } = skillLevel;
                if (briefs) {
                    const values = Object.values(briefs);
                    return values
                        .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))
                        .map(brief => ({ ...brief, level }));
                }
                return [];
            }),
        };
    }, [skill]);

    const countBriefsByLevel = (briefs: ISkillEducationalScenario["skillLevel1"]["briefs"]) => {
        if (briefs) {
            return Object.values(briefs).length;
        }
        return 0;
    };

    const totalBriefsCount = briefsLevelSkill.length;

    return (
        <li {...rest}>
            <ExpansionPanel htmlTag="div" expanded={totalBriefsCount > 0 ? undefined : false}>
                <ExpansionPanelSummary isDisabled={totalBriefsCount < 1}>
                    <LeftSide as="h2" variant="h4" lines={1}>
                        <span>{code}</span>
                        <OfficialTitle>{officialTitle}</OfficialTitle>
                    </LeftSide>
                    <RightSide>
                        {skillLevels.map(skillLevel => {
                            const { level, briefs, "@id": skillLevelId } = skillLevel;
                            return (
                                <li
                                    key={skillLevelId}
                                    className="flex items-center justify-center gap-2 rounded-lg bg-purple-lighter2 pl-7 pr-1"
                                >
                                    <Typography
                                        variant="subHeading"
                                        className="inline-flex items-center text-purple-base"
                                    >
                                        {t(
                                            "component.home.PedagogicalScenarioCard.ProfessionalSituation.ProfessionalSituationSkillListItem.level",
                                            { level },
                                        )}
                                    </Typography>

                                    <div className="flex aspect-square min-h-[18px] items-center justify-center rounded-full bg-purple-base p-1">
                                        <Typography variant="subHeading" className="inline-flex text-white-base">
                                            {countBriefsByLevel(briefs)}
                                        </Typography>
                                    </div>
                                </li>
                            );
                        })}
                    </RightSide>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails data-cy="pedagogical-scenario-tabs-briefs-list">
                    <BriefList>
                        {briefsLevelSkill.map(brief => {
                            const { title, level, "@id": briefId } = brief;
                            return (
                                <BriefItem key={briefId}>
                                    <Typography tag="h3" variant="h4">
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="subHeading"
                                        className="inline-flex items-center justify-center rounded-lg bg-purple-lighter2 px-12 text-purple-base"
                                    >
                                        {t(
                                            "component.home.PedagogicalScenarioCard.ProfessionalSituation.ProfessionalSituationSkillListItem.level",
                                            { level },
                                        )}
                                    </Typography>
                                </BriefItem>
                            );
                        })}
                    </BriefList>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </li>
    );
};

export default styled(ProfessionalSituationSkillListItem)`
    border: 1px solid ${({ theme }) => theme.palette.purple.lighter};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    ${ExpansionPanelSummary} div:first-child {
        flex-wrap: nowrap;
    }
`;
