import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

interface PutEducationalScenarioItemVariables {
    payload: {
        startDate: string;
        endDate: string;
    };
    educationalScenarioItemId: string;
}

export const putEducationalScenarioItem =
    (api: AxiosInstance) =>
    ({ payload, educationalScenarioItemId }: PutEducationalScenarioItemVariables) =>
        api.put(`/educational_scenario_items/${educationalScenarioItemId}`, payload);

export const usePutEducationalScenarioItemMutation = (
    config: MutateConfig<AxiosResponse, any, PutEducationalScenarioItemVariables>,
) => {
    const api = useApi();
    const putEducationalScenarioItemMutation = useMemo(() => putEducationalScenarioItem(api), [api]);
    const [updatedEducationalScenarioItem, { isLoading }] = useMutation(putEducationalScenarioItemMutation, config);

    return { updatedEducationalScenarioItem, isLoading };
};
