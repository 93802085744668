import first from "lodash/first";
import { ComponentProps, ComponentPropsWithoutRef, useEffect, useState } from "react";
import styled from "styled-components";

import { ISkillItem, useSkills } from "@sol/sdk";
import { Grid, GridColumn } from "@sol/uikit/layouts";

import { Text } from "../../uikit";
import BlockSkeleton from "../skeletons/BlockSkeleton";
import SkillLevelsTabs from "./SkillLevelsTabs";
import SkillsList from "./SkillsList";

type Props = ComponentPropsWithoutRef<"div"> & Pick<ComponentProps<typeof SkillsList>, "filters">;

const SkillsExplorer = ({ filters, className, ...rest }: Props) => {
    const { data, isLoading } = useSkills({
        filters,
    });
    const skills = data?.["hydra:member"];

    const [selectedSkill, setSelectedSkill] = useState<ISkillItem | undefined>(first(skills));

    useEffect(() => {
        if (!skills?.find(skill => skill["@id"] === selectedSkill?.["@id"])) {
            setSelectedSkill(first(skills));
        }
    }, [skills]);

    if (isLoading) {
        return (
            <Grid {...rest}>
                <GridColumn col={12}>
                    <BlockSkeleton />
                </GridColumn>
            </Grid>
        );
    }

    return (
        <Grid className={`divide-x-2 divide-grey-lightest ${className}`} {...rest}>
            <GridColumn col={3}>
                <SkillsList items={skills} selectedItem={selectedSkill?.["@id"]} onSelect={setSelectedSkill} />
            </GridColumn>
            {selectedSkill ? (
                <GridColumn
                    as={SkillLevelsTabs}
                    col={9}
                    skill={selectedSkill}
                    tabsVariant="secondary"
                    tabButtonVariant="secondary"
                />
            ) : undefined}
        </Grid>
    );
};

export default styled(SkillsExplorer)`
    height: 490px;
    gap: 0;
    grid-auto-rows: auto;

    > :first-child {
        > ${Text} {
            padding: ${({ theme }) => theme.spacing[5]};
        }
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    ${SkillsList} {
        overflow: auto;
        flex: 1;
        min-height: 0;
    }

    ${SkillLevelsTabs} {
        padding-top: 16px;
        overflow: hidden;
        background: ${({ theme }) => theme.palette.white.base};
        border-left: 2px solid ${({ theme }) => theme.palette.grey.lightest};
    }

    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;
