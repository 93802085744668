import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const STATUS_LABELS = {
    invited: {
        name: "admin.users.input.selectStatus.invited",
        color: "#2196F3",
    },
    inactive: {
        name: "admin.users.input.selectStatus.inactive",
        color: "#F44336",
    },
    active: {
        name: "admin.users.input.selectStatus.active",
        color: "#4CAF50",
    },
};

type Props = {
    component?: string;
    status: keyof typeof STATUS_LABELS;
};

const UserStatusLabel = ({ component = "span", status, ...rest }: Props) => {
    const [t] = useTranslation();
    return React.createElement(component, rest, t(STATUS_LABELS[status].name));
};

export default styled(UserStatusLabel)`
    color: ${({ status }) => STATUS_LABELS[status].color};
`;
