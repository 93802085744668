/* eslint-disable react/display-name */
import isUndefined from "lodash/isUndefined";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourcesCard from "src/components/home/ResourcesCard";
import WelcomeUser from "src/components/shared/WelcomeUser";
import styled from "styled-components";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { Feature, useCheckFeatureFlag } from "@sol/features";
import { useRouter } from "@sol/routing";
import { IUserSettings } from "@sol/sdk";
import { Grid, GridColumn } from "@sol/uikit/layouts/Grid";
import { useAnchor, useScrollSpy } from "@sol/utils";

import AnalyticsPage from "../components/AnalyticsPage";
import ClassroomMetricsSection from "../components/ClassroomMetricsSection";
import ClassroomCard from "../components/home/ClassroomCard";
import FrameworksCard from "../components/home/FrameworksCard";
import HomeNavigation from "../components/home/HomeNavigation";
import LearnersCard from "../components/home/LearnersCard";
import ClassroomBriefsCard from "../components/home/MyBriefsCard";
import PedagogicalScenarioCard from "../components/home/PedagogicalScenarioCard";
import AuthenticatedPageLayout from "../components/layouts/AuthenticatedPageLayout";
import NoClassroomRedirectWrapper from "../components/layouts/NoClassroomRedirectWrapper";
import MetaTitle from "../components/MetaTitle";
import useScroll from "../hooks/useScroll";
import theme from "../uikit/theme";

const HomeGrid = styled(Grid)`
    padding: 0 ${({ theme }) => theme.spacing[4]};

    ${WelcomeUser} {
        margin-bottom: ${({ theme }) => theme.spacing[5]};
    }

    > :last-child > section + section {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

const CARD_FEATURE_MAP = {
    classroom: Feature.HOMEPAGE_CLASSROOM_CARD,
    brief: Feature.HOMEPAGE_BRIEFS_CARD,
    statistic: Feature.HOMEPAGE_METRICS_CARD,
    learner: Feature.HOMEPAGE_LEARNERS_CARD,
    framework: Feature.HOMEPAGE_FRAMEWORKS_CARD,
    resource: Feature.HOMEPAGE_RESOURCES_CARD,
    scenario: Feature.HOMEPAGE_SCENARIO_CARD,
};

const ClassroomHomePageView = () => {
    const [t] = useTranslation();
    const { activeClassroom: classroom, isLoading } = useActiveClassroom();
    const checkFeatureFlag = useCheckFeatureFlag();
    const { user, refetchSettings, settings: userSettings } = useAuthenticatedUser();
    const scrollEl = useScroll();
    const learners = classroom ? classroom.learners ?? [] : user.learnersAsTutor;
    const navbarHeight = theme.navbarHeight;

    const homecards = useMemo(() => {
        const settings = userSettings?.homecards ?? [];

        // We filter settings provided by the API to match
        // frontend capabilities. It allow us to evolve API
        // or UI independently
        return [
            ...settings?.filter(card => {
                return checkFeatureFlag(CARD_FEATURE_MAP[card]);
            }),
        ] as IUserSettings["homecards"];
    }, [checkFeatureFlag, userSettings]);

    const scrollToCard = (card: string) => {
        if (!homecards) {
            return;
        }

        const element = document.getElementById(CARD_FEATURE_MAP[card as IUserSettings["homecards"][number]]);
        if (element) {
            const cardOffsetTop = element.offsetTop - window.scrollY - navbarHeight;
            scrollEl?.scrollTo({
                top: cardOffsetTop,
                behavior: "smooth",
            });
            element.focus({ preventScroll: true });
        }
    };

    const activeCardIndex = useScrollSpy(scrollEl, homecards?.map(card => CARD_FEATURE_MAP[card]) ?? [], {
        offset: 32 /* card padding */ - navbarHeight,
        initialState: 0,
    });

    // TODO: Move to layout
    const { anchor } = useRouter().state ?? {};

    useAnchor(anchor);

    return (
        <HomeGrid>
            <GridColumn col={1}>
                <HomeNavigation
                    user={user}
                    cards={homecards ?? []}
                    value={isUndefined(activeCardIndex) ? undefined : homecards?.[activeCardIndex]}
                    onChange={scrollToCard}
                    onSave={refetchSettings}
                />
            </GridColumn>
            <GridColumn col={11} data-cy="home-cards">
                <WelcomeUser user={user} message={t("page.home.welcome.welcome")} />
                {homecards?.map(card => {
                    const id = CARD_FEATURE_MAP[card];
                    switch (card) {
                        case "classroom":
                            return <ClassroomCard key={id} id={id} classroom={classroom} showVisio />;
                        case "brief":
                            return <ClassroomBriefsCard key={id} id={id} />;
                        case "statistic":
                            return (
                                <ClassroomMetricsSection
                                    key={id}
                                    id={id}
                                    classroom={classroom}
                                    isTrainer={checkFeatureFlag(Feature.CLASSROOM_METRICS_TRAINER)}
                                    isLearner={!checkFeatureFlag(Feature.CLASSROOM_METRICS_TRAINER)}
                                />
                            );
                        case "learner":
                            return (
                                <LearnersCard
                                    key={id}
                                    id={id}
                                    isPending={isLoading}
                                    learners={learners}
                                    classroom={classroom}
                                />
                            );
                        case "framework":
                            return (
                                <FrameworksCard
                                    key={id}
                                    id={id}
                                    variant={
                                        checkFeatureFlag(Feature.HOMEPAGE_CLASSROOM_SCOPED_FRAMEWORKS)
                                            ? "classroom-scoped"
                                            : "default"
                                    }
                                />
                            );
                        case "resource":
                            return <ResourcesCard key={id} id={id} loading={isLoading} classroom={classroom} />;
                        case "scenario":
                            return (
                                <PedagogicalScenarioCard key={id} id={id} loading={isLoading} classroom={classroom} />
                            );
                        default:
                            throw new Error("Homepage card not supported");
                    }
                })}
                <AnalyticsPage />
            </GridColumn>
        </HomeGrid>
    );
};

const ClassroomHomepage = () => {
    const [t] = useTranslation();

    return (
        <>
            <MetaTitle title={t("page.home.meta.title")} />
            <AuthenticatedPageLayout>
                <NoClassroomRedirectWrapper>
                    <ClassroomHomePageView />
                </NoClassroomRedirectWrapper>
            </AuthenticatedPageLayout>
        </>
    );
};

export default ClassroomHomepage;
