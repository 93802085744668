import { ReactNode, useEffect } from "react";
import useScroll from "src/hooks/useScroll";

import { useNavigationState } from "@sol/persistance";

import { CreateBriefStepWithSummary } from "./constants";

type ChildrenProps = {
    currentStep: CreateBriefStepWithSummary;
    setCurrentStep: (newStep: CreateBriefStepWithSummary) => void;
};

type Props = {
    defaultStep: CreateBriefStepWithSummary;
    children: ({ currentStep, setCurrentStep }: ChildrenProps) => ReactNode;
};

const CreateBriefNavigator = ({ defaultStep, children }: Props) => {
    const [currentStep, setCurrentStep] = useNavigationState("step", defaultStep);

    const scrollElement = useScroll();

    useEffect(() => {
        scrollElement?.scrollTo({ top: 0 });
    }, [currentStep]);

    return <>{children({ currentStep, setCurrentStep })}</>;
};
export default CreateBriefNavigator;
