import React, { FC, useMemo, useRef } from "react";
import CarouselComponent, { ResponsiveType } from "react-multi-carousel";
import styled from "styled-components";

import CarouselNavigation from "./CarouselNavigation";

type Props = {
    slidesPerPage?: number;
    className?: string;
};

const Carousel: FC<Props> = ({ slidesPerPage = 3, children, className }) => {
    const carouselRef = useRef<CarouselComponent>(null);

    const responsiveConfig: ResponsiveType = useMemo(
        () => ({
            desktop: {
                breakpoint: { max: Number.POSITIVE_INFINITY, min: 0 },
                items: slidesPerPage,
                slidesToSlide: slidesPerPage,
                partialVisibilityGutter: 16,
            },
        }),
        [slidesPerPage],
    );

    return (
        <div className={className} data-cy="carousel">
            <CarouselComponent
                ref={carouselRef}
                responsive={responsiveConfig}
                infinite={false}
                arrows={false}
                renderButtonGroupOutside
                customButtonGroup={<CarouselNavigation />}
                keyBoardControl={true}
                itemClass="carousel-item"
            >
                {children}
            </CarouselComponent>
        </div>
    );
};

export default styled(Carousel)`
    position: relative;

    .react-multi-carousel-list {
        margin: 0 calc(-1 * ${({ theme }) => theme.spacing[5]} / 2);
    }

    .carousel-item {
        padding: 0 calc(${({ theme }) => theme.spacing[5]} / 2);
    }
`;
