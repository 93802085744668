import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useActiveClassroom } from "@sol/classrooms";
import { Feature } from "@sol/features";
import { getResourceUuid, useFrameworks } from "@sol/sdk";
import Carousel from "@sol/uikit/Carousel";

import { Text } from "../../uikit";
import SkillExplorer from "../shared/SkillsExplorer";
import { BlockSkeleton } from "../skeletons";
import Card from "./Card";

const Helper = styled(Text).attrs({
    variant: "subheading",
})`
    --text-color: ${({ theme }) => theme.palette.grey.base};
`;

const FrameworkCarouselItem = styled.button<{ selected?: boolean }>`
    height: 56px;
    width: 100%;
    margin: ${({ theme }) => theme.spacing[2]} 0;

    cursor: pointer;

    ${({ theme }) => theme.typography.subheading};

    padding: 0 ${({ theme }) => theme.spacing[5]};

    border: 2px solid ${({ theme, selected }) => (selected ? theme.palette.white.base : theme.palette.yellow.base)};
    background: ${({ theme, selected }) => (selected ? theme.palette.yellow.base : theme.palette.white.base)};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    transition: all 0.2s;

    :active,
    :hover:active {
        background: ${({ theme }) => theme.palette.yellow.base};
        transform: translate(0, 0);
        box-shadow: none;
    }

    :hover {
        box-shadow: 3px 3px 0px ${({ theme }) => theme.palette.yellow.base};
        transform: translate(-3px, -3px);
    }

    :focus {
        outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
    }
`;

type Props = {
    id: Feature;
    variant?: "classroom-scoped" | "default";
};

const FrameworksCard = ({ variant = "default", ...rest }: Props) => {
    const [t] = useTranslation();

    const [frameworkUUID, setFrameworkUUID] = useState<string | undefined>();

    const { activeClassroom } = useActiveClassroom();

    const { data, isLoading } = useFrameworks(
        variant === "classroom-scoped"
            ? {
                  filters: {
                      classrooms: activeClassroom && getResourceUuid(activeClassroom),
                  },
                  enabled: activeClassroom,
              }
            : {
                  filters: {
                      restricted: true,
                  },
              },
    );

    const frameworks = data?.["hydra:member"];

    useEffect(() => {
        if (frameworks && frameworks.length > 0) {
            setFrameworkUUID(getResourceUuid(frameworks[0]));
        }
    }, [frameworks]);

    return (
        <Card
            title={t(`component.home.FrameworksCard.${variant}.title`)}
            helper={
                frameworks && (
                    <Helper>{t(`component.home.FrameworksCard.frameworksCount`, { count: frameworks.length })}</Helper>
                )
            }
            data-cy={`frameworks-card ${variant}`}
            {...rest}
        >
            {isLoading ? (
                <BlockSkeleton />
            ) : (
                frameworks && (
                    <>
                        <Carousel slidesPerPage={4} key={JSON.stringify(data)}>
                            {frameworks.map(framework => {
                                return (
                                    <FrameworkCarouselItem
                                        selected={getResourceUuid(framework) === frameworkUUID}
                                        key={framework["@id"]}
                                        onClick={() => setFrameworkUUID(getResourceUuid(framework))}
                                    >
                                        <Text as="span" variant="subheading" lines={2}>
                                            {framework.title}
                                        </Text>
                                    </FrameworkCarouselItem>
                                );
                            })}
                        </Carousel>
                        <SkillExplorer
                            filters={{
                                frameworks: frameworkUUID,
                            }}
                        />
                    </>
                )
            )}
        </Card>
    );
};

export default styled(FrameworksCard)`
    > ${BlockSkeleton} {
        height: 516px; // Size of card content when loaded to prevent cumulative layout shift
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }

    ${Carousel} {
        margin-bottom: ${({ theme }) => theme.spacing[7]};
    }
`;
