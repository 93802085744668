import { DatumValue, Point, ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";
import { TFunction } from "i18next";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { IDatavizLineResource, useDataviz } from "@sol/sdk";

import { BlockSkeleton } from "../skeletons";
import { DatavizFiltersState } from "./useDatavizFilters";

const ErrorContainer = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.purple.lightest};
    height: 500px;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;

const ColoredText = styled(Text)<{ colorVariant: string }>`
    color: ${({ colorVariant }) => colorVariant};

    ${Text} {
        font-weight: 700;
    }
`;

type TooltipProps = {
    slice: {
        id: DatumValue;
        points: Point[];
        date: string | number;
    };
    mode: DatavizFiltersState["mode"];
    className?: string;
};

const Tooltip = styled(({ slice, mode, className }: TooltipProps) => {
    const [t] = useTranslation();
    const { points, date } = slice;

    return (
        <div className={className}>
            <Text>
                {t("component.dataviz.ClassroomDataviz.tooltip.date", {
                    date: new Date(date),
                })}
            </Text>
            <ul>
                {points.map(point => {
                    const found = mode.values.find(value => value.action === point.serieId);

                    if (found) {
                        return (
                            <li key={point.id}>
                                <ColoredText as="span" colorVariant={found.color}>
                                    {t(found.label)}:{" "}
                                    <ColoredText as="span" variant="subheading" colorVariant={found.color}>
                                        {point.data.yFormatted}
                                    </ColoredText>
                                </ColoredText>
                            </li>
                        );
                    }
                })}
            </ul>
        </div>
    );
})`
    background-color: ${({ theme }) => theme.palette.white.base};
    padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[4]}`};
    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;

const mapDatavizToNivoLine = (line: IDatavizLineResource, mode: DatavizFiltersState["mode"], t: TFunction) => {
    const defaultColor = "pink";
    const defaultLabel = "undefined label";
    const found = mode.values.find(value => value.action === line.id);

    return {
        ...line,
        data: line.data.map(({ x, y }) => ({ x: format(new Date(x), "yyyy-MM-dd"), y })),
        color: found ? found.color : defaultColor,
        label: found ? t(found.label) : defaultLabel,
    };
};

type Props = {
    filters: Omit<DatavizFiltersState, "cumulatedNumber"> & {
        action: IDatavizLineResource["id"][];
        cumulatedNumber: boolean;
    };
    className?: string;
};

const ClassroomDataviz = ({ className, filters }: Props) => {
    const [t] = useTranslation();

    const { data, error } = useDataviz({ filters });

    const datavizLines = (data && data["hydra:member"].map(line => mapDatavizToNivoLine(line, filters.mode, t))) || [];
    let children: ReactNode;

    if (data) {
        children = (
            <ResponsiveLine
                data={datavizLines}
                curve="monotoneX"
                colors={datavizLines.map(line => line.color)}
                margin={{ top: 40, right: 80, bottom: 50, left: 80 }}
                xScale={{ type: "time", format: "%Y-%m-%d" }}
                yScale={{ type: "linear", min: 0, max: "auto" }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: t(filters.mode.yAxisLabel),
                    legendOffset: -50,
                    legendPosition: "middle",
                }}
                axisBottom={{
                    format: value => t("component.dataviz.ClassroomDataviz.bottomAxis.date", { date: new Date(value) }),
                    legend: t("component.dataviz.ClassroomDataviz.bottomAxis.time"),
                    tickValues: "every month",
                    legendOffset: 40,
                    legendPosition: "middle",
                }}
                lineWidth={4}
                enablePoints={false}
                enableSlices="x"
                pointLabelYOffset={-12}
                useMesh={true}
                sliceTooltip={({ slice }) => (
                    <Tooltip mode={filters.mode} slice={{ ...slice, date: slice.points[0].data.xFormatted }} />
                )}
                legends={[
                    {
                        data: datavizLines,
                        anchor: "bottom-right",
                        direction: "column",
                        justify: false,
                        translateX: -30,
                        translateY: -40,
                        itemsSpacing: 0,
                        itemDirection: "left-to-right",
                        itemWidth: 200,
                        itemHeight: 20,
                        itemOpacity: 1,
                        symbolSize: 12,
                        symbolShape: "circle",
                        effects: [
                            {
                                on: "hover",
                                style: {
                                    itemBackground: "rgba(0, 0, 0, .03)",
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        );
    } else if (error) {
        children = (
            <ErrorContainer>
                <Text>{t("component.dataviz.ClassroomDataviz.error")}</Text>
            </ErrorContainer>
        );
    } else {
        children = <BlockSkeleton />;
    }

    return <div className={className}>{children}</div>;
};

export default styled(ClassroomDataviz)`
    max-width: 1100px;
    height: 500px;
    width: 1100px;
`;
