import { ComponentPropsWithoutRef } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Warning } from "@sol/icons";
import { Button } from "@sol/uikit";

type Props = {
    start: Date;
    end: Date;
    children: string;
    aggregated?: boolean;
    zoomNeeded?: boolean;
    selected?: boolean;
} & ComponentPropsWithoutRef<typeof Button>;

const Content = styled.span`
    display: flex;
    flex-direction: column-reverse;
    align-items: start;
`;

const Dates = styled(Text)`
    --text-color: ${({ theme }) => theme.palette.grey.light};
`;

const Label = styled(Text)``;

const Alert = styled(Warning)`
    --icon-color: ${({ theme }) => theme.palette.black.base};
    --icon-bg-color: ${({ theme }) => theme.palette.yellow.base};
`;

const Helpers = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing[3]};
`;

const PedagogicalScenarioTimelineItem = ({
    start,
    end,
    aggregated = false,
    zoomNeeded = false,
    selected,
    children,
    ...props
}: Props) => {
    const [t] = useTranslation();
    const titleId = useId();

    return (
        <Button {...props} outlined variant="primary" aria-current={!!selected} aria-labelledby={titleId}>
            <Content>
                <Label as="span" variant="subheading" lines={1} textAlign="left" id={titleId}>
                    {children}
                </Label>
                {!aggregated && (
                    <Helpers>
                        <Dates
                            as="span"
                            variant="label-small"
                            lines={1}
                            textAlign="left"
                            aria-label={t(
                                "component.home.PedagogicalScenarioCard.PedagogicalScenarioTimelineItem.a11y",
                                {
                                    from: start,
                                    to: end,
                                },
                            )}
                        >
                            {t("component.home.PedagogicalScenarioCard.PedagogicalScenarioTimelineItem.dates", {
                                from: start,
                                to: end,
                            })}
                        </Dates>
                        {zoomNeeded && <Alert circle size={12} />}
                    </Helpers>
                )}
            </Content>

            {/* {aggregated ? <Magnifier /> : null} */}
        </Button>
    );
};

export default styled(PedagogicalScenarioTimelineItem)`
    --border-size: ${({ selected, theme }) => (selected ? theme.border.large : theme.border.small)};
    --hover-shadow: 0px 0px 0px 0px transparent;
    --padding: calc(${({ theme }) => theme.spacing[4]} - var(--border-size));
    --vertical-margin: ${({ theme }) => theme.spacing[2]};

    min-width: calc(141px - var(--border-size) * 2);

    width: 100%;
    height: 58px;
    margin: 0;
    padding: var(--padding);
    background-color: ${({ selected, theme }) => (selected ? theme.palette.purple.lightest : theme.palette.white.base)};
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    border-color: ${({ theme }) => theme.palette.purple.base};

    box-shadow: none;
    justify-content: start;

    &:hover {
        transform: none;
    }

    &:active {
        --border-size: ${({ theme }) => theme.border.large};
    }

    ${Content} {
        position: sticky;
        left: var(--padding);
    }

    ${Label} {
        --text-color: ${({ selected, theme }) => (selected ? theme.palette.black.base : theme.palette.grey.base)};
    }

    &:hover,
    &:active {
        background-color: ${({ theme }) => theme.palette.purple.lightest};

        ${Label} {
            --text-color: ${({ theme }) => theme.palette.black.base};
        }
    }

    &:focus {
        position: relative;
        z-index: ${({ theme }) => theme.zIndex.element + 2};
    }

    cursor: ${({ aggregated }) => (aggregated ? "zoom-in" : "pointer")};
`;
