import isEqual from "lodash/isEqual";
import { transparentize } from "polished";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import StickyBox from "react-sticky-box";
import ApiErrorAlert from "src/components/ApiErrorAlert";
import { Text } from "src/uikit";
import theme from "src/uikit/theme";
import styled from "styled-components";

import { useAuthenticatedUser } from "@sol/authentication";
import { Classroom, Clipboard, Frameworks, Loader, Resource, Statistics, Users, Quote } from "@sol/icons";
import { getResourceUuid, IUserResource, IUserSettings } from "@sol/sdk";
import { useUpdateUserSettings } from "@sol/sdk/users/useUpdateUserSettings";
import { Button } from "@sol/uikit/buttons";
import { Grid, GridColumn } from "@sol/uikit/layouts/Grid";

import SegmentedControls, { SegmentedControlsItem } from "../../../uikit/SegmentedControls";
import BlurredModal, { BlurredModalContainer } from "../../shared/BlurredModal";
import HomeNavigationDnd from "./HomeNavigationDnd";

const Modal = styled(BlurredModal)`
    > ${BlurredModalContainer} {
        width: fit-content;
        margin: 0;
    }
`;

const ModalContent = styled(Grid)`
    display: flex;

    ${GridColumn} {
        display: flex;
        flex-direction: column;
    }

    ${HomeNavigationDnd} {
        margin-left: -${({ theme }) => theme.spacing[6]};
    }
`;

const Heading = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${({ theme }) => theme.spacing[5]};
`;

const CARDS_OPTIONS_MAP = {
    classroom: {
        icon: <Classroom />,
        translationKey: "page.home.navigation.options.promo",
    },
    brief: {
        icon: <Clipboard />,
        translationKey: "page.home.navigation.options.briefs",
    },
    statistic: {
        icon: <Statistics />,
        translationKey: "page.home.navigation.options.stats",
    },
    learner: {
        icon: <Users />,
        translationKey: "page.home.navigation.options.learners",
    },
    framework: {
        icon: <Frameworks />,
        translationKey: "page.home.navigation.options.frameworks",
    },
    resource: {
        icon: <Resource />,
        translationKey: "page.home.navigation.options.resources",
    },
    scenario: {
        icon: <Quote />,
        translationKey: "page.home.navigation.options.scenario",
    },
};

type Props = {
    className?: string;
    user: IUserResource;
    cards: IUserSettings["homecards"];
    value?: string;
    onChange: (card: string) => void;
    onSave: () => void;
};

const HomeNavigation = ({ className, cards, onSave, onChange, value }: Props) => {
    const [t] = useTranslation();
    const { user } = useAuthenticatedUser();
    const submitButtonRef = useRef(null);

    const [modalVisible, setModalVisible] = useState(false);

    const options = useMemo(() => {
        return cards.map(card => {
            const { icon, translationKey } = CARDS_OPTIONS_MAP[card];
            return {
                id: card,
                icon: icon as ReactNode,
                label: t(translationKey) as ReactNode,
            };
        });
    }, [cards, t]);

    const [navOrder, setNavOrder] = useState(options);

    const {
        putUserSettings,
        error: updateUserSettingsError,
        reset: resetErrorMessage,
        isLoading: isUpdatingUserSettings,
    } = useUpdateUserSettings({});

    const onSubmit = async () => {
        const newHomeCards = navOrder.map(item => item?.id);

        if (isEqual(newHomeCards, cards)) {
            return setModalVisible(false);
        }

        const uuid = getResourceUuid(user);
        const updateSettings = await putUserSettings({
            uuid,
            user: user["@id"],
            homecards: newHomeCards,
        });
        if (updateSettings) {
            onSave();
            setModalVisible(false);
        }
    };

    return (
        // 16px equals to the layout padding top + navbar height
        <StickyBox offsetTop={16 + theme.navbarHeight}>
            <div className={className}>
                <SegmentedControls
                    data-cy="home-navigation"
                    orientation="vertical"
                    onChange={onChange}
                    value={value}
                    title={t("page.home.navigation.title")}
                    options={options}
                />

                <Button
                    data-cy="open-home-settings"
                    variant="primary"
                    outlined
                    small
                    onClick={() => setModalVisible(true)}
                >
                    {t("page.home.navigation.options.settings")}
                </Button>
            </div>

            <Modal
                visible={modalVisible}
                onClose={() => {
                    setNavOrder(options);
                    setModalVisible(false);
                }}
            >
                <ModalContent>
                    <GridColumn col={1}>
                        <Heading>
                            <Text as="span" variant="h4">
                                {t("page.home.modal.title")}
                            </Text>
                            <Text as="p">{t("page.home.modal.subTitle")}</Text>
                        </Heading>
                        <ModalBody>
                            <HomeNavigationDnd
                                navOrder={navOrder}
                                setNavOrder={navOrder => setNavOrder(() => navOrder)}
                            />
                            <Button
                                ref={submitButtonRef}
                                variant="success"
                                outlined
                                small
                                loading={isUpdatingUserSettings}
                                onClick={onSubmit}
                                data-cy="save-home-settings"
                            >
                                {isUpdatingUserSettings ? <Loader /> : t("page.home.modal.confirm")}
                            </Button>
                        </ModalBody>
                    </GridColumn>
                </ModalContent>
            </Modal>

            <ApiErrorAlert
                error={updateUserSettingsError}
                dissmiss={() => resetErrorMessage()}
                triggerElement={submitButtonRef}
            />
        </StickyBox>
    );
};

export default styled(HomeNavigation)`
    display: flex;
    flex-direction: column;

    ${SegmentedControls} {
        background-color: ${({ theme }) => theme.palette.white.base};
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        box-shadow: 0 0 0 4px ${({ theme }) => transparentize(0.99, theme.palette.black.base)};
    }

    > ${SegmentedControlsItem} {
        margin-top: ${({ theme }) => theme.spacing[5]};
        background: ${({ theme }) => theme.palette.white.base};
    }

    ${Button} {
        margin-top: ${({ theme }) => theme.spacing[3]};
    }
`;
