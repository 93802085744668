import React from "react";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Error } from "@sol/icons";

// DEBT dead-code there is a ts component which is the same (src/uikit/FieldErrorMessage.tsx)

// DEBT deprecated
type Props = {
    message?: string;
    className?: string;
};

const FieldErrorMessage = ({ message, className }: Props) => (
    <Text variant="subheading" className={className}>
        <Error />
        {message}
    </Text>
);

export default styled(FieldErrorMessage)`
    color: ${({ theme }) => theme.palette.red.base};
    display: flex;
    align-items: center;
    --icon-color: ${({ theme }) => theme.palette.red.base};

    ${Error} {
        height: 16px;
        width: 16px;
        margin-right: ${({ theme }) => theme.spacing[3]};
        background-color: ${({ theme }) => theme.palette.red.lightest};
    }
`;
