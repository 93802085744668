import React, { FC, useMemo, ComponentProps, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { IClassroomMetrics, IRateMetric } from "@sol/sdk";

import { BlockSkeleton } from "../../skeletons";
import MetricsCard from "../MetricsCard";
import MetricsCardHelper from "../MetricsCard/MetricsCardHelper";
import MetricsCircularProgressbar from "../MetricsCircularProgressbar";
import { ProgressbarColor } from "../MetricsCircularProgressbar/MetricsCircularProgressbar";
import WorksMetrics from "../WorksMetrics";

const MetricsSkeleton = styled(BlockSkeleton)`
    min-height: 280px;
`;

enum MetricsName {
    PROGRESS_PERCENT = "classroomProgression",
    SUCCESS_RATE = "classroomSuccess",
    WORKS_NUMBER = "learnerWorks",
    LEARNER_SUCCESS_RATE = "learnerSuccess",
}

type Props = {
    className?: string;
    showHelper: boolean;
    isLoading?: boolean;
    metrics?: IClassroomMetrics;
};

const LearnerClassroomMetrics = ({ className, showHelper, isLoading, metrics }: Props) => {
    const [t] = useTranslation();

    const createMetricsInfo = useCallback(
        ({
            showDetails,
            metricsName,
            color,
            component: MetricsComponent,
        }: {
            showDetails?: boolean;
            metricsName: MetricsName;
            color?: ProgressbarColor;
            component: FC<ComponentProps<typeof MetricsCircularProgressbar | typeof WorksMetrics>>;
        }) => {
            const i18NPrefix = `component.LearnerClassroomMetrics.${metricsName}`;

            const { total, number: value } = (metrics && (metrics[metricsName] as IRateMetric)) || {
                total: 0,
                number: 0,
            };

            return {
                gridArea: metricsName,
                title: t(`${i18NPrefix}.title`),
                content: t(`${i18NPrefix}.helper`),
                children: (
                    <MetricsCard title={t(`${i18NPrefix}.title`)} outlined>
                        <MetricsComponent
                            value={value}
                            total={total}
                            srOnlyLabel={
                                showDetails &&
                                t(`${i18NPrefix}.a11y`, {
                                    total,
                                    value,
                                })
                            }
                            color={color}
                            showDetails={showDetails}
                        />
                    </MetricsCard>
                ),
            };
        },
        [metrics, t],
    );

    const metricsInfosArray = useMemo(
        () => [
            createMetricsInfo({
                metricsName: MetricsName.PROGRESS_PERCENT,
                color: ProgressbarColor.YELLOW,
                component: MetricsCircularProgressbar,
            }),
            createMetricsInfo({
                metricsName: MetricsName.SUCCESS_RATE,
                component: MetricsCircularProgressbar,
            }),
            createMetricsInfo({
                showDetails: true,
                metricsName: MetricsName.LEARNER_SUCCESS_RATE,
                component: MetricsCircularProgressbar,
            }),
            createMetricsInfo({
                showDetails: true,
                metricsName: MetricsName.WORKS_NUMBER,
                component: WorksMetrics,
            }),
        ],
        [createMetricsInfo],
    );

    if (isLoading) {
        return (
            <div className={className}>
                {metricsInfosArray.map(({ title }) => (
                    <MetricsSkeleton key={title} />
                ))}
            </div>
        );
    }

    return (
        <div className={className}>
            {metricsInfosArray.map(item => (
                <MetricsCardHelper key={item.title} title={item.title} content={item.content} visible={showHelper}>
                    {item.children}
                </MetricsCardHelper>
            ))}
        </div>
    );
};

export default styled(LearnerClassroomMetrics)`
    display: grid;
    grid-template-columns: repeat(4, 256px);
    grid-gap: ${({ theme }) => theme.spacing[9]} ${({ theme }) => theme.spacing[5]};
`;
