import { cva } from "class-variance-authority";
import React, { RefObject, useRef } from "react";
import { AriaButtonOptions, useButton } from "react-aria";
import { useTranslation } from "react-i18next";

import { Order } from "@sol/uikit/core/icons";

const baseClasses = [
    "focus-classes",
    "group",
    "flex",
    "cursor-pointer",
    "items-center",
    "rounded-lg",
    "border-0",
    "bg-transparent-base",
    "font-main",
    "outline-none",
];
const sortButtonClasses = cva(baseClasses);

export enum ResourceOrder {
    ASC = "asc",
    DESC = "desc",
}

export type Props = {
    forwardRef?: RefObject<HTMLButtonElement>;
    label: string;
    order: string;
    isActive: boolean;
    className?: string;
} & AriaButtonOptions<"button">;

export const SortButton = ({ forwardRef, label, className, order, isActive, ...props }: Props) => {
    const [t] = useTranslation();
    const ref = forwardRef ?? useRef(null);
    const { buttonProps } = useButton(props, ref);

    const buttonTitle = `${label} - ${t(
        `uikit.molecules.SortButton.a11yTitle.${order === ResourceOrder.DESC ? "descendingSort" : "ascendingSort"}`,
    )}`;

    const textActiveColor = isActive ? "text-purple-base" : "text-black-base";
    const iconActiveColor = isActive ? "fill-purple-base stroke-purple-base" : "fill-black-base";

    return (
        <button {...buttonProps} ref={ref} className={sortButtonClasses({ className })} title={buttonTitle}>
            <span
                className={`text-base font-semibold transition-all duration-200 ease-in-out group-hover:text-purple-base ${textActiveColor}`}
            >
                {label}
            </span>
            <Order
                direction={order === ResourceOrder.DESC ? "down" : "up"}
                className={`${iconActiveColor} group-hover:fill-purple-base group-hover:stroke-purple-base`}
            />
        </button>
    );
};
