import React from "react";
import styled from "styled-components";

import { ISkillEducationalScenario } from "@sol/sdk/skills/ISkillEducationalScenario";

import SkillListItem from "./ProfessionalSituationSkillListItem";

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
    list-style: none;
    padding: 0;
`;

type ProfessionalSituationSkills = {
    skills?: ISkillEducationalScenario[];
    className?: string;
};

const ProfessionalSituationSkills = ({ skills, className }: ProfessionalSituationSkills) => {
    return (
        <div className={className}>
            <List data-cy="pedagogical-scenario-tabs-skill-list">
                {skills?.map(skill => {
                    const {
                        skillLevel1: { skill: skillId },
                    } = skill;
                    return (
                        <SkillListItem data-cy={`pedagogical-scenario-tabs-skill-item`} key={skillId} skill={skill} />
                    );
                })}
            </List>
        </div>
    );
};

export default styled(ProfessionalSituationSkills)`
    padding-bottom: ${({ theme }) => theme.spacing[5]};
`;
