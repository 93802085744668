import React, { useEffect, useRef, useState } from "react";
import { DismissButton, Overlay, usePopover } from "react-aria";
import type { AriaPopoverProps } from "react-aria";
import type { OverlayTriggerState } from "react-stately";

export type PopoverWidth = "fixed" | "double" | "standard";

type PopoverProps = Omit<AriaPopoverProps, "popoverRef"> & {
    children: React.ReactNode;
    state: OverlayTriggerState;
    className?: string;
    triggerRef: React.RefObject<HTMLElement>;
    width: PopoverWidth;
};

export const Popover = ({ children, state, className, triggerRef, width, ...props }: PopoverProps) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    const { popoverProps, underlayProps } = usePopover(
        {
            ...props,
            triggerRef,
            popoverRef,
        },
        state,
    );

    const [popoverWidth, setPopoverWidth] = useState<string>("auto");

    useEffect(() => {
        let widthStyle = "";

        if (width === "fixed" && triggerRef.current) {
            const width = triggerRef.current?.offsetWidth;
            widthStyle = `${width}px`;
        }

        if (width === "double" && triggerRef.current) {
            const width = triggerRef.current?.offsetWidth;
            widthStyle = `${width * 2}px`;
        }

        setPopoverWidth(widthStyle);
    }, [triggerRef, width]);

    return (
        <Overlay>
            <div {...underlayProps} className="fixed inset-0 overflow-hidden" />
            <div {...popoverProps} ref={popoverRef} style={{ ...popoverProps.style, width: popoverWidth }}>
                <DismissButton onDismiss={state.close} />
                {children}
                <DismissButton onDismiss={state.close} />
            </div>
        </Overlay>
    );
};
