import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import { Tab, TabPanel, Tabs } from "src/uikit/Tabs";
import styled from "styled-components";

import { Link, Paperclip } from "@sol/icons";
import { EntityTypes, IMediaResource, IMediaSecuredResource, MediaType } from "@sol/sdk";
import { IconButton } from "@sol/uikit";

import AddLinkForm from "../briefs/create/AddLinkForm";
import AddMediaForm from "../briefs/create/AddMediaForm";

const TabName = styled.span`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[3]};
`;

export enum AttachmentType {
    LINK,
    MEDIA,
}

export type MediaResourceType = IMediaSecuredResource | IMediaResource;

const MAX_MEDIA_COUNT = 5;

type Props = {
    resources: MediaResourceType[];
    onResourceAdd: (attachment: MediaResourceType) => void;
    showTitleField?: boolean;
    isMediaSecured?: boolean;
    className?: string;
    titleSectionId?: string;
};

const ResourceInput = ({
    resources,
    onResourceAdd,
    showTitleField = false,
    isMediaSecured = false,
    className,
    titleSectionId,
}: Props) => {
    const [t] = useTranslation();
    const [selectedTab, setSelectedTab] = useState(AttachmentType.LINK);

    const links = resources.filter(
        media => media["@type"] === EntityTypes.MEDIA && media.type === MediaType.URL,
    ) as IMediaResource[];

    // keep all file medias, a secured media cannot be a link
    const media = resources.filter(
        media => media["@type"] === EntityTypes.MEDIA_SECURED || media.type === MediaType.FILE,
    );

    return (
        <div className={className}>
            <Tabs>
                <Tab
                    tabId={AttachmentType.LINK}
                    isSelected={selectedTab === AttachmentType.LINK}
                    onClick={() => setSelectedTab(AttachmentType.LINK)}
                    data-cy="add-link-tab"
                >
                    <TabName>
                        <Link />
                        <Text as="span"> {t("component.WorkAttachmentForm.addLinkTabTitle")}</Text>
                    </TabName>
                </Tab>
                <Tab
                    tabId={AttachmentType.MEDIA}
                    isSelected={selectedTab === AttachmentType.MEDIA}
                    onClick={() => setSelectedTab(AttachmentType.MEDIA)}
                    data-cy="add-media-tab"
                >
                    <TabName>
                        <Paperclip />
                        <Text as="span"> {t("component.WorkAttachmentForm.addAttachmentTabTitle")}</Text>
                    </TabName>
                </Tab>
            </Tabs>

            {selectedTab === AttachmentType.LINK && (
                <TabPanel tabId={AttachmentType.LINK} tabIndex={-1}>
                    <AddLinkForm links={links} onSubmit={onResourceAdd} showTitleField={showTitleField} />
                </TabPanel>
            )}

            {selectedTab === AttachmentType.MEDIA && (
                <TabPanel tabId={AttachmentType.MEDIA} tabIndex={-1}>
                    <AddMediaForm
                        onSubmit={onResourceAdd}
                        disabled={media.length >= MAX_MEDIA_COUNT}
                        isMediaSecured={isMediaSecured}
                        showTitleField={showTitleField}
                        titleSectionId={titleSectionId}
                    />
                </TabPanel>
            )}
        </div>
    );
};

export default styled(ResourceInput)`
    ${TabPanel} {
        padding: ${({ theme }) => `${theme.spacing[7]} ${theme.spacing[9]}`};
    }

    ${IconButton} {
        margin-left: ${({ theme }) => theme.spacing[3]};
    }
`;
