import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useActiveClassroom } from "@sol/classrooms";
import { IClassroomMetrics } from "@sol/sdk";

import ClassroomDatavizFilters from "../../dataviz/ClassroomDatavizFilters";
import { BlockSkeleton } from "../../skeletons";
import MetricsCard from "../MetricsCard";
import MetricsCardHelper from "../MetricsCard/MetricsCardHelper";
import SkillsMetrics from "../SkillsMetrics";

enum MetricsName {
    PROGRESS_PERCENT = "classroomProgression",
    SUCCESS_RATE = "classroomSuccess",
    WORKS_NUMBER = "classroomWorks",
    VALIDATED_SKILLS = "skillsProgression",
}

type Props = {
    className?: string;
    showHelper: boolean;
    isLoading?: boolean;
    metrics?: IClassroomMetrics;
    learnersCount: number;
};

type SharedProps = {
    gridArea: string;
};

const StyledMetricsCardHelper = styled(MetricsCardHelper)<SharedProps>`
    grid-area: ${({ gridArea }) => gridArea};
`;

const StyledBlockSkeleton = styled(BlockSkeleton)<SharedProps>`
    grid-area: ${({ gridArea }) => gridArea};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;

const TrainerClassroomMetrics = ({ className, showHelper, isLoading, metrics, learnersCount }: Props) => {
    const [t] = useTranslation();

    const { activeClassroom } = useActiveClassroom();

    const metricsInfosArray = useMemo(() => {
        return [
            {
                gridArea: MetricsName.VALIDATED_SKILLS,
                title: t(`component.TrainerClassroomMetrics.${MetricsName.VALIDATED_SKILLS}.title`),
                content: t(`component.TrainerClassroomMetrics.${MetricsName.VALIDATED_SKILLS}.helper`),
                children: (
                    <MetricsCard
                        title={t(`component.TrainerClassroomMetrics.${MetricsName.VALIDATED_SKILLS}.title`)}
                        rightText={t(
                            `component.TrainerClassroomMetrics.${MetricsName.VALIDATED_SKILLS}.learnersCount`,
                            {
                                count: learnersCount,
                            },
                        )}
                    >
                        <SkillsMetrics skillsValidations={metrics?.skillsProgression || []} />
                    </MetricsCard>
                ),
            },
        ];
    }, [t, metrics]);

    if (isLoading) {
        return (
            <div className={className}>
                {metricsInfosArray.map(item => (
                    <StyledBlockSkeleton key={item.gridArea} gridArea={item.gridArea} />
                ))}
            </div>
        );
    }

    return (
        <div className={className}>
            {activeClassroom && <ClassroomDatavizFilters classroom={activeClassroom} />}
            {metricsInfosArray.map(item => (
                <StyledMetricsCardHelper
                    key={item.gridArea}
                    gridArea={item.gridArea}
                    title={item.title}
                    content={item.content}
                    visible={showHelper}
                >
                    {item.children}
                </StyledMetricsCardHelper>
            ))}
        </div>
    );
};

export default styled(TrainerClassroomMetrics)`
    ${SkillsMetrics} {
        flex: 1;
        margin-left: -${({ theme }) => theme.spacing[5]};
        margin-right: -${({ theme }) => theme.spacing[5]};
    }
`;
