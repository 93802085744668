import { isBefore, isAfter, clamp, max as dateMax, min as dateMin, isEqual as isDateEqual } from "date-fns";
import { useMotionValue, motion, useTransform, AnimatePresence } from "framer-motion";
import isNull from "lodash/isNull";
import { useRef, useMemo, useState, MouseEvent, useEffect, ReactNode } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDebounceValue, useUpdateEffect } from "usehooks-ts";

import { Chevron, Loading } from "@sol/icons";
import { IconButton } from "@sol/uikit";
import { usePrevious } from "@sol/utils";

import { Text } from "../../../../uikit";
import ScaleCell from "./ScaleCell";
import ScaleGenerator from "./ScaleGenerator";
import SubScaleCell from "./SubScaleCell";
import Toolbar from "./Toolbar";
import { Scale, MONTH, WEEK, DAY, HALF_DAY } from "./units";
import { useTimeline, roundToUnit } from "./useTimeline";

type Props = {
    loading?: boolean;
    date?: Date;
    start: Date;
    end: Date;
    scale: Scale;
    onScaleChange: (scale: Scale) => void;
    items?: {
        id: string | number;
        start: Date;
        end: Date;
        render: () => ReactNode;
    }[];
    cardTitleId?: string;
};

const getTimelineConfigFromScale = (scale: Scale) => {
    switch (scale) {
        case "day":
            return {
                scale: DAY,
                subscale: HALF_DAY,
                unit: HALF_DAY,
                unitLength: 176,
            };
        case "week":
            return {
                scale: WEEK,
                subscale: DAY,
                unit: HALF_DAY,
                unitLength: 24,
            };
        case "month":
        default:
            return {
                scale: MONTH,
                subscale: WEEK,
                unit: DAY,
                unitLength: 12,
            };
    }
};

const Cursor = styled(motion.div)`
    height: 100%;
    background: ${({ theme }) => theme.palette.purple.lighter2};
`;

const Viewport = styled.div<{ unit: number; grabbing?: boolean }>`
    width: 100%;
    position: relative;

    overflow: auto;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    cursor: ${({ grabbing }) => (grabbing ? "grabbing" : "grab")};
    user-select: none;
`;

const UnitCell = styled.div`
    position: absolute;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[5]};
    padding: 0 ${({ theme }) => theme.spacing[8]};

    position: relative;

    &:focus-visible {
        outline: none;
    }
`;

const TimelineScale = styled(ScaleGenerator)``;
const TimelineSubscale = styled(ScaleGenerator)``;
const TimelineUnit = styled.div``;
const TimelineDisabled = styled.div`
    > div {
        position: absolute;
        height: 100%;
        background: ${({ theme }) => theme.palette.purple.lightest};
    }
`;

const LoadingIndicator = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: 50%;

    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    --text-color: ${({ theme }) => theme.palette.purple.base};
    --icon-color: ${({ theme }) => theme.palette.purple.base};

    padding: ${({ theme }) => theme.spacing[2]};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[2]};
`;

const clampInterval = (interval: Interval, bounds: Interval) => ({
    start: clamp(interval.start, bounds),
    end: clamp(interval.end, bounds),
});

const arrowAnimationVariants = {
    visible: {
        scale: 1,
        opacity: 1,
    },
    hidden: {
        scale: 0.5,
        opacity: 0,
    },
};

const Timeline = ({
    date,
    start,
    end,
    items = [],
    scale: currentScale,
    onScaleChange,
    loading,
    cardTitleId,
    ...props
}: Props) => {
    const parentRef = useRef<HTMLDivElement | null>(null);
    const contentContainerRef = useRef<HTMLDivElement>(null);

    const [t] = useTranslation();
    const [grabbing, setGrabbing] = useState(false);
    const [hovered, setHovered] = useState<boolean>(false);

    const cursor = useMotionValue<null | number>(null);

    const { options, disabledIntervals, scale, subscale } = useMemo(() => {
        const { scale, subscale, unit, unitLength } = getTimelineConfigFromScale(currentScale);

        const { start: unitStart, end: unitEnd } = roundToUnit(
            {
                start,
                end,
            },
            unit,
        );

        const subscaleStart = subscale.floor(unitStart);
        const scaleStart = scale.floor(subscaleStart);

        const subscaleEnd = subscale.ceil(unitEnd);
        const scaleEnd = scale.ceil(subscaleEnd);

        const range = {
            start: unit.floor(dateMin([unitStart, subscaleStart, scaleStart])),
            end: unit.ceil(dateMax([unitEnd, subscaleEnd, scaleEnd])),
        };

        const disabledIntervals: Interval[] = [];

        if (isBefore(range.start, start)) {
            disabledIntervals.push(
                roundToUnit(
                    {
                        start: range.start,
                        end: unit.ceil(unit.add(start, -1)),
                    },
                    unit,
                ),
            );
        }

        if (isAfter(range.end, end)) {
            disabledIntervals.push(
                roundToUnit(
                    {
                        start: unit.floor(unit.add(end, 1)),
                        end: range.end,
                    },
                    unit,
                ),
            );
        }

        return {
            scale: {
                unit: scale,
                interval: {
                    start: scaleStart,
                    end: scaleEnd,
                },
            },

            subscale: {
                unit: subscale,
                interval: {
                    start: subscaleStart,
                    end: subscaleEnd,
                },
            },

            options: {
                min: range.start,
                max: range.end,
                unitLength,
                unit: unit,
            },

            disabledIntervals: disabledIntervals.filter(interval => {
                return !isDateEqual(interval.start, interval.end) && !isAfter(interval.start, interval.end);
            }),
        };
    }, [start, end, currentScale]);

    const timeline = useTimeline({
        viewportRef: parentRef,
        ...options,
    });

    const { handleTodayClick, handleNextClick, handlePrevClick } = useMemo(() => {
        const unit = scale.unit;
        const interval = scale.interval;

        return {
            handleTodayClick: () => {
                if (parentRef.current) {
                    parentRef.current.scroll({ left: timeline.getDistance(new Date()), behavior: "smooth" });
                }
                if (contentContainerRef.current) {
                    contentContainerRef.current.focus();
                }
            },

            handleNextClick: () => {
                if (parentRef.current) {
                    const date = timeline.getDate(parentRef.current.scrollLeft);

                    parentRef.current.scroll({
                        left: timeline.getDistance(clamp(unit.add(unit.floor(date), 1), interval)),
                        behavior: "smooth",
                    });
                }
            },

            handlePrevClick: () => {
                if (parentRef.current) {
                    const date = timeline.getDate(parentRef.current.scrollLeft);

                    parentRef.current.scroll({
                        left: timeline.getDistance(clamp(unit.add(unit.floor(date), -1), interval)),
                        behavior: "smooth",
                    });
                }
            },
        };
    }, [parentRef, timeline]);

    const mouseHandlers = useMemo(() => {
        let isScrollingByDragging = grabbing;
        // TODO: kinetic scroll https://github.com/ariya/kinetic/blob/master/2/scroll.js
        return {
            onMouseDown: () => {
                isScrollingByDragging = true;
                setGrabbing(true);
            },

            onMouseUp: () => {
                isScrollingByDragging = false;
                setGrabbing(false);
            },
            onMouseMove: (evt: MouseEvent) => {
                if (isScrollingByDragging && parentRef.current) {
                    parentRef.current.scrollLeft = parentRef.current.scrollLeft - evt.movementX;
                }

                // Get the target
                const target = parentRef.current;

                // Get the bounding rectangle of target
                const rect = target?.getBoundingClientRect();
                // Shift viewport to viewport relative coordinates
                const viewportRelX = evt.clientX - (rect?.x ?? 0);
                // Add Scroll to get timelineRelative coordinates
                const timelineX = viewportRelX + (target?.scrollLeft ?? 0);

                const position = timelineX;
                cursor.set(position);
                setHovered(true);
            },
            onMouseLeave: () => {
                cursor.set(null);
                setGrabbing(false);
                isScrollingByDragging = false;
                setHovered(false);
            },
        };
    }, [timeline, parentRef]);

    const unitOverscannedInterval = timeline.getInterval(true);
    const unitInterval = timeline.getInterval();
    const prevPosition = usePrevious(unitInterval?.start.toString());

    const cursorX = useTransform(cursor, value =>
        isNull(value) ? timeline.unitLength : timeline.getDistance(options.unit.floor(timeline.getDate(value))),
    );

    const isLeftArrowDisabled =
        unitInterval &&
        (isBefore(unitInterval.start, timeline.bounds.start) || isDateEqual(unitInterval.start, timeline.bounds.start));
    const isRightArrowDisabled =
        unitInterval &&
        (isAfter(unitInterval.end, timeline.bounds.end) || isDateEqual(unitInterval.end, timeline.bounds.end));

    // If it's not the first render we focus the timeline  on scale change
    useUpdateEffect(() => {
        contentContainerRef?.current?.focus();
    }, [currentScale]);

    useEffect(() => {
        // on scale change
        if (prevPosition && parentRef.current) {
            // if we were somewhere on the timeline before
            // scroll to this position using the new scale unit
            parentRef.current.scroll({ left: timeline.getDistance(new Date(prevPosition)) });
        }
    }, [currentScale]);

    useEffect(() => {
        if (parentRef.current) {
            parentRef.current.scroll({ left: timeline.getDistance(date ?? new Date()) });
        }
    }, [date]);

    const visibleInterval = timeline.getInterval();
    const [ariaInterval] = useDebounceValue(
        visibleInterval
            ? t(`component.home.PedagogicalScenarioCard.Timeline.a11yVisibleInterval`, visibleInterval)
            : null,
        400,
    );

    const timelineId = useId();

    return (
        <div {...props} id={timelineId}>
            <Toolbar
                role="group"
                scale={currentScale}
                onScaleChange={onScaleChange}
                cardTitleId={cardTitleId}
                onTodayClick={
                    isBefore(Date.now(), timeline.bounds.end) && isAfter(Date.now(), timeline.bounds.start)
                        ? handleTodayClick
                        : undefined
                }
            />
            <p className="sr-only">{ariaInterval}</p>
            <Content ref={contentContainerRef} tabIndex={-1} className="focus-classes rounded-md">
                <Viewport ref={parentRef} {...mouseHandlers} unit={timeline.unitLength} grabbing={grabbing}>
                    <TimelineDisabled style={{ width: timeline.getTotalSize() }}>
                        {disabledIntervals.reduce<ReactNode[]>((acc, curr, index) => {
                            const interval = timeline.round(curr);

                            if (timeline.isVisible(interval)) {
                                return [
                                    ...acc,
                                    <div
                                        key={index}
                                        style={{
                                            left: timeline.getDistance(interval.start),
                                            width: timeline.getDistance(interval),
                                        }}
                                    />,
                                ];
                            }

                            return acc;
                        }, [])}
                    </TimelineDisabled>

                    {hovered && (
                        <Cursor
                            style={{
                                position: "absolute",
                                left: cursorX,
                                width: timeline.unitLength,
                            }}
                        />
                    )}

                    {unitOverscannedInterval && (
                        <>
                            <TimelineScale
                                style={{ width: timeline.getTotalSize() }}
                                {...clampInterval(unitOverscannedInterval, scale.interval)}
                                unit={scale.unit}
                                render={(item: Interval, index: number) => {
                                    const interval = item;

                                    return (
                                        <ScaleCell
                                            key={index}
                                            label={t(
                                                `component.home.PedagogicalScenarioCard.Timeline.${currentScale}.scale`,
                                                { date: interval.start },
                                            )}
                                            style={{
                                                left: timeline.getDistance(interval.start),
                                                width: timeline.getDistance(interval),
                                            }}
                                        />
                                    );
                                }}
                            />

                            <TimelineSubscale
                                style={{ width: timeline.getTotalSize() }}
                                {...clampInterval(unitOverscannedInterval, subscale.interval)}
                                unit={subscale.unit}
                                render={(item: Interval, index: number) => {
                                    const interval = timeline.round(item);

                                    if (currentScale === "day" && new Date(interval.start).getHours() !== 12) {
                                        return null;
                                    }

                                    return (
                                        <SubScaleCell
                                            key={index}
                                            label={t(
                                                `component.home.PedagogicalScenarioCard.Timeline.${currentScale}.subscale`,
                                                { date: interval.start },
                                            )}
                                            style={{
                                                left: timeline.getDistance(interval.start),
                                                width: timeline.getDistance(interval),
                                            }}
                                        />
                                    );
                                }}
                            />
                        </>
                    )}

                    {/* HACK: hold the place for the whole virtualized list width in order to have proper scrolling when using Viewport scroll */}
                    <TimelineUnit style={{ width: timeline.getTotalSize() }}>
                        {items.reduce((prev, { render, ...curr }, index) => {
                            const interval = curr;
                            if (timeline.isVisible(interval, true)) {
                                return [
                                    ...prev,
                                    <UnitCell
                                        key={index}
                                        style={{
                                            left: timeline.getDistance(interval.start),
                                            width: timeline.getDistance(interval),
                                        }}
                                    >
                                        {render()}
                                    </UnitCell>,
                                ];
                            }

                            return prev;
                        }, [])}
                    </TimelineUnit>
                </Viewport>
                <IconButton
                    as={motion.button}
                    variants={arrowAnimationVariants}
                    animate={isLeftArrowDisabled ? "hidden" : "visible"}
                    onClick={handlePrevClick}
                    disabled={isLeftArrowDisabled}
                    data-cy="timeline-scroll-left-button"
                    className="absolute left-0 top-1"
                    aria-controls={timelineId}
                >
                    <Chevron direction="left" />
                    <span className="sr-only">
                        {t("component.home.PedagogicalScenarioCard.Timeline.scrollButtons.a11y.left", {
                            scale: currentScale,
                        })}
                    </span>
                </IconButton>
                <IconButton
                    as={motion.button}
                    variants={arrowAnimationVariants}
                    animate={isRightArrowDisabled ? "hidden" : "visible"}
                    onClick={handleNextClick}
                    disabled={isRightArrowDisabled}
                    data-cy="timeline-scroll-right-button"
                    className="absolute right-0 top-1"
                    aria-controls={timelineId}
                >
                    <Chevron direction="right" />
                    <span className="sr-only">
                        {t("component.home.PedagogicalScenarioCard.Timeline.scrollButtons.a11y.right", {
                            scale: currentScale,
                        })}
                    </span>
                </IconButton>

                <AnimatePresence>
                    {loading ? (
                        <LoadingIndicator
                            transition={{
                                type: "spring",
                            }}
                            transformTemplate={({ y }) => `translateX(-50%) translateY(${y})`}
                            variants={{
                                visible: { opacity: 1, y: 0 },
                                hidden: { opacity: 0, y: 12 },
                            }}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                        >
                            <Loading />

                            <Text variant="label-xs">
                                {t("component.home.PedagogicalScenarioCard.Timeline.loading")}
                            </Text>
                        </LoadingIndicator>
                    ) : null}
                </AnimatePresence>
            </Content>
        </div>
    );
};

export default styled(Timeline)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[6]};

    --scale-height: 25px;
    --subscale-height: 28px;
    --viewport-height: 58px;
    --gap: ${({ theme }) => theme.spacing[2]};

    --subscale-top: calc(var(--scale-height) + var(--gap));
    --unit-top: calc(var(--scale-height) + 2 * var(--gap) + var(--subscale-height));

    ${Viewport} {
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        height: calc(var(--scale-height) + var(--subscale-height) + var(--viewport-height) + 2 * var(--gap));
    }

    ${TimelineDisabled}, ${TimelineScale}, ${TimelineSubscale}, ${TimelineUnit} {
        position: absolute;
        left: 0;
    }

    ${TimelineDisabled}, ${TimelineScale} {
        height: 100%;
        top: 0;
    }

    ${TimelineSubscale} {
        top: var(--subscale-top);
        height: calc(100% - var(--subscale-top));
    }

    ${TimelineUnit} {
        bottom: 0;
        height: var(--viewport-height);
    }

    ${ScaleCell}, ${SubScaleCell}, ${UnitCell} {
        position: absolute;
        height: 100%;
    }

    ${UnitCell} {
        display: flex;
        align-items: center;
    }
`;
