import Link from "next/link";
import { ComponentPropsWithoutRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NoBriefYet from "src/components/briefs/NoBriefYet";
import { BriefOptionsAction } from "src/components/briefs/shared/BriefActionsMenu";
import BriefCard from "src/components/briefs/shared/BriefCard";
import SearchForm from "src/components/shared/SearchForm";
import HttpError from "src/errors/HttpError";
import NetworkError from "src/errors/NetworkError";
import { Text } from "src/uikit";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { useAuthenticatedUser } from "@sol/authentication";
import { useActiveClassroom } from "@sol/classrooms";
import { Feature, FeatureGuard, useCheckFeatureFlag } from "@sol/features";
import { Brief, Plus } from "@sol/icons";
import { Route } from "@sol/routing";
import { BriefStatus, useBriefs } from "@sol/sdk";
import Button from "@sol/uikit/buttons/Button";
import Carousel from "@sol/uikit/Carousel";

// TODO : Find a better way to share those styled components
import { BlockSkeleton } from "../../skeletons";
import Card from "../Card";

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[7]};
`;

const CardPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.white.base};
    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};
    height: 450px;
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
`;

const LoadingCarousel = styled(({ className }: { className?: string }) => (
    <div className={className}>
        <BlockSkeleton />
        <BlockSkeleton />
        <BlockSkeleton />
    </div>
))`
    display: flex;
    align-items: center;

    ${BlockSkeleton} {
        flex: 1;
        min-height: 0;
        height: 448px;

        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        margin-right: ${({ theme }) => theme.spacing[5]};
    }
`;

const CardHelper = () => {
    const [t] = useTranslation();
    const checkFeatureFlag = useCheckFeatureFlag();

    return checkFeatureFlag([Feature.TOPICS_WORK_SUBMIT, Feature.TOPICS_WORK_EVAL]) ? (
        <>
            <FeatureGuard feature={Feature.BRIEFS_CREATE}>
                <Link href={Route.BRIEFS_CREATE} passHref>
                    <Button as="a" filled variant="primary" data-cy="create-brief-cta">
                        {t("page.briefs.create.title")}
                        <Plus />
                    </Button>
                </Link>
            </FeatureGuard>
            <FeatureGuard feature={Feature.TOPICS_WORK_SUBMIT}>
                <Link href={Route.TOPICS} passHref>
                    <Button as="a" filled variant="primary" data-cy="topics-list-cta">
                        {t("page.classrooms.home.briefs.goToAllMyTopics")}
                    </Button>
                </Link>
            </FeatureGuard>
        </>
    ) : (
        <Link href={Route.BRIEFS_EXPLORE} passHref>
            <Button as="a" filled variant="primary" data-cy="briefs-explorer-cta">
                {t("page.classrooms.home.briefs.goToAllClassroomBriefsLabel")}
            </Button>
        </Link>
    );
};

type Props = Omit<ComponentPropsWithoutRef<typeof Card>, "title" | "helper" | "children">;

const MyBriefsCard = (props: Props) => {
    const [searchBrief, setSearchBrief] = useState("");
    const [submittedSearch, setSubmittedSearch] = useState("");
    const [t] = useTranslation();
    const { activeClassroom } = useActiveClassroom();
    const userInfo = useAuthenticatedUser();
    const checkFeatureFlag = useCheckFeatureFlag();
    const canCreateBrief = checkFeatureFlag(Feature.BRIEFS_CREATE);

    const briefQueryFilter = {
        classrooms: checkFeatureFlag(Feature.CLASSROOM_CREATE) ? activeClassroom : undefined,
        topicLearners: checkFeatureFlag(Feature.TOPICS_WORK_SUBMIT) ? userInfo.user : undefined,
        topicClassrooms: checkFeatureFlag(Feature.TOPICS_WORK_SUBMIT) ? activeClassroom : undefined,
        status: BriefStatus.VALIDATED,
        title: submittedSearch,
    };

    const {
        data: briefs,
        isLoading,
        error,
        refetch: refetchBriefs,
    } = useBriefs({
        filters: briefQueryFilter,
        orderBy: "createdAt",
        pagination: {
            page: 1,
            perPage: 5,
        },
    });

    if (error) {
        if (error?.response) {
            throw new HttpError(error?.response);
        } else {
            throw new NetworkError(error);
        }
    }

    const allBriefs = briefs ? briefs["hydra:member"] : [];

    const carouselKey = useMemo(() => uuid(), [briefs]);

    return (
        <Card
            {...props}
            data-cy="my-briefs-card"
            title={t("page.classrooms.home.briefs.title")}
            helper={<CardHelper />}
        >
            <Header>
                <SearchForm
                    searchFieldId="home-card-brief-filtering"
                    onSubmit={() => setSubmittedSearch(searchBrief)}
                    onReset={() => {
                        setSearchBrief("");
                        setSubmittedSearch("");
                    }}
                    label={t("component.home.MyBriefsCard.search.label")}
                    value={searchBrief}
                    onChange={setSearchBrief}
                    srOnly={t("component.home.MyBriefsCard.search.sr-only")}
                />

                {canCreateBrief && (
                    <Link href={Route.BRIEFS} passHref>
                        <Button as="a" variant="primary" outlined data-cy="view-all-briefs-cta">
                            {t("page.classrooms.home.briefs.goToAllMyBriefsLabel")}
                        </Button>
                    </Link>
                )}
            </Header>
            {isLoading ? (
                <LoadingCarousel />
            ) : allBriefs.length > 0 ? (
                <Carousel key={carouselKey}>
                    {allBriefs.map(brief => (
                        <BriefCard
                            key={brief["@id"]}
                            brief={brief}
                            onAction={action => {
                                if (action === BriefOptionsAction.DELETE) {
                                    refetchBriefs();
                                }
                            }}
                        />
                    ))}
                    <CardPlaceholder>
                        <Link href={Route.BRIEFS} passHref>
                            <Button outlined as="a" variant="primary" data-cy="last-carousel-item-cta">
                                {t("page.classrooms.home.briefs.goToAllMyBriefsLabel")}
                            </Button>
                        </Link>
                    </CardPlaceholder>
                </Carousel>
            ) : (
                <NoBriefYet>
                    {canCreateBrief ? (
                        <>
                            <Text>{t("component.briefs.BriefListView.noBriefCallToActionAsTrainer")}</Text>{" "}
                            <div>
                                <Link href={Route.BRIEFS_CREATE} passHref>
                                    <Button filled variant="primary" as="a">
                                        {t("component.briefs.BriefListView.createBrief")}
                                        <Brief />
                                    </Button>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <Text>{t("component.briefs.BriefListView.noBriefCallToAction")}</Text>
                    )}
                </NoBriefYet>
            )}
        </Card>
    );
};

export default styled(MyBriefsCard)``;
