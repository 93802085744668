import isArray from "lodash/isArray";
import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { IClassroomUser } from "@sol/sdk/classrooms/IClassroom";
import { IUserResource } from "@sol/sdk/users/IUserResource";
import { getResourceUuid } from "@sol/sdk/utils/getResourceUuid";
import { getUserDisplayName } from "@sol/sdk/utils/getUserDisplayName";

import TextSkeleton from "../..//components/skeletons/TextSkeleton";
import UserAvatar from "../../components/shared/UserAvatar";
import IconSkeleton from "../../components/skeletons/IconSkeleton";
import List from "../../uikit/List/List";
import ListItem from "../../uikit/List/ListItem";
import Text from "../../uikit/Text";

const Loader = styled.div`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[6]}`};

    ${TextSkeleton} {
        margin-left: ${({ theme }) => theme.spacing[6]};
    }
`;

type ListItemProps = {
    user: IUserResource;
    onClick?: () => void;
    selected: boolean;
    showUserAvatar: boolean;
} & ComponentProps<typeof ListItem>;

const UserListItem = styled(({ user, onClick, selected, showUserAvatar, ...rest }: ListItemProps) => {
    return (
        <ListItem
            as="button"
            onClick={onClick}
            title={getUserDisplayName(user)}
            {...rest}
            selected={selected}
            role="tab"
            aria-selected={selected}
        >
            {showUserAvatar && <UserAvatar user={user} />}
            <Text variant="p" lines={1} textAlign="left" as="span">
                {getUserDisplayName(user)}
            </Text>
        </ListItem>
    );
})`
    button& {
        height: ${({ showUserAvatar }) => (showUserAvatar ? 80 : 48)}px;

        display: flex;
        align-items: center;

        padding: ${({ showUserAvatar, theme }) => `0 ${theme.spacing[showUserAvatar ? 6 : 5]}`};
    }

    ${UserAvatar} {
        margin-right: ${({ theme }) => theme.spacing[5]};
        --bg-color: ${({ theme, selected }) => (selected ? theme.palette.white.base : theme.palette.purple.base)};
        --text-color: ${({ theme, selected }) => (selected ? theme.palette.purple.base : theme.palette.white.base)};
    }

    ${Text}:not(${UserAvatar}) {
        flex: 1;
        word-break: break-all;
    }
`;

type Props = {
    users?: (IClassroomUser | IUserResource)[];
    setSelectedUser: (selectedUser: IClassroomUser | IUserResource) => void;
    selectedUserId?: string | string[];
    isLoading?: boolean;
    allowMultipleSelection?: boolean;
    showUserAvatar?: boolean;
    className?: string;
};

const UsersList = ({
    className,
    users = [],
    setSelectedUser,
    allowMultipleSelection = false,
    selectedUserId,
    isLoading = false,
    showUserAvatar = true,
}: Props) => {
    const [t] = useTranslation();

    if ((!isArray(selectedUserId) && allowMultipleSelection) || (isArray(selectedUserId) && !allowMultipleSelection)) {
        throw new Error(
            "An error occured while trying to render UsersList component, allowMultipleSelection props has been used the wrong way",
        );
    }

    if (isLoading) {
        return (
            <>
                {new Array(4).fill(null).map((item, index) => (
                    <Loader key={index} data-cy="users-list-loading-item">
                        <IconSkeleton round height={40} width={40} />
                        <TextSkeleton width="80%" height="18px" />
                    </Loader>
                ))}
            </>
        );
    }

    return (
        <List as="div" className={className} role="tablist" data-cy="users-list">
            {users.length > 0 ? (
                users.map(user => {
                    const isSelected =
                        allowMultipleSelection && isArray(selectedUserId)
                            ? !!selectedUserId?.find(id => getResourceUuid(user) === id)
                            : selectedUserId === getResourceUuid(user);

                    return (
                        <UserListItem
                            key={user["@id"]}
                            user={user}
                            selected={isSelected}
                            data-cy="users-list-item"
                            onClick={() => setSelectedUser(user)}
                            showUserAvatar={showUserAvatar}
                        />
                    );
                })
            ) : (
                <Text as="li">{t("component.UsersList.noResult")}</Text>
            )}
        </List>
    );
};

export default styled(UsersList)`
    > ${Text} {
        list-style: none;
        align-self: center;
        justify-self: center;
    }

    ${List} {
        padding: 0 ${({ theme }) => theme.spacing[3]};
    }
`;
