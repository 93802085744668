import React, { useMemo } from "react";
import styled from "styled-components";

import { ISkillProgressionMetric } from "@sol/sdk";

import Carousel from "../../../modules/uikit/Carousel";
import SkillMetricsCard from "./SkillMetricsCard";

const SLIDES_TO_SHOW = 4;

type Props = {
    skillsValidations: ISkillProgressionMetric[];
    className?: string;
};

const SkillsMetrics = ({ skillsValidations, className }: Props) => {
    const skillMetricsCardElements = useMemo(
        () =>
            skillsValidations.map(skillValidation => (
                <SkillMetricsCard
                    key={skillValidation.uuid}
                    label={skillValidation.shortTitle}
                    levelValidationsMetrics={skillValidation.skillLevels}
                />
            )),
        [skillsValidations],
    );

    return (
        <div className={className}>
            <Carousel slidesPerPage={SLIDES_TO_SHOW}>{skillMetricsCardElements}</Carousel>
        </div>
    );
};

export default styled(SkillsMetrics)`
    width: 100%;
`;
