import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BRIEF_CREATE } from "src/components/briefs/create/CreateBriefView";
import {
    PROFESSIONAL_SITUATION_EDIT,
    ProfessionalSituationPickerContext,
} from "src/components/shared/ProfessionalSituations/ProfessionalSituationsPicker";
import { BriefSteps } from "src/enums";
import { Text } from "src/uikit";
import { Tab, TabPanel, Tabs } from "src/uikit/Tabs";
import styled from "styled-components";

import { useRestrictedScope } from "@sol/access-control";
import { useAuthenticatedUser } from "@sol/authentication";
import { Feature, useCheckFeatureFlag } from "@sol/features";
import { Pencil, Plus } from "@sol/icons";
import { Route, useRouter } from "@sol/routing";
import { getResourceUuid } from "@sol/sdk";
import { useEducationalScenarioItem } from "@sol/sdk/educationalScenarioItems/useEducationalScenarioItem";
import { EducationalScenarioSlot } from "@sol/sdk/educationalScenarios/IEducationalScenarioSlot";
import { Button } from "@sol/uikit";

import { BlockSkeleton } from "../../skeletons";
import ProfessionalSituationBriefs from "./ProfessionalSituation/ProfessionalSituationBriefs";
import ProfessionalSituationDatePicker from "./ProfessionalSituation/ProfessionalSituationDatePicker";
import ProfessionalSituationDescription from "./ProfessionalSituation/ProfessionalSituationDescription";
import ProfessionalSituationSkills from "./ProfessionalSituation/ProfessionalSituationSkills";
import { Scale } from "./Timeline";

const HeadingRow = styled.div<{ currentTab: string }>`
    display: flex;
    justify-content: ${({ currentTab }) => (currentTab === TabsKeys.DESCRIPTION ? "space-between" : "start")};
    gap: ${({ currentTab, theme }) => (currentTab === TabsKeys.DESCRIPTION ? 0 : theme.spacing[6])};
    align-items: center;
    height: 56px;
    margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

const TabPanelContent = styled(TabPanel)<{ currentTab: string }>`
    display: flex;
    flex-direction: column;
    height: 358px;
    overflow-y: scroll;
    padding: 0 ${({ theme }) => theme.spacing[6]};
`;

const Content = styled.div`
    flex: 1;
    min-height: 0;
`;

const CustomBlockSkeleton = styled(BlockSkeleton)`
    height: 358px;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
`;

const CreateBriefButton = styled(Button)`
    min-width: 120px;
`;

enum TabsKeys {
    DESCRIPTION = "description",
    SKILLS = "skills",
    BRIEFS = "briefs",
}

type Props = {
    educationalScenarioItemId: string;
    educationalScenarioItems?: EducationalScenarioSlot[];
    classroomStartDate?: string;
    classroomEndDate?: string;
    setTimelineStartDate: (date: Date) => void;
    setTimelineScale: (scale: Scale) => void;
    refreshTimeline: () => void;
};

const PedagogicalScenarioTabs = ({
    educationalScenarioItemId,
    educationalScenarioItems,
    setTimelineStartDate,
    setTimelineScale,
    refreshTimeline,
    classroomStartDate,
    classroomEndDate,
    ...rest
}: Props) => {
    const [t] = useTranslation();
    const scope = useRestrictedScope();
    const checkFeatureFlag = useCheckFeatureFlag();
    const router = useRouter();
    const { PEDAGOGICAL_SCENARIO } = ProfessionalSituationPickerContext;
    const { user } = useAuthenticatedUser();
    const [currentTab, setCurrentTab] = useState(TabsKeys.DESCRIPTION);
    const { data, isLoading, refetch } = useEducationalScenarioItem({ educationalScenarioItemId });
    const tabPanelContentRef = useRef<HTMLDivElement | null>(null);

    const { professionalSituation, educationalBriefs, classroom, startDate, endDate } = data ?? {};
    const { title, description, targetedDomain, problematic, skills } = professionalSituation ?? {};

    const { trainers } = classroom || {};
    const isClassroomTrainer = trainers ? trainers.findIndex(trainer => trainer === user["@id"]) > -1 : false;
    const { canCreateBrief } = {
        canCreateBrief: isClassroomTrainer && checkFeatureFlag(Feature.BRIEFS_CREATE),
    };

    const tabs = [
        {
            title: t("component.home.PedagogicalScenarioCard.tabs.description"),
            key: TabsKeys.DESCRIPTION,
        },
        {
            title: t("component.home.PedagogicalScenarioCard.tabs.skills", {
                count: skills ? skills.length : 0,
            }),
            key: TabsKeys.SKILLS,
        },
        {
            title: t("component.home.PedagogicalScenarioCard.tabs.briefs", {
                count: educationalBriefs ? educationalBriefs.length : 0,
            }),
            key: TabsKeys.BRIEFS,
        },
    ];

    const tabPanelId = tabs.findIndex(tab => tab.key === currentTab);

    const handleBriefDelete = () => refetch();

    if (isLoading) {
        return (
            <div {...rest}>
                <CustomBlockSkeleton />
            </div>
        );
    }

    const canUpdateProfessionalSituation = professionalSituation
        ? checkFeatureFlag([Feature.PROFESSIONAL_SITUATION_UPDATE], {
              scope: scope(professionalSituation),
          })
        : false;

    const handleAddBriefButtonClick = () => {
        router.push({
            route: Route.BRIEFS_CREATE,
            state: {
                [BRIEF_CREATE]: {
                    professionalSituationId: professionalSituation?.["@id"],
                    step: BriefSteps.SKILLS,
                },
            },
        });
    };

    return (
        <div {...rest} data-cy="pedagogical-scenario-tabs">
            <Tabs>
                {tabs.map((tab, index) => {
                    const { title, key } = tab;
                    return (
                        <Tab
                            key={key}
                            tabId={index}
                            isSelected={currentTab === key}
                            data-cy={`pedagogical-scenario-tabs-${key}`}
                            onClick={() => {
                                setCurrentTab(key);
                                tabPanelContentRef?.current?.focus();
                            }}
                        >
                            <Text>{title}</Text>
                        </Tab>
                    );
                })}
            </Tabs>
            <TabPanelContent
                ref={tabPanelContentRef}
                tabId={tabPanelId}
                currentTab={currentTab}
                tabIndex={-1}
                className="focus-classes ring-inset"
            >
                <ProfessionalSituationDatePicker
                    currentTab={currentTab}
                    targetTab={TabsKeys.DESCRIPTION}
                    isClassroomTrainer={isClassroomTrainer}
                    startDate={startDate}
                    endDate={endDate}
                    educationalScenarioItemId={educationalScenarioItemId}
                    educationalScenarioItems={educationalScenarioItems}
                    refreshTimeline={refreshTimeline}
                    refetchPedagogicalScenarioItem={refetch}
                    setTimelineStartDate={setTimelineStartDate}
                    setTimelineScale={setTimelineScale}
                    classroomStartDate={classroomStartDate}
                    classroomEndDate={classroomEndDate}
                />
                <HeadingRow currentTab={currentTab}>
                    <Text as="h2" variant="h3" lines={1}>
                        {title}
                    </Text>
                    {canUpdateProfessionalSituation && currentTab === TabsKeys.DESCRIPTION && professionalSituation && (
                        <Button
                            as="a"
                            outlined
                            variant="primary"
                            data-cy="pedagogical-scenario-tabs-edit-professional-situation"
                            className="focus-classes"
                            tabIndex={0}
                            onClick={() =>
                                router.push({
                                    route: Route.PROFESSIONAL_SITUATION_EDIT,
                                    query: { id: getResourceUuid(professionalSituation) },
                                    state: {
                                        [PROFESSIONAL_SITUATION_EDIT]: {
                                            context: PEDAGOGICAL_SCENARIO,
                                            educationalScenarioItemId,
                                            start: startDate,
                                        },
                                    },
                                })
                            }
                        >
                            {t("component.home.PedagogicalScenarioCard.editProfessionalSituation")}
                            <Pencil />
                        </Button>
                    )}
                    {canCreateBrief &&
                        educationalBriefs &&
                        educationalBriefs.length > 0 &&
                        currentTab === TabsKeys.BRIEFS && (
                            <CreateBriefButton
                                small={true}
                                flat={true}
                                type="button"
                                onClick={handleAddBriefButtonClick}
                                data-cy="pedagogical-scenario-tabs-add-brief-button"
                            >
                                {t("component.home.PedagogicalScenarioCard.createBrief")}
                                <Plus />
                            </CreateBriefButton>
                        )}
                </HeadingRow>
                <Content>
                    {currentTab === TabsKeys.DESCRIPTION && (
                        <ProfessionalSituationDescription
                            description={description}
                            targetedDomain={targetedDomain}
                            problematic={problematic}
                        />
                    )}
                    {currentTab === TabsKeys.SKILLS && <ProfessionalSituationSkills skills={skills} />}
                    {currentTab === TabsKeys.BRIEFS && (
                        <ProfessionalSituationBriefs
                            educationalBriefs={educationalBriefs}
                            educationalScenarioItemId={educationalScenarioItemId}
                            classroom={classroom}
                            isClassroomTrainer={isClassroomTrainer}
                            handleBriefDelete={handleBriefDelete}
                            handleAddBrief={handleAddBriefButtonClick}
                            canCreateBrief={canCreateBrief}
                            refetchPedagogicalScenarioItem={refetch}
                        />
                    )}
                </Content>
            </TabPanelContent>
        </div>
    );
};

export default styled(PedagogicalScenarioTabs)`
    min-height: 451px;
    position: relative;

    ${Tab} {
        width: 100vw;
    }

    ${TabPanelContent} {
        flex: 1;
        min-height: 0;
    }
`;
