import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { MutateConfig, useMutation } from "react-query";

import { useApi } from "@sol/sdk/SDKProvider";

import { EducationalBriefs } from "../educationalScenarioItems/IEducationalScenarioItem";

interface PutEducationalBriefVariables {
    payload: EducationalBriefs;
    educationalBriefId?: string;
}

export const putEducationalBrief =
    (api: AxiosInstance) =>
    ({ payload, educationalBriefId }: PutEducationalBriefVariables) =>
        api.put(`educational_briefs/${educationalBriefId}`, payload);

export const useUpdateEducationalBriefMutation = (
    config: MutateConfig<AxiosResponse, any, PutEducationalBriefVariables>,
) => {
    const api = useApi();
    const putEducationalBriefMutation = useMemo(() => putEducationalBrief(api), [api]);
    const [updateEducationalBrief, { isLoading }] = useMutation(putEducationalBriefMutation, config);

    return { updateEducationalBrief, isLoading };
};
