import { TFunction } from "i18next";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import List from "src/uikit/List/List";
import ListItem from "src/uikit/List/ListItem";
import Text from "src/uikit/Text";
import styled from "styled-components";

import { ISkillItem, ISkillLevelItem } from "@sol/sdk";

import { BlockSkeleton } from "../skeletons";
import { SkillLevelSelectionType } from "../SkillSelection/types";

const LevelBadge = styled(Text)<{ noLevel?: boolean }>`
    padding: ${({ theme }) => theme.spacing[2]};
    margin-top: ${({ theme }) => theme.spacing[1]};
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: 48px;
    background-color: ${({ noLevel, theme }) => (noLevel ? theme.palette.white.base : theme.palette.purple.base)};
    color: ${({ noLevel, theme }) => (noLevel ? theme.palette.grey.base : theme.palette.white.base)};
    width: 109px;
`;

const SkillListLabel = styled(Text)`
    display: inline-flex;
    padding: ${({ theme }) => theme.spacing[5]};
    text-align: center;
`;

const renderLevelBadge = (selectedLevels: ISkillLevelItem[], skill: ISkillItem, t: TFunction) => {
    const level = selectedLevels.find(selectedLevel => selectedLevel.skill === skill["@id"])?.level;
    return level ? (
        <LevelBadge variant="subheading">{t("component.shared.SkillsList.skillLevel", { level })}</LevelBadge>
    ) : (
        <LevelBadge variant="subheading" noLevel>
            {t("component.shared.SkillsList.noLevel")}
        </LevelBadge>
    );
};

type ListItemProps = {
    item: ISkillItem;
    onClick?: () => void;
    selectedLevels?: SkillLevelSelectionType[];
} & ComponentProps<typeof ListItem>;

const SkillsListItem = styled(
    ({ item, onClick, selectedLevels = [], showLevelBadges = false, selected, ...rest }: ListItemProps) => {
        const [t] = useTranslation();

        return (
            <ListItem {...rest}>
                <button type="button" onClick={onClick} title={item.shortTitle}>
                    <Text as="span" variant="label" textAlign="left" lines={3}>
                        {item.code}. {item.shortTitle}
                    </Text>
                    {showLevelBadges && renderLevelBadge(selectedLevels, item, t)}
                </button>
            </ListItem>
        );
    },
)`
    button {
        min-height: 80px;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;

        padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[6]}`};
        background: ${({ theme, selected }) => (selected ? theme.palette.purple.lightest : "none")};
        --text-color: ${({ theme, selected }) => (selected ? theme.palette.purple.base : theme.palette.black.base)};
    }

    ${Text} {
        font-weight: 400;
    }
`;

type Props = {
    items?: ISkillItem[];
    onSelect?: (item: ISkillItem) => void;
    selectedItem?: string;
    selectedLevels?: ISkillLevelItem[];
    isLoading?: boolean;
    showLevelBadges?: boolean;
    className?: string;
};

const SkillsList = ({
    items = [],
    onSelect,
    selectedItem,
    selectedLevels,
    showLevelBadges,
    isLoading = false,
    className,
}: Props) => {
    const [t] = useTranslation();

    return (
        <div className={className}>
            <SkillListLabel as="span" variant="label">
                {t("component.shared.SkillsList.label")}
            </SkillListLabel>
            <List data-cy="skills-list">
                {isLoading || items.length < 1 ? (
                    <BlockSkeleton />
                ) : (
                    items.map(item => {
                        const itemId = item["@id"];

                        return (
                            <SkillsListItem
                                key={itemId}
                                data-cy="skills-list-item"
                                item={item}
                                selected={selectedItem === itemId}
                                onClick={() => {
                                    onSelect?.(item);
                                }}
                                selectedLevels={selectedLevels}
                                showLevelBadges={showLevelBadges}
                            />
                        );
                    })
                )}
            </List>
        </div>
    );
};

export default styled(SkillsList)`
    display: flex;
    flex-direction: column;
    padding: 0 ${({ theme }) => theme.spacing[3]};

    > ${Text} {
        list-style: none;
        align-self: center;
        justify-self: center;
    }
    ${BlockSkeleton} {
        overflow: hidden;
    }
`;
