import { compareAsc, format } from "date-fns";
import { TFunction } from "i18next";
import React, { InputHTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import Checkbox, { CheckboxColor } from "src/uikit/Checkbox";
import SegmentedControls from "src/uikit/SegmentedControls";
import theme from "src/uikit/theme";
import styled from "styled-components";

import { IClassroom, getResourceUuid, IUserResource } from "@sol/sdk";
import { Button, GhostButton } from "@sol/uikit";
import { Grid, GridColumn } from "@sol/uikit/layouts/Grid";

import BlurredModal, { BlurredModalContainer } from "../shared/BlurredModal";
import UserAvatar from "../shared/UserAvatar";
import ClassroomDataviz from "./ClassroomDataviz";
import {
    setDates,
    setLearners,
    useDatavizFilters,
    setMode,
    toggleCumulated,
    DatavizFiltersState,
} from "./useDatavizFilters";

const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

const TogglesContainer = styled.div`
    display: flex;
`;

const DatePicker = styled.div`
    display: flex;
    align-items: center;
`;

const DateInput = styled.input`
    padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[5]}`};
    margin: ${({ theme }) => `0 ${theme.spacing[5]} 0 ${theme.spacing[3]}`};
    border: 0.2rem solid ${({ theme }) => theme.palette.purple.lighter};
    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
    box-sizing: border-box;
    outline: 0;

    &:focus {
        border: 0.2rem solid ${({ theme }) => theme.palette.purple.light};
    }

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }

    height: 48px;

    ${theme.typography.p}
`;

const Modal = styled(BlurredModal)`
    > ${BlurredModalContainer} {
        width: calc(100% - ${({ theme }) => theme.spacing[6]});
        margin: ${({ theme }) => `${theme.spacing[5]} auto`};
        max-height: calc(100vh - ${({ theme }) => theme.spacing[6]});
        overflow-y: auto;
    }
`;

const ModalHeading = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const SelectedLearnersCount = styled(Text)`
    color: ${({ theme }) => theme.palette.grey.base};
`;

const LearnersGrid = styled(Grid)`
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.spacing[7]};
`;

const CloseButton = styled(Button)`
    margin-right: auto;
`;

const getFormatedDate = (date?: string) => {
    const DATE_FORMAT = "yyyy-MM-dd";

    if (!date) {
        return format(new Date(), DATE_FORMAT);
    } else {
        return format(new Date(date), DATE_FORMAT);
    }
};

const getDefaultDates = (classroom: IClassroom) => {
    const today = getFormatedDate();
    const classroomStartDate = getFormatedDate(classroom.startDate);
    const classroomEndDate = getFormatedDate(classroom.endDate);
    const hasClassroomEnded = Date.parse(today) > Date.parse(classroomEndDate);

    return { fromDate: classroomStartDate, toDate: hasClassroomEnded ? classroomEndDate : today };
};

const getModeOptions = (t: TFunction) => [
    { id: "levels", label: t("component.dataviz.ClassroomDataviz.toggles.skillsLabel") },
    { id: "works", label: t("component.dataviz.ClassroomDataviz.toggles.worksLabel") },
];

const getCumulatedOptions = (t: TFunction) => [
    {
        id: "cumulated",
        label: t("component.dataviz.ClassroomDataviz.toggles.cumulatedDataLabel"),
    },
    {
        id: "uncumulated",
        label: t("component.dataviz.ClassroomDataviz.toggles.uncumulatedDataLabel"),
    },
];

type LearnerItemProps = {
    learner: IUserResource;
    className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const LearnerItem = styled(({ learner, className, ...rest }: LearnerItemProps) => (
    <label htmlFor={learner["@id"]} key={learner["@id"]}>
        <div className={className}>
            <Checkbox {...rest} id={learner["@id"]} color={CheckboxColor.PURPLE} />
            <UserAvatar user={learner} />
            <Text>
                {learner.name} {learner.surname}
            </Text>
        </div>
    </label>
))`
    padding: ${({ theme }) => theme.spacing[4]};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    display: flex;
    align-items: center;

    ${Text} {
        margin-left: ${({ theme }) => theme.spacing[4]};
    }
`;

type Props = {
    classroom: IClassroom;
    className?: string;
};

const ClassroomDatavizFilters = ({ classroom, className }: Props) => {
    const [t] = useTranslation();
    const allLearnerIds = classroom.learners.map(learner => getResourceUuid(learner));

    const [showLearnersList, setShowLearnersList] = useState(false);
    const [selectedLearners, setSelectedLearners] = useState(allLearnerIds);

    const [dateInterval, setDateInterval] = useState(getDefaultDates(classroom));

    const [filters, dispatch] = useDatavizFilters({
        ...getDefaultDates(classroom),
        classroomId: getResourceUuid(classroom),
        learners: allLearnerIds,
    });

    const shouldDateFilterButtonBeDisabled =
        compareAsc(new Date(dateInterval.fromDate), new Date(classroom.startDate)) === -1 ||
        compareAsc(new Date(dateInterval.toDate), new Date(classroom.endDate)) === 1 ||
        compareAsc(new Date(dateInterval.fromDate), new Date(dateInterval.toDate)) === 1;

    return (
        <Container className={className}>
            <TogglesContainer>
                <SegmentedControls
                    onChange={id =>
                        dispatch(
                            setMode({
                                mode: id as DatavizFiltersState["mode"]["id"],
                            }),
                        )
                    }
                    value={filters.mode.id}
                    title={t("component.dataviz.ClassroomDataviz.toggles.modeTitle")}
                    options={getModeOptions(t)}
                />
                <SegmentedControls
                    onChange={id =>
                        dispatch(
                            toggleCumulated({
                                cumulatedNumber: id as DatavizFiltersState["cumulatedNumber"],
                            }),
                        )
                    }
                    value={filters?.cumulatedNumber}
                    title={t("component.dataviz.ClassroomDataviz.toggles.cumulatedTitle")}
                    options={getCumulatedOptions(t)}
                />
            </TogglesContainer>

            <FiltersContainer>
                <DatePicker>
                    <Text as="label" htmlFor="fromDate">
                        {t("component.dataviz.ClassroomDataviz.fromDate")}
                    </Text>
                    <DateInput
                        type="date"
                        name="fromDate"
                        id="fromDate"
                        min={getDefaultDates(classroom).fromDate}
                        max={getDefaultDates(classroom).toDate}
                        value={dateInterval.fromDate}
                        onChange={event => setDateInterval({ ...dateInterval, fromDate: event.currentTarget.value })}
                    />
                    <Text as="label" htmlFor="toDate">
                        {t("component.dataviz.ClassroomDataviz.toDate")}
                    </Text>
                    <DateInput
                        type="date"
                        name="toDate"
                        id="toDate"
                        min={getDefaultDates(classroom).fromDate}
                        max={getDefaultDates(classroom).toDate}
                        value={dateInterval.toDate}
                        onChange={event => setDateInterval({ ...dateInterval, toDate: event.currentTarget.value })}
                    />
                    <Button
                        outlined
                        variant="primary"
                        disabled={shouldDateFilterButtonBeDisabled}
                        onClick={() =>
                            dispatch(setDates({ startDate: dateInterval.fromDate, endDate: dateInterval.toDate }))
                        }
                    >
                        {t("component.dataviz.ClassroomDataviz.filterByDate")}
                    </Button>
                </DatePicker>
                <Button filled variant="primary" onClick={() => setShowLearnersList(!showLearnersList)}>
                    {t("component.dataviz.ClassroomDataviz.filterByLearners")}
                </Button>
            </FiltersContainer>
            <ClassroomDataviz
                filters={{
                    ...filters,
                    action: filters.mode.values.map(v => v.action),
                    cumulatedNumber: filters.cumulatedNumber === "cumulated",
                }}
            />
            <Modal visible={showLearnersList}>
                <ModalHeading>
                    <Text as="span" variant="label">
                        {t("component.dataviz.ClassroomDataviz.select")} -{" "}
                    </Text>
                    <SelectedLearnersCount as="span">
                        {t("component.dataviz.ClassroomDataviz.selectedLearner", { count: selectedLearners.length })}
                    </SelectedLearnersCount>
                </ModalHeading>
                <LearnersGrid as="ul">
                    {classroom.learners.map(learner => {
                        const learnerUuid = getResourceUuid(learner);
                        const isSelected = selectedLearners.includes(learnerUuid);

                        return (
                            <GridColumn col={4} as="li" key={learner["@id"]}>
                                <LearnerItem
                                    learner={learner}
                                    checked={isSelected}
                                    onChange={() => {
                                        setSelectedLearners(
                                            isSelected
                                                ? [...selectedLearners.filter(uuid => learnerUuid !== uuid)]
                                                : [...selectedLearners, learnerUuid],
                                        );
                                    }}
                                />
                            </GridColumn>
                        );
                    })}
                </LearnersGrid>
                <ModalFooter>
                    <CloseButton variant="primary" onClick={() => setShowLearnersList(false)}>
                        {t("component.dataviz.ClassroomDataviz.close")}
                    </CloseButton>

                    <GhostButton onClick={() => setSelectedLearners(selectedLearners.length ? [] : allLearnerIds)}>
                        {selectedLearners.length
                            ? t("component.dataviz.ClassroomDataviz.unselectAll")
                            : t("component.dataviz.ClassroomDataviz.selectAll")}
                    </GhostButton>

                    <Button
                        outlined
                        variant="success"
                        onClick={() => {
                            dispatch(setLearners({ learners: selectedLearners }));
                            setShowLearnersList(false);
                        }}
                    >
                        {t("component.dataviz.ClassroomDataviz.submit")}
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default styled(ClassroomDatavizFilters)`
    max-width: 1100px;

    ${FiltersContainer} {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }

    ${SegmentedControls} + ${SegmentedControls} {
        margin-left: ${({ theme }) => theme.spacing[4]};
    }

    ${SegmentedControls} {
        width: 300px;
    }

    ${ModalFooter} ${GhostButton} {
        margin-right: ${({ theme }) => theme.spacing[5]};
    }
`;
