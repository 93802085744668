import { AxiosError, AxiosResponse } from "axios";
import { EditorState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";
import isArray from "lodash/isArray";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStateToMarkdown } from "src/components/BriefForm/utils";
import CreateBriefFormsManager, {
    CreateBriefAdditionalInformationStepPayload,
    CreateBriefDescriptionStepPayload,
    CreateBriefFormValues,
    CreateBriefSkillsStepPayload,
} from "src/components/briefs/create/CreateBriefFormsManager";
import CreateBriefLayout from "src/components/briefs/create/CreateBriefLayout";
import CreateBriefNavigator from "src/components/briefs/create/CreateBriefNavigator";
import CreateBriefStepsAside from "src/components/briefs/create/CreateBriefStepsAside";
import { BRIEF_IS_UNDER_EDITION } from "src/components/shared/ProfessionalSituations/ProfessionalSituationsPicker";
import { BriefSteps } from "src/enums";
import { v4 as uuid } from "uuid";

import { NotificationLevel, useNotify } from "@sol/notifications";
import { useNavigationState } from "@sol/persistance";
import { Route, useRouter } from "@sol/routing";
import {
    BriefStatus,
    EntityTypes,
    getResourceUuid,
    IBrief,
    useBrief,
    useCreateBrief,
    useProfessionalSituation,
} from "@sol/sdk";
import { useUpdateBrief } from "@sol/sdk/briefs/useUpdateBrief";

import ApiErrorAlert from "../../ApiErrorAlert";
import { CreateBriefStepWithSummary } from "./constants";
import CreateBriefSummaryView from "./CreateBriefSummaryView";
import { isBriefDraftSchemaValid } from "./forms/validations";
import SaveDraftButton from "./SaveDraftButton";

type CurrentStepFormValues = Partial<
    CreateBriefSkillsStepPayload | CreateBriefDescriptionStepPayload | CreateBriefAdditionalInformationStepPayload
>;

interface mutationVariables {
    values: CreateBriefFormValues;
    currentStepValues?: CurrentStepFormValues;
    currentStep: CreateBriefStepWithSummary;
}

export const BRIEF_CREATE = "briefCreate";

const CreateBriefView = () => {
    const [t] = useTranslation();
    const router = useRouter();
    const { route, query } = useRouter();
    const { PROFESSIONAL_SITUATION, DESCRIPTION, SKILLS, SUMMARY, ADDITIONAL_INFORMATION } = BriefSteps;
    const draftButtonRef = useRef(null);

    const isEdit = useMemo(() => route === Route.BRIEFS_EDIT, [route]);
    const briefId = isArray(query.id) ? query.id[0] : query.id;

    const { data: briefs, isLoading: isBriefLoading } = useBrief({
        brief: briefId,
        enabled: !!briefId,
        refetchOnMount: false,
    });

    const { step: defaultStep } = (router.state?.[BRIEF_IS_UNDER_EDITION] || router.state?.[BRIEF_CREATE]) ?? {};
    const { professionalSituationId } = router.state?.[BRIEF_CREATE] ?? {};
    const notify = useNotify();
    const [step, setStep] = useNavigationState<CreateBriefStepWithSummary>(
        "step",
        isEdit && defaultStep ? defaultStep : isEdit ? SKILLS : defaultStep ? defaultStep : PROFESSIONAL_SITUATION,
    );
    const [mutationError, setMutationError] = useState<AxiosResponse | undefined>(undefined);

    const { data: professionalSituation, isLoading: isProfessionalSituationLoading } = useProfessionalSituation({
        professionalSituation: professionalSituationId,
        enabled: !!professionalSituationId,
    });

    const getDraftValues = ({ values, currentStepValues }: Pick<mutationVariables, "values" | "currentStepValues">) => {
        const payload = {
            professionalSituation: values["professional-situation"]?.["@id"],
            ...values.skills,
            ...values.description,
            ...values["additional-information"],
            ...currentStepValues,
        };

        const isValid = isBriefDraftSchemaValid(t, payload);

        if (isValid) {
            return {
                ...payload,
                skillLevels: payload.skillLevels.map(skillLevel => skillLevel["@id"]),
                context: getStateToMarkdown(payload.context),
                pedagogicModalities: getStateToMarkdown(payload.pedagogicModalities),
                tags: payload.tags.map(tag => tag["@id"]),
                media: payload.resources.map(resource => resource["@id"]),
                image: payload.image?.["@id"],
                status: briefs?.status ?? BriefStatus.DRAFT,
                uuid: uuid(),
            };
        }

        return undefined;
    };

    const getSuccessMessage = () => {
        if (isEdit) {
            return briefs?.status === BriefStatus.DRAFT
                ? "component.CreateBriefLayout.update.success-draft"
                : "component.CreateBriefLayout.update.success";
        }

        return "component.CreateBriefLayout.create.success";
    };

    const mutationOptions = useMemo(
        () => ({
            onSuccess: () => {
                notify({
                    level: NotificationLevel.SUCCESS,
                    message: t(getSuccessMessage()),
                });
            },
            onError: (error: AxiosError) => setMutationError(error.response),
        }),
        [isEdit, briefs, notify, t],
    );

    const { updateBrief, isLoading: updateBriefMutationLoading } = useUpdateBrief({ ...mutationOptions });
    const { createBrief, isLoading: createBriefMutationLoading } = useCreateBrief({ ...mutationOptions });

    const saveDraft = async ({ values, currentStepValues, currentStep }: mutationVariables) => {
        const payload = getDraftValues({ values, currentStepValues });

        if (payload) {
            if (isEdit) {
                await updateBrief({ payload, briefId });
            } else {
                const result = await createBrief(payload);
                const id = result ? getResourceUuid(result.data) : undefined;
                setStep(currentStep, {
                    route: Route.BRIEFS_EDIT,
                    query: { id },
                });
            }
        }
    };

    const briefToEditDefaultValues = (brief?: IBrief): CreateBriefFormValues | undefined => {
        if (brief) {
            return {
                skills: {
                    skillLevels: brief.skillLevels.map(({ skill, "@id": id, level }) => ({
                        "@id": id,
                        "@type": EntityTypes.SKILL_LEVEL,
                        level: level,
                        skill: skill["@id"],
                        skillTitle: skill.shortTitle,
                    })),
                },
                // TODO: rework Brief typing to check nullable values
                description: {
                    context: EditorState.createWithContent(stateFromMarkdown(brief.context ?? "")),
                    description: brief.description ?? "",
                    evaluationModalities: brief?.evaluationModalities ?? "",
                    expectedWork: brief.expectedWork ?? "",
                    pedagogicModalities: EditorState.createWithContent(
                        stateFromMarkdown(brief.pedagogicModalities ?? ""),
                    ),
                    performanceCriteria: brief?.performanceCriteria ?? "",
                    title: brief.title,
                    isCorrected: brief.isCorrected,
                    language: brief.language,
                },
                "additional-information": {
                    resources: brief.media,
                    tags: brief.tags,
                    image: brief.image ?? undefined,
                },
                "professional-situation": brief.professionalSituation,
            };
        }
    };

    const briefWithPreSelectedProfessionalSituation = (): CreateBriefFormValues | undefined => {
        if (professionalSituation) {
            return {
                "professional-situation": professionalSituation,
                skills: {
                    skillLevels: [],
                },
                description: {
                    context: EditorState.createWithContent(
                        stateFromMarkdown(
                            `${professionalSituation.targetedDomain} ${professionalSituation.description}`,
                        ),
                    ),
                    description: "",
                    evaluationModalities: "",
                    expectedWork: "",
                    pedagogicModalities: EditorState.createEmpty(),
                    performanceCriteria: "",
                    title: "",
                },
                "additional-information": {
                    resources: [],
                    tags: [],
                    image: undefined,
                },
            };
        }
    };

    const editDefaultValues = useMemo(() => briefToEditDefaultValues(briefs), [briefs]);

    const pedagogicalScenarioContextDefaultValues = useMemo(
        () => briefWithPreSelectedProfessionalSituation(),
        [professionalSituation],
    );

    return (
        <CreateBriefNavigator defaultStep={step}>
            {({ currentStep, setCurrentStep }) => (
                <CreateBriefFormsManager
                    isEdit={isEdit}
                    currentStep={currentStep}
                    onStepChange={setCurrentStep}
                    defaultValues={editDefaultValues || pedagogicalScenarioContextDefaultValues}
                >
                    {({ formValues, stepStatuses, currentStepData, forms }) =>
                        currentStep === SUMMARY ? (
                            <CreateBriefSummaryView
                                values={formValues}
                                onBackClick={() => setCurrentStep(ADDITIONAL_INFORMATION)}
                                isEdit={isEdit}
                                briefStatus={briefs?.status}
                                isBriefLoading={isBriefLoading}
                                isLoading={updateBriefMutationLoading || createBriefMutationLoading}
                                defaultValues={editDefaultValues}
                                onSaveDraft={() =>
                                    saveDraft({
                                        values: formValues,
                                        currentStep,
                                    })
                                }
                            />
                        ) : (
                            <CreateBriefLayout
                                isLoading={isBriefLoading || isProfessionalSituationLoading}
                                title={t(
                                    isEdit
                                        ? "component.CreateBriefLayout.title.editBrief"
                                        : "component.CreateBriefLayout.title.create",
                                )}
                                currentStep={currentStep}
                                {...currentStepData}
                            >
                                <CreateBriefStepsAside
                                    isLoading={isBriefLoading || isProfessionalSituationLoading}
                                    onBackClick={() => setCurrentStep(PROFESSIONAL_SITUATION)}
                                    currentStep={currentStep}
                                    stepsGroups={[
                                        {
                                            title: t("component.CreateBriefStepsRecap.professionalSituation"),
                                            steps: [
                                                {
                                                    label: t(
                                                        "component.CreateBriefLayout.steps.professionalSituation.label",
                                                    ),
                                                    order: 1,
                                                    status: stepStatuses[PROFESSIONAL_SITUATION],
                                                },
                                            ],
                                        },
                                        {
                                            title: t("component.CreateBriefStepsRecap.brief"),
                                            steps: [
                                                {
                                                    label: t("component.CreateBriefLayout.steps.skills.label"),
                                                    order: 2,
                                                    status: stepStatuses[SKILLS],
                                                },
                                                {
                                                    label: t("component.CreateBriefLayout.steps.description.label"),
                                                    order: 3,
                                                    status: stepStatuses[DESCRIPTION],
                                                },
                                                {
                                                    label: t(
                                                        "component.CreateBriefLayout.steps.additionalInformation.label",
                                                    ),
                                                    order: 4,
                                                    status: stepStatuses[ADDITIONAL_INFORMATION],
                                                },
                                            ],
                                        },
                                    ]}
                                >
                                    <SaveDraftButton
                                        forwardRef={draftButtonRef}
                                        isLoading={updateBriefMutationLoading || createBriefMutationLoading}
                                        currentStep={currentStep}
                                        isEdit={isEdit}
                                        forms={forms}
                                        onClick={() =>
                                            saveDraft({
                                                values: formValues,
                                                currentStepValues: forms[currentStep as keyof typeof forms].getValues(),
                                                currentStep,
                                            })
                                        }
                                    />
                                    <ApiErrorAlert
                                        error={mutationError}
                                        dissmiss={() => setMutationError(undefined)}
                                        triggerElement={draftButtonRef}
                                    />
                                </CreateBriefStepsAside>
                            </CreateBriefLayout>
                        )
                    }
                </CreateBriefFormsManager>
            )}
        </CreateBriefNavigator>
    );
};

export default CreateBriefView;
