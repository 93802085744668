import React, { useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";
import styled from "styled-components";

import useScroll from "../../../hooks/useScroll";
import { Text } from "../../../uikit";
import WorkSheetSvg from "./WorkSheetSvg";

const DataContainer = styled.div`
    position: absolute;
    bottom: 12px;
`;

type ValueComponentProps = { isBigNumber: boolean };

const Value = styled(Text).attrs({ "aria-hidden": true })<ValueComponentProps>`
    text-align: center;
    font-weight: 900;
    line-height: 48px;
    font-size: ${({ isBigNumber }) => (isBigNumber ? "50px" : "60px")};
    color: ${({ theme }) => theme.palette.blue.base};
`;

const Divider = styled.div`
    display: block;
    width: 114px;
    height: 2px;
    background: ${({ theme }) => theme.palette.grey.base};
    border-radius: 4px; // Not in the theme
`;

const Label = styled(Text).attrs({ variant: "subheading", "aria-hidden": true })`
    color: ${({ theme }) => theme.palette.grey.base};
    text-align: center;
`;

const Total = styled(Text).attrs({ "aria-hidden": true })`
    text-align: center;
    font-weight: 900;
    line-height: 48px;
    font-size: 40px;
    color: ${({ theme }) => theme.palette.grey.base};
`;

type Props = {
    value?: number;
    total?: number;
    srOnlyLabel: string;
    className?: string;
};

const WorksMetrics = ({ value = 0, total = 100, className, srOnlyLabel }: Props) => {
    const [t] = useTranslation();
    const [active, setActive] = useState(true);
    const scroll = useScroll();

    return (
        <Waypoint scrollableAncestor={scroll} onEnter={active ? () => setActive(false) : undefined}>
            <div className={className}>
                <WorkSheetSvg />
                <DataContainer aria-label={srOnlyLabel}>
                    <Value isBigNumber={value > 999}>
                        <CountUp end={!active ? value : 0} duration={1.25} />
                    </Value>
                    <Divider />
                    <Total>{total}</Total>
                    <Label>{t("component.WorksMetrics.label")}</Label>
                </DataContainer>
            </div>
        </Waypoint>
    );
};

export default styled(WorksMetrics)`
    width: 180px;
    height: 180px;
    position: relative;
    display: flex;
    justify-content: center;
`;
