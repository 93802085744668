import { cva } from "class-variance-authority";
import React, { useRef } from "react";
import { AriaListBoxOptions, useListBox, useOption } from "react-aria";
import { ListState, Node } from "react-stately";

import { type SelectVariantsProps } from "@sol/uikit/molecules/Forms/Select/variants";

type OptionProps<T> = {
    state: ListState<T>;
    item: Node<T>;
} & SelectVariantsProps;

const Option = <T extends object>({ state, item, variant = "primary" }: OptionProps<T>) => {
    const ref = useRef<HTMLLIElement>(null);
    const { optionProps, isFocused } = useOption({ key: item.key }, state, ref);

    const optionVariant = cva(
        ["inline-flex", "w-full", "px-4", "py-3", "rounded-lg", "text-sm", "outline-none", "hover:cursor-pointer"],
        {
            variants: {
                variant: {
                    primary: [],
                    secondary: [],
                    cta: ["text-white-base", "hover:bg-yellow-base", "hover:text-black-base"],
                },
            },
            compoundVariants: [
                {
                    variant: ["primary", "secondary"],
                    className: ["text-black-base", "hover:bg-purple-base", "hover:text-white-base"],
                },
            ],
            defaultVariants: {
                variant,
            },
        },
    );

    let optionFocusClassName = "";
    if (isFocused && (variant === "primary" || variant === "secondary")) {
        optionFocusClassName = "bg-purple-base text-white-base";
    } else if (isFocused && variant === "cta") {
        optionFocusClassName = "bg-yellow-base !text-black-base";
    }

    return (
        <li
            {...optionProps}
            ref={ref}
            className={optionVariant({
                variant,
                className: `${optionFocusClassName}`,
            })}
        >
            {item.rendered}
        </li>
    );
};

type ListBoxProps<T> = {
    state: ListState<T>;
} & AriaListBoxOptions<T> &
    SelectVariantsProps;

export const OptionsList = <T extends object>({ state, variant = "primary", ...props }: ListBoxProps<T>) => {
    const listBoxRef = useRef(null);

    const { listBoxProps } = useListBox(props, state, listBoxRef);

    const listBoxVariant = cva(["m-0", "flex", "flex-col", "items-start", "rounded-lg", "shadow-primary"], {
        variants: {
            variant: {
                primary: ["bg-grey-lightest"],
                secondary: ["bg-white-base"],
                cta: ["bg-purple-base", "text-white-base"],
            },
        },
        defaultVariants: {
            variant,
        },
    });

    return (
        <ul
            {...listBoxProps}
            ref={listBoxRef}
            className={listBoxVariant({ variant, className: "!max-h-[400px] overflow-auto rounded-lg" })}
        >
            {[...state.collection].map(item => (
                <Option key={item.key} item={item} state={state} variant={variant} />
            ))}
        </ul>
    );
};
