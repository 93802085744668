import { first } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockSkeleton } from "src/components/skeletons";
import { Text } from "src/uikit";
import styled from "styled-components";

import { ISkillItem, ISkillLevelItem } from "@sol/sdk";
import { GridColumn } from "@sol/uikit";

import { TabVariant } from "../../../uikit/Tabs/Tab";
import SkillLevelsTabs from "../SkillLevelsTabs";
import SkillsList from "../SkillsList";

const DEFAULT_HEIGHT = 478;

const Empty = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.white.base};
    min-height: 100%;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    border: 2px solid ${({ theme }) => theme.palette.grey.lightest};
`;

type Props = {
    skills?: ISkillItem[];
    value: ISkillLevelItem[];
    onChange: (value: ISkillLevelItem[]) => void;
    className?: string;
    isReadOnly?: boolean;
    tabsVariant?: TabVariant;
    tabButtonVariant?: TabVariant;
    isLoading?: boolean;
};

const SkillLevelsPicker = ({
    skills,
    value,
    onChange,
    className,
    isReadOnly,
    tabsVariant,
    tabButtonVariant,
    isLoading,
}: Props) => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.shared.pickers.SkillLevelsPicker" });

    const [selectedSkill, setSelectedSkill] = useState<ISkillItem | undefined>(first(skills));

    const isEmpty = (skills?.length ?? 0) === 0;

    const skill: ISkillItem | null | undefined = useMemo(() => {
        if (isEmpty) {
            return null;
        }

        return selectedSkill ?? first(skills);
    }, [selectedSkill, skills, isEmpty]);

    return (
        <div
            className={`grid w-full grid-flow-row auto-rows-auto grid-cols-12 gap-3 divide-x-2 divide-grey-lightest overflow-hidden rounded-lg ${className}`}
        >
            {isLoading ? (
                <GridColumn col={12} as={BlockSkeleton} />
            ) : !skill ? (
                <GridColumn col={12} as={Empty}>
                    <Text>{t("empty")}</Text>
                </GridColumn>
            ) : (
                <>
                    <SkillsList
                        className="col-span-4 h-full overflow-auto"
                        items={skills}
                        selectedItem={skill?.["@id"]}
                        onSelect={setSelectedSkill}
                        selectedLevels={value}
                        showLevelBadges
                    />

                    <SkillLevelsTabs
                        className="col-span-8 overflow-hidden"
                        skill={skill}
                        value={value}
                        onLevelChange={onChange}
                        isReadOnly={isReadOnly}
                        tabsVariant={tabsVariant}
                        tabButtonVariant={tabButtonVariant}
                    />
                </>
            )}
        </div>
    );
};

export default styled(SkillLevelsPicker)`
    height: ${DEFAULT_HEIGHT}px;
`;
