import { uuidRegex } from "./regex";

export function getUuid(str: string): string | null {
    const matchResult = str.match(uuidRegex);

    if (!matchResult || matchResult.length <= 1) {
        return null;
    }
    return matchResult[1];
}

export function encodeQueryData(data: { [key: string]: string | number }): string {
    return Object.keys(data)
        .map(key => {
            return [key, data[key]].map(encodeURIComponent).join("=");
        })
        .join("&");
}
