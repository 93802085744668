import { cva, type VariantProps } from "class-variance-authority";

export type SelectVariantsProps = VariantProps<typeof selectVariants>;

const baseClasses: string[] = [
    "flex",
    "items-center",
    "justify-between",
    "max-w-[600px]",
    "gap-3",
    "rounded-lg",
    "capitalize",
    "transition-all",
    "ease-in-out",
    "transition-200",
    "enabled:hover:translate-x-[calc(-1*3px)]",
    "enabled:hover:translate-y-[calc(-1*3px)]",
    "enabled:hover:shadow-primary",
];

export const selectVariants = cva(baseClasses, {
    variants: {
        variant: {
            primary: ["bg-grey-lightest"],
            secondary: ["bg-white-base"],
            cta: ["bg-purple-base", "text-white-base"],
        },
        size: {
            standard: ["min-w-[200px]", "p-3", "text-sm"],
            small: ["min-w-content", "px-2", "text-xs"],
        },
    },
    compoundVariants: [
        {
            variant: ["primary", "secondary"],
            size: ["small", "standard"],
            className: ["text-black-base"],
        },
    ],
    defaultVariants: {
        variant: "primary",
    },
});
