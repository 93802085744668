import { areIntervalsOverlapping } from "date-fns";
import { memoize } from "lodash";
import React, { useMemo, ReactNode, ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { TimelineUnit } from "./useTimeline";

type Props = {
    start: Date | number;
    end: Date | number;
    unit: TimelineUnit;
    className?: string;
    render: (interval: Interval, index: number) => ReactNode;
} & ComponentPropsWithoutRef<"div">;

const ScaleGenerator = ({ start, end, unit, render, ...props }: Props) => {
    const items = useMemo(() => {
        const result = [];
        const visibleInterval = { start, end };
        const origin = unit.floor(visibleInterval.start);

        const interval = memoize((i: number) => {
            const start = unit.add(origin, i);
            return {
                start,
                end: unit.ceil(start),
            };
        });

        for (let i = 0; areIntervalsOverlapping(interval(i), visibleInterval); i++) {
            result.push(interval(i));
        }

        return result;
    }, [start, end, unit]);

    return <div {...props}>{items.map(render)}</div>;
};

export default styled(ScaleGenerator)``;
