import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Users } from "@sol/icons";
import { ISkillLevelMetric } from "@sol/sdk";

import { Text } from "../../../../uikit";
import SROnly from "../../../../uikit/SROnly";

type ProgressbarProps = {
    value: number;
    minValue?: number;
    maxValue?: number;
    className?: string;
    a11yLabel?: string;
};

type ProgressProps = { percent: number; value: number };

const Progress = styled.div<ProgressProps>`
    height: 100%;
    min-width: ${({ value }) => (value > 0 ? "12px" : 0)};
    width: ${({ percent }) => `${percent}%`};
    background: ${({ theme }) => theme.palette.yellow.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
`;

const Progressbar = styled(({ value, minValue = 0, maxValue = 100, className, a11yLabel }: ProgressbarProps) => {
    const percent = (value * 100) / maxValue;

    return (
        <div
            className={className}
            role="progressbar"
            aria-valuetext={a11yLabel}
            aria-valuenow={value}
            aria-valuemin={minValue}
            aria-valuemax={maxValue}
        >
            <Progress percent={percent} value={value} />
        </div>
    );
})`
    height: 12px;
    min-width: 140px;
    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
    background-color: ${({ theme }) => theme.palette.grey.lightest};
`;

type SkillLevelProgressProps = {
    level: number;
    value: number;
    total: number;
    label: string;
    className?: string;
};

const SkillLevelLabel = styled(Text).attrs({ variant: "subheading" })`
    font-size: 10px;
    color: ${({ theme }) => theme.palette.grey.base};
`;

const LearnersCount = styled(SkillLevelLabel)`
    color: ${({ theme }) => theme.palette.black.base};
`;

const UsersIcon = styled(Users)`
    width: 17px;
    height: 16px;
    margin-left: ${({ theme }) => theme.spacing[3]};

    .colorStroke {
        stroke: ${({ theme }) => theme.palette.grey.base};
    }
`;

const SkillLevelProgress = styled(({ level, value, total, className, label }: SkillLevelProgressProps) => {
    const [t] = useTranslation();

    const accessibilityLabel = t("component.SkillMetrics.skillLevelMetrics", { value, total, label, level });

    return (
        <div title={accessibilityLabel} className={className}>
            <SkillLevelLabel aria-hidden={true}>
                {t("component.SkillMetrics.levelShortcut", { level })}
                <SROnly>{t("component.SkillMetrics.levelLabel", { level })}</SROnly>
            </SkillLevelLabel>
            <Progressbar value={value} maxValue={total} a11yLabel={accessibilityLabel} />
            <LearnersCount aria-hidden={true}>{value}</LearnersCount>
            <UsersIcon />
        </div>
    );
})`
    display: flex;
    align-items: center;

    ${Progressbar} {
        flex: 1;
        margin: 0 ${({ theme }) => theme.spacing[2]};
    }
`;

type Props = {
    label: string;
    levelValidationsMetrics: ISkillLevelMetric[];
    className?: string;
};

const SkillMetricsCard = ({ label, levelValidationsMetrics, className }: Props) => {
    return (
        <div className={className}>
            <Text variant="subheading">{label}</Text>
            {levelValidationsMetrics.map(item => (
                <SkillLevelProgress
                    key={item.level}
                    level={item.level}
                    total={item.total}
                    value={item.number}
                    label={label}
                />
            ))}
        </div>
    );
};

export default styled(SkillMetricsCard)`
    ${({ theme }) => css`
        min-height: 160px;
        border: 1px solid ${theme.palette.grey.lightest};
        border-radius: ${theme.shape.borderRadius.medium};
        background-color: ${theme.palette.white.base};
        padding: ${({ theme }) => theme.spacing[5]};

        > ${Text} {
            text-align: left;
            min-height: 48px;
        }

        > * + * {
            margin-top: ${({ theme }) => theme.spacing[4]};
        }
    `}
`;
