import React, { memo, ComponentPropsWithoutRef, Ref, forwardRef } from "react";
import styled, { css } from "styled-components";
import { Merge } from "type-fest";

import Text from "../../../../uikit/Text";

type Props = Merge<
    {
        label: string;
        last?: boolean;
    },
    ComponentPropsWithoutRef<"div">
>;

const SubScaleCell = ({ label, ...props }: Props, ref: Ref<HTMLDivElement>) => {
    return (
        <div ref={ref} {...props}>
            <Text variant="label-small" aria-hidden="true">
                {label}
            </Text>
        </div>
    );
};

export default styled(memo(forwardRef(SubScaleCell)))`
    display: flex;
    flex-direction: column;
    align-items: start;

    > ${Text} {
        white-space: nowrap;

        color: ${({ theme }) => theme.palette.grey.base};

        text-align: center;
        transform: translateX(-50%);

        background-color: ${({ theme }) => theme.palette.white.base};
        border: ${({ theme }) => theme.spacing[3]} solid ${({ theme }) => theme.palette.white.base};

        box-sizing: content-box;
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }

    border-left: 2px dashed ${({ theme }) => theme.palette.grey.lightest};
    ${({ last }) =>
        last &&
        css`
            border-right: 2px dashed ${({ theme }) => theme.palette.grey.lightest};
        `}
`;
