import { areIntervalsOverlapping, getHours, parse, differenceInSeconds, isAfter, isBefore } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiErrorAlert from "src/components/ApiErrorAlert";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Arrow, Calendar, Loading, Check, Cross } from "@sol/icons";
import { parseDate } from "@sol/sdk";
import { IEducationalScenarioItem } from "@sol/sdk/educationalScenarioItems/IEducationalScenarioItem";
import { usePutEducationalScenarioItemMutation } from "@sol/sdk/educationalScenarioItems/usePutEducationalScenarioItemMutation";
import { EducationalScenarioSlot } from "@sol/sdk/educationalScenarios/IEducationalScenarioSlot";
import { formatDate } from "@sol/sdk/utils/parseDate";
import { Button } from "@sol/uikit";

import PedagogicalScenarioDateRange from "../DatePicker/PedagogicalScenarioDateRange";

const DatePickerButton = styled.button`
    --icon-color: ${({ theme }) => theme.palette.grey.base};

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: ${({ theme }) => theme.spacing[6]};
    width: fit-content;
    height: 18px;
    background: none;
    position: relative;
    border: 0;
    color: ${({ theme }) => theme.palette.grey.base};

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: ${({ theme }) => theme.palette.grey.base};
    }
`;

const DateText = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[3]};
    padding: 0;
    font-size: 12px;
    line-height: 16px;
`;

const DatePickerModal = styled(motion.div)`
    position: absolute;
    width: 100%;
    max-width: 1058px;
    top: ${({ theme }) => theme.spacing[4]};
    left: ${({ theme }) => theme.spacing[4]};
    padding: 1rem;
    background: ${({ theme }) => theme.palette.white.base};
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.08);
    border-radius: ${({ theme }) => theme.spacing[4]};
    z-index: ${({ theme }) => theme.zIndex.overlay};
    box-sizing: border-box;
`;

const DatePickerOverlay = styled(motion.div)`
    &:after {
        content: "";
        position: absolute;
        height: 385px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(255, 255, 255, 0.6);
        z-index: ${({ theme }) => theme.zIndex.overlay};
    }
`;

const DatePickerActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Error = styled(Text)`
    color: ${({ theme }) => theme.palette.red.base};
`;

type Props = {
    currentTab: string;
    targetTab: string;
    isClassroomTrainer: boolean;
    startDate?: string;
    endDate?: string;
    educationalScenarioItemId: string;
    educationalScenarioItems?: EducationalScenarioSlot[];
    classroomStartDate?: string;
    classroomEndDate?: string;
    refreshTimeline: () => void;
    refetchPedagogicalScenarioItem: () => Promise<IEducationalScenarioItem | undefined>;
    setTimelineStartDate: (date: Date) => void;
    setTimelineScale: (scale: string) => void;
};

export enum DayPeriod {
    MORNING = "morning",
    MIDDAY = "midday",
    NIGHT = "night",
}

type SubmitValues = {
    period: { startDate: Date; endDate: Date; key: string };
};

const ProfessionalSituationDatePicker = ({
    currentTab,
    targetTab,
    isClassroomTrainer,
    startDate,
    endDate,
    educationalScenarioItemId,
    educationalScenarioItems,
    refreshTimeline,
    refetchPedagogicalScenarioItem,
    setTimelineStartDate,
    setTimelineScale,
    classroomStartDate,
    classroomEndDate,
}: Props) => {
    const [t] = useTranslation();
    const submitButtonRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [mutationError, setMutationError] = useState(null);
    const [isUserInputValid, setIsUserInputValid] = useState(true);
    const { updatedEducationalScenarioItem, isLoading: educationalScenarioItemMutationLoading } =
        usePutEducationalScenarioItemMutation({
            onSuccess: ({ data }) => {
                const { startDate } = data;
                refreshTimeline();
                refetchPedagogicalScenarioItem();
                setTimelineScale("day");
                setTimelineStartDate(new Date(startDate));
                setShowModal(false);
            },
            onError: ({ response }) => setMutationError(response),
        });

    const defaultValues = useMemo(
        () => ({
            period:
                startDate && endDate
                    ? {
                          key: "professional-situation",
                          startDate: parseDate(startDate),
                          endDate: parseDate(endDate),
                      }
                    : undefined,
        }),
        [startDate, endDate],
    );

    const {
        control,
        handleSubmit,
        clearErrors,
        reset,
        formState: { isDirty, isSubmitting, errors },
    } = useForm({
        mode: "onChange",
    });

    const handleDatePickerButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        clearErrors();
    };

    useEffect(() => {
        handleCloseModal();
        reset(defaultValues);
    }, [educationalScenarioItemId, educationalScenarioItems, defaultValues]);

    const onSubmit = (values: SubmitValues) => {
        const {
            period: { startDate, endDate },
        } = values;

        const payload = {
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
        };

        updatedEducationalScenarioItem({
            payload,
            educationalScenarioItemId,
        });
    };
    const isValidInterval = (range: { start: Date; end: Date }): boolean => {
        const { start, end } = range;
        return differenceInSeconds(end, start) > 0 && isBefore(start, end) && isAfter(end, start);
    };

    const isOverlapping = (range: { start: Date; end: Date }, currentEducationalScenarioItemId: string): boolean => {
        const { start, end } = range;
        if (differenceInSeconds(end, start) > 1) {
            const colisions = educationalScenarioItems?.findIndex(({ startDate, endDate, uuid }) => {
                return (
                    areIntervalsOverlapping(
                        { start, end },
                        {
                            start: parse(startDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()),
                            end: parse(endDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()),
                        },
                    ) && uuid !== currentEducationalScenarioItemId
                );
            });

            return colisions ? colisions === -1 : true;
        }
        return true;
    };

    const getDateHours = (date: string) => getHours(parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date()));

    const getUserInputValidation = (isValid: boolean) => {
        setIsUserInputValid(isValid);
    };

    return (
        <>
            {isClassroomTrainer && currentTab === targetTab && startDate && endDate && (
                <DatePickerButton
                    type="button"
                    onClick={handleDatePickerButtonClick}
                    data-cy="professional-situation-date-picker-button"
                    className="focus-classes my-2 rounded-lg "
                >
                    <DateText>
                        <Calendar size={16} />
                        {t("component.home.PedagogicalScenarioCard.professionalSituationStartDate", {
                            date: parseDate(startDate),
                        })}
                        <span>
                            {getDateHours(startDate) < 12
                                ? t(`component.home.PedagogicalScenarioCard.dayPeriod.${DayPeriod.MORNING}`)
                                : t(`component.home.PedagogicalScenarioCard.dayPeriod.${DayPeriod.MIDDAY}`)}
                        </span>
                    </DateText>
                    <Arrow direction="right" size={16} />
                    <DateText>
                        <Calendar size={16} />
                        {t("component.home.PedagogicalScenarioCard.professionalSituationEndDate", {
                            date: parseDate(endDate),
                        })}
                        <span>
                            {getDateHours(endDate) < 12
                                ? t(`component.home.PedagogicalScenarioCard.dayPeriod.${DayPeriod.MIDDAY}`)
                                : t(`component.home.PedagogicalScenarioCard.dayPeriod.${DayPeriod.NIGHT}`)}
                        </span>
                    </DateText>
                </DatePickerButton>
            )}
            <AnimatePresence>
                {showModal && startDate && endDate && (
                    <form onSubmit={handleSubmit(onSubmit)} data-cy="professional-situation-date-form">
                        <DatePickerOverlay
                            initial={{ visibility: "hidden" }}
                            animate={{ visibility: "visible" }}
                            exit={{ visibility: "hidden" }}
                        />
                        <DatePickerModal initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <Controller
                                name="period"
                                control={control}
                                defaultValue={defaultValues.period}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <PedagogicalScenarioDateRange
                                            onChange={onChange}
                                            range={value}
                                            getUserInputValidation={getUserInputValidation}
                                            classroomStartDate={classroomStartDate}
                                            classroomEndDate={classroomEndDate}
                                        />
                                    );
                                }}
                                rules={{
                                    validate: {
                                        isOverlapping: (values: { startDate: Date; endDate: Date }) =>
                                            isOverlapping(
                                                { start: values.startDate, end: values.endDate },
                                                educationalScenarioItemId,
                                            ),
                                        isValidInterval: (values: { startDate: Date; endDate: Date }) =>
                                            isValidInterval({ start: values.startDate, end: values.endDate }),
                                    },
                                }}
                            />
                            <DatePickerActionButtonsWrapper>
                                <Button
                                    type="reset"
                                    variant="primary"
                                    outlined={false}
                                    onClick={handleCloseModal}
                                    disabled={educationalScenarioItemMutationLoading}
                                >
                                    {t("component.home.PedagogicalScenarioCard.closeDateModal")}
                                    <Cross />
                                </Button>
                                <Button
                                    ref={submitButtonRef}
                                    type="submit"
                                    variant="primary"
                                    filled={true}
                                    disabled={!isDirty || isSubmitting || !!errors.period || !isUserInputValid}
                                    data-cy="professional-situation-date-submit-button"
                                >
                                    {t("component.home.PedagogicalScenarioCard.confirmDateChange")}
                                    {educationalScenarioItemMutationLoading ? <Loading /> : <Check />}
                                </Button>
                            </DatePickerActionButtonsWrapper>
                            {errors.period && errors.period.type === "isOverlapping" && (
                                <Error textAlign="right" data-cy="professional-situation-date-error">
                                    {t("component.home.PedagogicalScenarioCard.overlapping")}
                                </Error>
                            )}
                            {errors.period && errors.period.type === "isValidInterval" && (
                                <Error textAlign="right" data-cy="professional-situation-date-error">
                                    {t("component.home.PedagogicalScenarioCard.invalidInterval")}
                                </Error>
                            )}
                        </DatePickerModal>
                    </form>
                )}
            </AnimatePresence>
            <ApiErrorAlert
                error={mutationError}
                dissmiss={() => setMutationError(null)}
                triggerElement={submitButtonRef}
            />
        </>
    );
};

export default ProfessionalSituationDatePicker;
