import { formatDistanceToNow } from "date-fns";
import { enGB, es, fr } from "date-fns/locale";
import { isString } from "lodash";
import React, { ComponentPropsWithoutRef, RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import UserStatusLabel from "src/components/UserStatusLabel";
import useActiveClassroomFrameworks from "src/hooks/useActiveClassroomFrameworks";
import useLocale from "src/hooks/useLocale";
import { Text } from "src/uikit";
import styled from "styled-components";

import { useActiveClassroom } from "@sol/classrooms";
import { PaperPlane } from "@sol/icons";
import {
    getResourceUuid,
    IClassroomItem,
    IClassroomUser,
    IFramework,
    IFrameworkItem,
    IFrameworkSkill,
    IUser,
    IValidationResource,
    useFrameworks,
} from "@sol/sdk";

import FrameworkSkillsValidations from "../../FrameworkSkillsValidations";
import LearnerResultsDownloadButton from "../../shared/LearnerResultsDownloadButton";
import BlockSkeleton from "../../skeletons/BlockSkeleton";
import SkillsMetrics from "../../SkillsMetrics";

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing[5]};
`;

const HeaderLeftSide = styled.div`
    display: flex;
    align-items: center;
`;

const Name = styled(Text).attrs({ variant: "h4", as: "h2" })`
    margin-top: 0;
    margin-bottom: 0;
`;

const Surname = styled(Name).attrs({ as: "span" })`
    text-transform: uppercase;
`;

const Email = styled(Text).attrs({ variant: "subheading", as: "p" })`
    color: ${({ theme }) => theme.palette.grey.base};
    margin-left: ${({ theme }) => theme.spacing[6]};
`;

const WrapperSkillLevelCard = styled.ul`
    display: flex;
    flex-direction: column;

    position: relative;
    margin: 0;
    padding: 0;

    ${FrameworkSkillsValidations} {
        border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

        > div {
            border-bottom: 0;
        }
    }

    ${FrameworkSkillsValidations} + ${FrameworkSkillsValidations} {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;

const Frameworks = styled.div`
    overflow: auto;
`;

const LearnersSkillsEmpty = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    ${Text} {
        font-weight: 900;
    }
`;

const ConnectionContainer = styled.div`
    margin-left: ${({ theme }) => theme.spacing[5]};
    display: flex;
    align-items: center;
`;

const LastConnection = styled(Text).attrs({ variant: "subheading", as: "span" })`
    margin: 0 0 0 ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.palette.grey.base};
`;

const ConnectionStatus = styled.span`
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.grey.base};
    margin-right: ${({ theme }) => theme.spacing[2]};
`;

const StatusLabel = styled(UserStatusLabel)`
    ${({ theme }) => theme.typography.subheading};

    color: ${({ theme }) => theme.palette.purple.base};
    text-transform: lowercase;
    margin-right: ${({ theme }) => theme.spacing[2]};
`;

const MetricsContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const PaperplaneIcon = styled(PaperPlane)`
    width: 12px;
    --icon-color: ${({ theme }) => theme.palette.purple.base};
`;

const localeToDateFNSLocale: { [key: string]: Locale } = {
    en: enGB,
    fr,
    es,
};

type Props = {
    lastLearnerClassroom?: IClassroomItem;

    learner: IClassroomUser | IUser;
    validations: IValidationResource[];
    isLoading: boolean;
    focusRef: RefObject<HTMLHeadingElement>;
} & ComponentPropsWithoutRef<"div">;

const LearnerDetails = ({ lastLearnerClassroom, learner, validations, isLoading, focusRef, ...rest }: Props) => {
    const { name, surname, email, connectedAt, status } = learner;
    const [locale] = useLocale();
    const [t] = useTranslation();
    const { activeClassroom } = useActiveClassroom();

    //queryOptim:: We chose a time of 24 hours before the data is considered stale so that users
    //who keep their browser tabs open without refreshing the page will at least get their data updated every 24 hours.
    const { data: learnerFrameworks, isLoading: isLoadingLearnerFrameworks } = useFrameworks({
        filters: {
            learners: learner && getResourceUuid(learner),
        },
        enabled: !activeClassroom,
        staleTime: 1000 * 60 * 60 * 24,
    });

    const frameworks: (IFrameworkItem | IFramework)[] = activeClassroom
        ? useActiveClassroomFrameworks()
        : learnerFrameworks
        ? learnerFrameworks["hydra:member"]
        : [];

    const skills = useMemo(() => {
        const acc: string[] = [];
        frameworks.forEach(framework => {
            framework.skills.forEach((skill: IFrameworkSkill | string) => {
                const skillId = isString(skill) ? skill : skill["@id"];
                if (!acc.includes(skillId)) {
                    acc.push(skillId);
                }
            });
        });

        return acc;
    }, [frameworks]);

    let children =
        isLoading || isLoadingLearnerFrameworks ? (
            <LearnersSkillsEmpty>
                <BlockSkeleton />
            </LearnersSkillsEmpty>
        ) : (
            <LearnersSkillsEmpty>
                <Text as="p" variant="h3">
                    {t("page.classrooms.home.noSkill")}
                </Text>
            </LearnersSkillsEmpty>
        );

    if (validations && validations.length > 0) {
        children = (
            <>
                <MetricsContainer>
                    <SkillsMetrics skillsCount={skills.length} validations={validations} isInCard />
                </MetricsContainer>
                <Frameworks>
                    <WrapperSkillLevelCard>
                        {frameworks.map(framework => (
                            <FrameworkSkillsValidations
                                key={framework["@id"]}
                                framework={framework}
                                validations={validations}
                                learner={learner}
                                isInCard
                            />
                        ))}
                    </WrapperSkillLevelCard>
                </Frameworks>
            </>
        );
    }

    const classroom = activeClassroom ? activeClassroom : lastLearnerClassroom;

    return (
        <div {...rest}>
            <Header>
                <HeaderLeftSide>
                    <Name tabIndex={-1} ref={focusRef}>
                        {name} <Surname>{surname}</Surname>
                    </Name>
                    <Email>{email}</Email>
                    <ConnectionContainer>
                        {connectedAt ? (
                            <LastConnection>
                                <ConnectionStatus />
                                {formatDistanceToNow(new Date(connectedAt), {
                                    locale: localeToDateFNSLocale[locale as string],
                                })}
                            </LastConnection>
                        ) : (
                            <>
                                <StatusLabel status={status} />
                                <PaperplaneIcon />
                            </>
                        )}
                    </ConnectionContainer>
                </HeaderLeftSide>
                {classroom && (
                    <LearnerResultsDownloadButton classroom={classroom} learner={learner}>
                        {activeClassroom
                            ? t("page.classrooms.home.learners.learnerTrainingReport.default")
                            : t("page.classrooms.home.learners.learnerTrainingReport.lastClassroom")}
                    </LearnerResultsDownloadButton>
                )}
            </Header>

            {children}
        </div>
    );
};

export default styled(LearnerDetails)`
    padding: ${({ theme }) => theme.spacing[5]} ${({ theme }) => theme.spacing[5]} 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    ${LearnersSkillsEmpty},
    ${Frameworks} {
        flex: 1;
        min-height: 0;
    }
`;
