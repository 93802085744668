import qs from "qs";
import { FC, ComponentPropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";
import useLocale from "src/hooks/useLocale";
import useToken from "src/hooks/useToken";
import styled from "styled-components";

import { Download } from "@sol/icons";
import { IUserResource, IClassroom, getResourceUuid, getUserDisplayName } from "@sol/sdk";
import { Button } from "@sol/uikit";

import { sanitizeFilename } from "./utils";

type Props = {
    variant?: "default" | "primary";
    learner: IUserResource;
    classroom: Pick<IClassroom, "@id" | "@type">;
} & Omit<ComponentPropsWithoutRef<"a">, "href">;

const LearnerResultsDownloadButton: FC<Props> = ({
    learner,
    classroom,
    children,
    download,
    variant = "primary",
    ...rest
}) => {
    const [locale] = useLocale();
    const token = useToken();
    const [t] = useTranslation();

    const fileName = sanitizeFilename(
        download ??
            t("component.shared.LearnerResultsDownloadButton.filename", {
                date: new Date(),
                displayName: getUserDisplayName(learner),
            }),
    );

    const href = `/api/users/${getResourceUuid(learner)}/classrooms/${getResourceUuid(
        classroom,
    )}/results?${qs.stringify({ token, locale })}`;

    return variant === "primary" ? (
        <Button as="a" href={href} download={fileName} small outlined variant="primary" {...rest}>
            {children}
            <Download />
        </Button>
    ) : (
        <a href={href} download={fileName}>
            {children}
        </a>
    );
};

export default styled(LearnerResultsDownloadButton)``;
