import React, { useRef, FC, RefObject, Key, useMemo, ReactNode } from "react";
import {
    HiddenSelect,
    useSelect,
    useButton,
    AriaButtonProps,
    AriaSelectProps,
    useFocusRing,
    mergeProps,
} from "react-aria";
import { useSelectState } from "react-stately";

import { Chevron } from "@sol/uikit/core/icons";

import { OptionsList } from "./shared/OptionsList";
import { Popover, type PopoverWidth } from "./shared/Popover";
import { selectVariants, type SelectVariantsProps } from "./variants";

type ItemsProps = {
    key: Key;
    label: string;
    component?: () => ReactNode;
};

export type SelectProps<T> = {
    isLabelHidden?: boolean;
    width?: PopoverWidth;
    isLoading?: boolean;
    items: ItemsProps[];
    className?: string;
    iconColor?: string;
    focusClasses?: string;
} & AriaSelectProps<T> &
    SelectVariantsProps;

type SelectButtonProps = {
    buttonRef: RefObject<HTMLButtonElement>;
    className?: string;
} & AriaButtonProps<"button">;

const SelectButton: FC<SelectButtonProps> = ({ buttonRef, className, ...props }) => {
    const ref = buttonRef;
    const { buttonProps } = useButton(props, ref);

    return (
        <button {...buttonProps} ref={ref} className={className}>
            {props.children}
        </button>
    );
};

const Loader = ({
    variant,
    size,
}: {
    variant: "primary" | "secondary" | "cta" | null;
    size: "standard" | "small" | null;
}) => {
    return (
        <div
            className={`${size === "small" ? "m-0.5 h-0.5" : "m-2 h-3"} w-full min-w-[100px] rounded-full ${
                variant === "secondary" ? "bg-grey-lightest" : "bg-white-base"
            } animate-pulse`}
        ></div>
    );
};
export const Select = <T extends object>({
    variant = "primary",
    isLabelHidden = true,
    width = "standard",
    size = "standard",
    isLoading,
    className,
    iconColor,
    focusClasses,
    ...props
}: SelectProps<T>) => {
    const state = useSelectState(props);

    const ref = useRef(null);
    const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, ref);
    const { focusProps, isFocusVisible } = useFocusRing();

    const iconStroke = useMemo(() => {
        if (iconColor) {
            return iconColor;
        }

        if (variant === "cta") {
            return "white-base";
        } else {
            return "purple-base";
        }
    }, [variant, iconColor]);

    let defaultFocusClasses = "";
    const isOpenClasses = "translate-x-[calc(-1*3px)] translate-y-[calc(-1*3px)] shadow-primary";
    const isLoadingClasses = "cursor-not-allowed";

    if (!focusClasses && isFocusVisible) {
        defaultFocusClasses = "ring ring-blue-focus ring-offset-2 ring-offset-white-base";
    }

    return (
        <>
            <div {...labelProps} className={isLabelHidden ? "sr-only" : "mb-2 block"}>
                {props.label}
            </div>
            <HiddenSelect
                isDisabled={props.isDisabled || isLoading}
                state={state}
                triggerRef={ref}
                label={props.label}
                name={props.name}
            />
            <SelectButton
                {...mergeProps(triggerProps, focusProps)}
                isDisabled={props.isDisabled || isLoading}
                buttonRef={ref}
                className={selectVariants({
                    variant,
                    size,
                    className: `${state.isOpen && isOpenClasses} ${
                        focusClasses && isFocusVisible ? focusClasses : defaultFocusClasses
                    } ${isLoading ? isLoadingClasses : ""} ${className} outline-none`,
                })}
            >
                {isLoading ? (
                    <Loader variant={variant} size={size} />
                ) : (
                    <>
                        <span {...valueProps} className="line-clamp-1">
                            {state.selectedItem ? state.selectedItem.rendered : props.label}
                        </span>
                        <span aria-hidden="true">
                            <Chevron
                                direction={state.isOpen ? "up" : "down"}
                                fill="transparent-base"
                                stroke={iconStroke}
                            />
                        </span>
                    </>
                )}
            </SelectButton>
            {state.isOpen && (
                <Popover state={state} triggerRef={ref} placement="bottom start" offset={6} width={width}>
                    <OptionsList {...menuProps} state={state} variant={variant} />
                </Popover>
            )}
        </>
    );
};
