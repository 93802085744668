import React from "react";
import { ButtonGroupProps } from "react-multi-carousel";
import styled from "styled-components";

import CarouselArrow from "./CarouselArrow";

type NavigationProps = {
    className?: string;
} & ButtonGroupProps;

const Navigation = ({ carouselState, goToSlide, next, previous, className }: NavigationProps) => {
    const handleNext = () => {
        if (carouselState && goToSlide && next) {
            const { currentSlide, totalItems, slidesToShow } = carouselState;
            const isLastPage = totalItems === slidesToShow + currentSlide;

            if (isLastPage) {
                return goToSlide(0);
            } else {
                return next();
            }
        }
    };

    const showPreviousArrow = carouselState?.currentSlide !== 0;
    const showNextArrow = carouselState && carouselState?.totalItems > carouselState?.slidesToShow;

    return (
        <div className={className}>
            {showPreviousArrow && <CarouselArrow direction="left" onClick={previous} />}
            {showNextArrow && <CarouselArrow direction="right" onClick={handleNext} />}
        </div>
    );
};

export default styled(Navigation)`
    position: absolute;
    width: 100%;
    top: calc(50% - ${({ theme }) => theme.spacing[5]});
`;
