import { useRouter } from "next/router";
import React, { FC } from "react";

import { useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { Route } from "@sol/routing";

const NoClassroomRedirectWrapper: FC = ({ children }) => {
    const router = useRouter();
    const { classrooms } = useAuthenticatedUserClassrooms();

    if (classrooms && classrooms.length === 0) {
        router.push(Route.PROFILE);

        return null;
    }

    return <>{children}</>;
};

export default NoClassroomRedirectWrapper;
