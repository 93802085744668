import isAfter from "date-fns/isAfter";
import React, { useEffect, useMemo, useRef, useState, ChangeEvent } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import useSearch from "src/hooks/useSearch";
import { InputBase, Label } from "src/uikit/TextInput";
import styled from "styled-components";

import { useActiveClassroom } from "@sol/classrooms";
import { getResourceUuid, IClassroomUser, IUser, IValidationResource } from "@sol/sdk";
import { useClassrooms } from "@sol/sdk/classrooms/useClassrooms";
import { useValidations } from "@sol/sdk/validations/useValidations";

import { Grid, GridColumn } from "../../../modules/uikit/layouts/Grid";
import { Text } from "../../../uikit";
import UsersList from "../../shared/UsersList";
import BlockSkeleton from "../../skeletons/BlockSkeleton";
import LearnersSkills from "./LearnerDetails";

const MasterDetails = styled(GridColumn)`
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${({ theme }) => theme.palette.grey.lightest};

    ${UsersList} {
        flex: 1;
        overflow: auto;
    }
`;

const LearnersExplorerEmpty = styled(GridColumn)`
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;
    min-height: 0;
    min-width: 0;
`;

const SearchContainer = styled.form`
    position: relative;

    padding: ${({ theme }) => theme.spacing[5]} ${({ theme }) => theme.spacing[5]} ${({ theme }) => theme.spacing[6]};

    & > input {
        min-height: 48px;
        margin-bottom: 0;
    }
`;

const InputSearch = styled(InputBase)`
    background-color: ${({ theme }) => theme.palette.white.base};
    border: 1px solid ${({ theme }) => theme.palette.grey.base};
    padding-left: ${({ theme }) => theme.spacing[4]};

    &:focus {
        border: 1px solid ${({ theme }) => theme.palette.black.base};
        padding-left: ${({ theme }) => theme.spacing[4]};
    }
`;

type Props = {
    isPending?: boolean;
    learners: (IClassroomUser | IUser)[];
};

const LearnersExplorer = ({ isPending, learners, ...rest }: Props) => {
    const [t] = useTranslation();
    const [selectedLearner, setSelectedLearner] = useState<IClassroomUser | IUser>();
    const [isLearnerSelected, setIsLearnerSelected] = useState(false);
    const { activeClassroom } = useActiveClassroom();
    const focusRef = useRef<HTMLHeadingElement>(null);
    const skipFocusRef = useRef(true);
    const searchInputId = useId();

    const { results, search, setSearch } = useSearch<IClassroomUser | IUser>(learners, { keys: ["name", "surname"] });

    useEffect(() => {
        setSelectedLearner(learners.length > 0 ? learners[0] : undefined);
        setIsLearnerSelected(false);
    }, [isPending, learners]);

    const filters = useMemo(() => {
        if (!selectedLearner) {
            return;
        }

        const uuidLearner = getResourceUuid(selectedLearner);
        const classroomFilter = activeClassroom ? { classroom: getResourceUuid(activeClassroom) } : {};

        return {
            learner: uuidLearner,
            ...classroomFilter,
        };
    }, [activeClassroom, selectedLearner]);

    const {
        data: skillValidationsList,
        isLoading,
        refetch: fetchSkillLearner,
    } = useValidations({
        filters,
        enabled: !!selectedLearner && !!filters,
    });

    useEffect(() => {
        if (selectedLearner) {
            fetchSkillLearner();

            if (!skipFocusRef.current && isLearnerSelected) {
                focusRef?.current?.focus();
            }
            skipFocusRef.current = false;
        }
    }, [selectedLearner, isLearnerSelected]);

    //If no active classroom, we fetch selected learners classrooms order by startDate
    const { data: selectedLearnerClassrooms } = useClassrooms(
        !activeClassroom
            ? {
                  orderBy: "startDate",
                  filters: {
                      learner: selectedLearner && selectedLearner,
                  },
                  enabled: selectedLearner,
              }
            : { filters: {}, enabled: false },
    );

    const lastLearnerClassroom = selectedLearnerClassrooms?.["hydra:member"][0];

    let itemToRender = (
        <LearnersExplorerEmpty col={12}>
            <Text textAlign="center" variant="h3">
                {t("page.classrooms.home.noLearners")}
            </Text>
        </LearnersExplorerEmpty>
    );

    const skillsValidations: IValidationResource[] = skillValidationsList ? skillValidationsList["hydra:member"] : [];

    //Here we keep only the most recent skillValidations for a same framework found in multiple classrooms (tutor)
    const filterSkillsValidations = useMemo(() => {
        const sortedValidationsByUpdatedAt: IValidationResource[] = skillsValidations.sort(
            (firstValidation, nextValidation) =>
                new Date(nextValidation.updatedAt).getTime() - new Date(firstValidation.updatedAt).getTime(),
        );
        return sortedValidationsByUpdatedAt.reduce((acc, current) => {
            const duplicates = acc.find((validation: IValidationResource) => {
                return (
                    validation.skillLevel["@id"] === current.skillLevel["@id"] &&
                    isAfter(new Date(validation.updatedAt), new Date(current.updatedAt))
                );
            });
            if (!duplicates) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, [] as IValidationResource[]);
    }, [skillsValidations]);

    const validations = activeClassroom ? skillsValidations : filterSkillsValidations;

    if (isPending || (learners.length > 0 && !selectedLearner)) {
        itemToRender = (
            <GridColumn col={12}>
                <BlockSkeleton />
            </GridColumn>
        );
    } else if (learners?.length > 0) {
        itemToRender = (
            <>
                <MasterDetails col={3}>
                    <SearchContainer>
                        <Label htmlFor={searchInputId}>{t("component.SelfEvaluationTrainer.searchLabel")}</Label>
                        <InputSearch
                            data-cy="search"
                            value={search}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.currentTarget.value)}
                            placeholder={t("component.SelfEvaluationTrainer.searchPlaceholder")}
                            id={searchInputId}
                        />
                    </SearchContainer>
                    <div aria-live="polite" className="overflow-auto">
                        <p className="sr-only">
                            {results.length > 0
                                ? t("component.UsersList.userCount", { count: results.length })
                                : t("component.UsersList.noResult")}
                        </p>
                        <UsersList
                            users={results}
                            selectedUserId={selectedLearner && getResourceUuid(selectedLearner)}
                            setSelectedUser={learner => {
                                if (learner) {
                                    setSelectedLearner(learner as IUser);
                                }

                                if (selectedLearner) {
                                    setIsLearnerSelected(true);
                                }
                            }}
                        />
                    </div>
                </MasterDetails>
                {selectedLearner && (
                    <GridColumn col={9} role="tabpanel">
                        <LearnersSkills
                            lastLearnerClassroom={lastLearnerClassroom}
                            learner={selectedLearner}
                            validations={validations}
                            isLoading={isLoading}
                            focusRef={focusRef}
                        />
                    </GridColumn>
                )}
            </>
        );
    }

    return <Grid {...rest}>{itemToRender}</Grid>;
};

export default styled(LearnersExplorer)`
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    height: 490px;

    > * {
        height: inherit;
    }

    overflow: hidden;
`;
