import { AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import { useMutation, MutateConfig } from "react-query";

import { IBrief } from "@sol/sdk";
import { useApi } from "@sol/sdk/SDKProvider";

interface PatchBriefVariables {
    payload: Pick<
        IBrief,
        "title" | "context" | "description" | "expectedWork" | "pedagogicModalities" | "performanceCriteria" | "status"
    >;
    briefId?: string;
}
export const putBrief =
    (api: AxiosInstance) =>
    ({ payload, briefId }: PatchBriefVariables) =>
        api.put<IBrief>(`briefs/${briefId}`, payload);

export const useUpdateBrief = (config: MutateConfig<AxiosResponse<IBrief>, any, PatchBriefVariables>) => {
    const api = useApi();
    const putBriefMutation = useMemo(() => putBrief(api), [api]);
    const [updateBrief, { isSuccess, error, reset, isLoading }] = useMutation(putBriefMutation, config);

    return { updateBrief, isSuccess, error, reset, isLoading };
};
