import { transparentize } from "polished";
import React, { FC, HTMLProps } from "react";
import FocusTrap from "react-focus-trap";
import styled from "styled-components";

const Content = styled.div`
    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    max-width: 846px;
    box-sizing: border-box;
    transition: all ease 0.2s;
`;

const StyledFocusTrap = styled(FocusTrap)`
    padding: 48px;
    width: 100%;
    height: 100%;
`;

type Props = {
    visible: boolean;
    className?: string;
    ariaLabelledBy?: string;
} & HTMLProps<HTMLDivElement>;

const Modal: FC<Props> = ({ className, children, visible, ariaLabelledBy, ...rest }) => {
    return (
        <div className={className} {...rest}>
            <Content role="dialog" aria-modal="true" aria-labelledby={ariaLabelledBy}>
                <StyledFocusTrap active={visible}>{children}</StyledFocusTrap>
            </Content>
        </div>
    );
};

export default styled(Modal)<Props>`
    top: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => transparentize(0.33, theme.palette.black.base)};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${({ theme }) => theme.zIndex.overlay};
    visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

    ${Content} {
        transform: ${({ visible }) => `scale(${visible ? 1 : 0})`};
    }
`;
