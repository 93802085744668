import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import { useState } from "react";
import { useId } from "react-aria";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MediaResourceType } from "src/components/shared/ResourceInput";
import { SROnly, Text } from "src/uikit";
import styled, { css } from "styled-components";
import * as yup from "yup";

import { Check, Loading, Upload } from "@sol/icons";
import { MediaType } from "@sol/sdk";
import useMediaUpload from "@sol/sdk/media/useMediaUpload";
import { Button, InputDecorator, TextInput } from "@sol/uikit";

const STATUS_ICON_SIZE = "48px";

const StatusIcon = styled.div`
    background-color: ${({ theme }) => theme.palette.white.base};
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${STATUS_ICON_SIZE};
    width: ${STATUS_ICON_SIZE};
    border-radius: 50%;
`;

type DropzoneProps = {
    isDragActive?: boolean;
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
};

const Dropzone = styled.div<DropzoneProps>`
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 105px;

    ${({ theme, isDragActive, isLoading, isSuccess, isError }) => {
        if (isDragActive) {
            return css`
                background-color: ${theme.palette.purple.lightest};
                border: 2px dashed ${theme.palette.purple.base};
            `;
        }

        if (isLoading) {
            return css`
                background-color: ${theme.palette.purple.lightest};
                --icon-bg-color: ${theme.palette.white.base};
                --icon-color: ${theme.palette.purple.base};
            `;
        }

        if (isSuccess) {
            return css`
                background-color: ${theme.palette.green.lightest};
                --icon-bg-color: ${theme.palette.white.base};
                --icon-color: ${theme.palette.green.base};
            `;
        }

        if (isError) {
            return css`
                background-color: ${theme.palette.red.lightest};
            `;
        }
    }};
`;

const Instructions = styled(Text)`
    color: ${({ theme }) => theme.palette.purple.base};
`;

const SizeHelper = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing[5]};
    color: ${({ theme }) => theme.palette.grey.base};
`;

const ErrorMessage = styled(Text)`
    color: ${({ theme }) => theme.palette.red.base};
`;

const MAX_FILE_SIZE = 10000000;

const getErrorMessage = (t: TFunction, code?: string) => {
    if (code === "file-too-large") {
        return t("component.AddMediaForm.fileTooLargeErrorMessage", { maxSize: `${MAX_FILE_SIZE / 1000000} Mo` });
    } else {
        return t("component.AddMediaForm.defaultErrorMessage");
    }
};

export type Attachment = {
    id?: string;
    kind: "media" | "link";
    title?: string;
    size?: number;
    url?: string;
    onClose?: () => void;
};

const schema = yup.object().shape({
    title: yup.string(),
});

const defaultValues = {
    title: "",
};

type Props = {
    isMediaSecured?: boolean;
    onSubmit: (media: MediaResourceType) => void;
    disabled?: boolean;
    showTitleField?: boolean;
    className?: string;
    titleSectionId?: string;
};

const AddMediaForm = ({
    isMediaSecured = false,
    onSubmit,
    disabled = false,
    showTitleField = false,
    className,
    titleSectionId,
}: Props) => {
    const [t] = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const sizeHelperId = useId();

    const { control, watch, reset } = useForm({ defaultValues, resolver: yupResolver(schema) });

    const title = watch("title");

    const [uploadAttachmentMutation, { isLoading }] = useMediaUpload({ onSuccess: () => reset() });

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        noClick: true,
        maxSize: MAX_FILE_SIZE,
        onDropAccepted: async (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            const upload = await uploadAttachmentMutation({
                payload: isMediaSecured
                    ? { secured: true, title, file }
                    : { type: MediaType.FILE, secured: false, title, file },
            });

            if (upload) {
                onSubmit(upload.data);
                setIsSuccess(true);

                return new Promise(resolve => setTimeout(resolve, 1500)).then(() => setIsSuccess(false));
            } else {
                setErrorMessage(getErrorMessage(t));
            }
        },
        onDrop: (_, rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                const errorMessage = getErrorMessage(t, rejectedFiles[0].errors[0].code);
                setErrorMessage(errorMessage);
            }
        },
    });

    const children = () => {
        if (isDragActive) {
            return (
                <StatusIcon>
                    <Upload />
                </StatusIcon>
            );
        }

        if (isLoading) {
            return (
                <StatusIcon role="alert" aria-busy="true">
                    <Loading />
                </StatusIcon>
            );
        }

        if (isSuccess) {
            return (
                <StatusIcon role="alert">
                    <Check />
                    <SROnly>{t("component.AddMediaForm.uploadSuccessSrOnly")}</SROnly>
                </StatusIcon>
            );
        }

        if (errorMessage) {
            return (
                <>
                    <ErrorMessage role="alert">{errorMessage}</ErrorMessage>
                    <Button
                        type="button"
                        small
                        variant="primary"
                        onClick={() => setErrorMessage("")}
                        disabled={disabled}
                    >
                        {t("component.AddMediaForm.retry")}
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Instructions>{t("component.AddMediaForm.instructions")}</Instructions>
                    <Button
                        aria-describedby={`${titleSectionId} ${sizeHelperId}`}
                        type="button"
                        small
                        variant="primary"
                        onClick={open}
                        disabled={disabled}
                        autoFocus
                    >
                        {t("component.AddMediaForm.importButton")}
                    </Button>
                    <SizeHelper id={sizeHelperId}>
                        {t("component.AddMediaForm.sizeHelper", { maxSize: `${MAX_FILE_SIZE / 1000000} Mo` })}
                    </SizeHelper>
                </>
            );
        }
    };

    return (
        <div className={className}>
            {showTitleField && (
                <Controller
                    control={control}
                    name="title"
                    render={({ field: { name, value, ...field }, fieldState: { invalid, error } }) => (
                        <InputDecorator
                            htmlFor={name}
                            label={t("component.AddMediaForm.titleLabel")}
                            error={error}
                            helper={t("component.AddMediaForm.helper")}
                        >
                            {({ errorId }) => (
                                <TextInput
                                    {...field}
                                    id={name}
                                    name={name}
                                    value={value}
                                    aria-describedby={errorId}
                                    aria-invalid={invalid}
                                    variant={invalid ? "error" : "default"}
                                    autoFocus
                                />
                            )}
                        </InputDecorator>
                    )}
                />
            )}

            <Dropzone
                {...getRootProps()}
                tabIndex={-1}
                isDragActive={isDragActive}
                isLoading={isLoading}
                isSuccess={isSuccess}
                isError={!!errorMessage}
            >
                {children()}
                <input {...getInputProps()} />
            </Dropzone>
        </div>
    );
};

export default styled(AddMediaForm)`
    ${TextInput} {
        margin-bottom: ${({ theme }) => theme.spacing[5]};
    }
`;
