import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Feature, FeatureGuard } from "@sol/features";
import { Plus } from "@sol/icons";
import { Route } from "@sol/routing";
import { IClassroom } from "@sol/sdk";
import { Button } from "@sol/uikit/buttons";

import ClassroomCardContent from "../shared/ClassroomCardContent";
import Card from "./Card";

type Props = {
    id: Feature;
    classroom?: IClassroom;
    showVisio?: boolean;
};

const ClassroomCard = ({ classroom, showVisio, ...rest }: Props) => {
    const [t] = useTranslation();

    return (
        <Card
            {...rest}
            title={t("component.classroomCard.title")}
            helper={
                <FeatureGuard feature={Feature.CLASSROOM_CREATE}>
                    <Link href={Route.CLASSROOMS_CREATE} passHref>
                        <Button as="a" filled variant="primary" data-cy="add-classroom-link">
                            {t("page.home.createClassroom.link")} <Plus />
                        </Button>
                    </Link>
                </FeatureGuard>
            }
            data-cy="classroom-card"
        >
            <ClassroomCardContent classroom={classroom} showVisio={showVisio} />
        </Card>
    );
};

export default styled(ClassroomCard)``;
