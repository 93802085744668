import { AxiosError, AxiosResponse } from "axios";
import { isEqual } from "lodash";
import isArray from "lodash/isArray";
import uniq from "lodash/uniq";
import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStateToMarkdown } from "src/components/BriefForm/utils";
import BriefSummary from "src/components/BriefSummary";
import { BriefSkill } from "src/components/BriefSummary/BriefSummary";
import { SkillLevelChipStatus } from "src/components/SkillLevelChip";
import { BriefStatus } from "src/enums";
import { v4 as uuid } from "uuid";

import { useAuthenticatedUser } from "@sol/authentication";
import { NotificationLevel, useNotify } from "@sol/notifications";
import { Route } from "@sol/routing";
import { getResourceUuid, useCreateBrief, useFrameworks } from "@sol/sdk";
import { useUpdateBrief } from "@sol/sdk/briefs/useUpdateBrief";

import ApiErrorAlert from "../../ApiErrorAlert";
import { CreateBriefFormValues, CreateBriefSkillsStepPayload } from "./CreateBriefFormsManager";
import CreateBriefSummaryLayout from "./CreateBriefSummaryLayout";

const generateBriefSkills = (skillLevels: CreateBriefSkillsStepPayload["skillLevels"]): BriefSkill[] =>
    skillLevels.map(({ skill, skillTitle, level }) => ({
        skill,
        title: skillTitle,
        levels: [1, 2, 3].map(levelIndex => ({
            id: `${skill}_level-${levelIndex}`,
            level: levelIndex,
            status: level >= levelIndex ? SkillLevelChipStatus.VALIDATED : SkillLevelChipStatus.NOT_EVALUATED,
        })),
    }));

type Props = {
    isEdit: boolean;
    values: CreateBriefFormValues;
    onBackClick: () => void;
    briefStatus: BriefStatus | undefined;
    onSaveDraft: () => void;
    isLoading: boolean;
    isBriefLoading: boolean;
    defaultValues: CreateBriefFormValues | undefined;
};

const CreateBriefSummaryView = ({
    values,
    onBackClick,
    isEdit,
    briefStatus,
    onSaveDraft,
    isLoading,
    isBriefLoading,
    defaultValues,
}: Props) => {
    const { user } = useAuthenticatedUser();
    const { push, query } = useRouter();
    const [t] = useTranslation();
    const notify = useNotify();
    const saveBriefButtonRef = useRef(null);

    const briefId = isArray(query.id) ? query.id[0] : query.id;
    const [mutationError, setMutationError] = useState<AxiosResponse | undefined>(undefined);

    const skillsUuids = uniq(values["professional-situation"]?.skills.map(skill => getResourceUuid(skill)) || []);
    const { data: frameworks } = useFrameworks({ filters: { skills: skillsUuids } });
    const mutationNotification = (level: NotificationLevel, message: string) => {
        notify({
            level,
            message: t(message),
        });
    };
    const defaultProfessionalSituation = defaultValues?.["professional-situation"]?.["@id"];
    const defaultSkills = defaultValues?.skills.skillLevels || [];
    const currentProfessionalSituation = values["professional-situation"]?.["@id"];
    const currentSkills = values.skills.skillLevels;
    const isBriefCorrected = values.description.isCorrected;

    const isCorrectedBriefSaveable =
        defaultProfessionalSituation === currentProfessionalSituation &&
        isEqual(defaultSkills, currentSkills) &&
        isBriefCorrected === true;

    const { createBrief, isLoading: saveBriefLoading } = useCreateBrief({
        onSuccess: ({ data }) => {
            const { "@id": id } = data;
            mutationNotification(NotificationLevel.SUCCESS, "component.CreateBriefLayout.create.validated");
            return push(Route.BRIEFS_DETAILS, id);
        },
        onError: (error: AxiosError) => setMutationError(error.response),
    });

    const { updateBrief, isLoading: updateBriefLoading } = useUpdateBrief({
        onSuccess: ({ data }) => {
            const { "@id": id } = data;
            mutationNotification(NotificationLevel.SUCCESS, "component.CreateBriefLayout.update.success");
            return push(Route.BRIEFS_DETAILS, id);
        },
        onError: (error: AxiosError) => setMutationError(error.response),
    });

    const brief = {
        ...values.description,
        context: getStateToMarkdown(values.description.context),
        pedagogicModalities: getStateToMarkdown(values.description.pedagogicModalities),
        frameworks: frameworks?.["hydra:member"],
        image: values["additional-information"].image,
        status: briefStatus,
        tags: values["additional-information"].tags,
        language: values["professional-situation"]?.language,
        createdAt: new Date().toDateString(),
        createdBy: user,
        skills: generateBriefSkills(values.skills.skillLevels),
        media: values["additional-information"].resources,
        professionalSituation: values["professional-situation"],
    };
    const saveBrief = (status: BriefStatus) => {
        const additionalInformation = values["additional-information"];
        const skills = values.skills;

        // TODO: clean payload
        const payload = {
            ...brief,
            professionalSituation: brief.professionalSituation?.["@id"],
            tags: additionalInformation.tags.map(tag => tag["@id"]),
            media: additionalInformation.resources.map(resource => resource["@id"]),
            image: additionalInformation.image?.["@id"],
            status,
            skillLevels: skills.skillLevels.map(skillLevel => skillLevel["@id"]),
            uuid: uuid(),
        };
        if (status === BriefStatus.VALIDATED && isEdit) {
            updateBrief({ payload, briefId });
        } else {
            createBrief(payload);
        }
    };

    return (
        /*
         *  TODO
         *   Dirty need to be refactoring
         *   Not the actual status but necessary in order to get the "non-assigned" status
         */
        <CreateBriefSummaryLayout
            onBackClick={onBackClick}
            onBriefSave={() => saveBrief(BriefStatus.VALIDATED)}
            onDraftSave={onSaveDraft}
            isDraftButtonLoading={isLoading}
            isSaveButtonLoading={saveBriefLoading || updateBriefLoading}
            briefStatus={brief.status}
            isEdit={isEdit}
            isCorrectedBriefSaveable={isCorrectedBriefSaveable}
            saveBriefButtonRef={saveBriefButtonRef}
        >
            <BriefSummary {...{ ...brief, status: BriefStatus.VALIDATED }} isLoading={isBriefLoading} />
            <ApiErrorAlert
                error={mutationError}
                dissmiss={() => setMutationError(undefined)}
                triggerElement={saveBriefButtonRef}
            />
        </CreateBriefSummaryLayout>
    );
};

export default CreateBriefSummaryView;
