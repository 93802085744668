import { isBefore, isAfter } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import React, { ComponentPropsWithoutRef, useState, useMemo, MouseEvent } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import {
    ProfessionalSituationPickerContext,
    PROFESSIONAL_SITUATION_CREATE,
} from "src/components/shared/ProfessionalSituations/ProfessionalSituationsPicker";
import styled from "styled-components";

import { useCheckFeatureFlag, Feature } from "@sol/features";
import { Plus, Cross } from "@sol/icons";
import { Route, useRouter } from "@sol/routing";
import { IClassroom, parseDate, useDeleteEducationalScenarioItemMutation } from "@sol/sdk";
import { useEducationalScenario } from "@sol/sdk/educationalScenarios";
import { Button, IconButton } from "@sol/uikit";

import ApiErrorAlert from "../../ApiErrorAlert";
import ConfirmationModal from "../../shared/ConfirmationModal";
import Card from "../Card";
import PedagogicalScenarioTabs from "./PedagogicalScenarioTabs";
import PedagogicalScenarioTimelineItem from "./PedagogicalScenarioTimelineItem";
import Timeline, { Scale } from "./Timeline";

const TimelineItemToolbar = styled(motion.div)`
    display: flex;
    justify-content: end;
    flex-direction: row;

    transform: translateY(calc(-200% * var(--timeline-item-toobar-visible) + var(--timeline-item-toobar-tab)));

    ${IconButton} {
        position: sticky;
        right: 0px;
    }
`;

const TimelineItemToolbarContent = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[2]};

    padding: var(--timeline-item-toobar-padding) var(--timeline-item-toobar-padding)
        calc(
            var(--timeline-item-toobar-padding) + var(--timeline-item-toobar-visible) * var(--timeline-item-toobar-tab)
        );

    background: transparent;
    border-radius: var(--timeline-item-toobar-radius) var(--timeline-item-toobar-radius) 0 0;
`;

const TimelineItem = styled.div`
    position: relative;
    width: 100%;

    --timeline-item-toobar-visible: 1;
    --timeline-item-toobar-padding: 0px; // ${({ theme }) => theme.spacing[2]};
    --timeline-item-toobar-tab: 15px;
    --timeline-item-toobar-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    ${TimelineItemToolbar} {
        position: absolute;
        top: 0;
        width: 100%;
    }

    ${TimelineItemToolbarContent} {
        position: sticky;
        right: 0;
        margin-left: auto;
    }
`;

type Props = { loading?: boolean; classroom?: IClassroom } & Omit<
    ComponentPropsWithoutRef<typeof Card>,
    "title" | "helper"
>;

const PedagogicalScenarioCard = ({ loading, classroom, ...rest }: Props) => {
    const [t] = useTranslation();
    const checkFeatureFlag = useCheckFeatureFlag();
    const router = useRouter();
    const { PEDAGOGICAL_SCENARIO } = ProfessionalSituationPickerContext;
    const titleId = useId();
    const timelineItemsid = useId();

    const addProfessionalSituationButton = () => (
        <Button
            onClick={() =>
                router.push({
                    route: Route.PROFESSIONAL_SITUATION,
                    state: {
                        [PROFESSIONAL_SITUATION_CREATE]: {
                            context: PEDAGOGICAL_SCENARIO,
                        },
                    },
                })
            }
            as="a"
            filled
            variant="primary"
            data-cy="pedagogical-scenario-card-add-professional-situation"
            tabIndex={0}
        >
            {t("component.home.PedagogicalScenarioCard.addProfessionalSituation")}
            <Plus />
        </Button>
    );

    const { [Feature.HOMEPAGE_SCENARIO_CARD]: initialState } = useRouter().state ?? {};

    const [educationalScenarioItemId, setEducationalScenarioItemId] = useState<string | null>(
        initialState?.educationalScenarioItemId ?? null,
    );
    const [itemToDelete, setItemToDelete] = useState<null | string>(null);

    const [date, setDate] = useState(initialState?.timestamp ?? new Date());
    const [scale, setScale] = useState<Scale>(initialState?.scale ?? "month");
    const { startDate, endDate } = classroom ?? {};

    const classroomDates = useMemo(
        () =>
            startDate && endDate
                ? {
                      start: parseDate(startDate),
                      end: parseDate(endDate),
                  }
                : undefined,
        [startDate, endDate],
    );

    const educationalScenarioQuery = useEducationalScenario({
        classroom,
        filters: {
            scale,
        },
    });

    const [deleteEducationalScenarioItem, deleteEducationalScenarioItemMutation] =
        useDeleteEducationalScenarioItemMutation({
            onSuccess: () => {
                educationalScenarioQuery.refetch();
                // Close deletion modal
                setItemToDelete(null);
                // Unselect the educational scenario
                setEducationalScenarioItemId(null);
            },
        });

    const educationalScenarioItems = educationalScenarioQuery.data?.["hydra:member"];

    const timelineItems = useMemo(
        () =>
            (educationalScenarioItems ?? []).map(slot => {
                const { startDate, endDate, title, uuid, type, numberOfItems, zoomNeeded } = slot;
                const aggregated = type === "aggregated";
                const shouldZoom = zoomNeeded || aggregated;
                const start = parseDate(startDate);
                const end = parseDate(endDate);
                const selected = educationalScenarioItemId === uuid && !aggregated;

                return {
                    id: uuid,
                    start,
                    end,
                    render: () => {
                        return (
                            <TimelineItem data-cy="timeline-item">
                                <AnimatePresence>
                                    {selected && checkFeatureFlag(Feature.PEDAGOGICAL_SCENARIO_MANAGE) && (
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        <TimelineItemToolbar
                                            initial={{ "--timeline-item-toobar-visible": 0 }}
                                            animate={{ "--timeline-item-toobar-visible": 1 }}
                                            exit={{ "--timeline-item-toobar-visible": 0 }}
                                        >
                                            <IconButton
                                                data-cy="delete-educational-scenario-item"
                                                type="button"
                                                size="tiny"
                                                onClick={() => setItemToDelete(uuid)}
                                                title={t(
                                                    "component.home.PedagogicalScenarioCard.deleteEducationalScenarioItem",
                                                )}
                                                aria-describedby={`${timelineItemsid}-${uuid}`}
                                            >
                                                <Cross />
                                            </IconButton>
                                        </TimelineItemToolbar>
                                    )}
                                </AnimatePresence>
                                <PedagogicalScenarioTimelineItem
                                    id={`${timelineItemsid}-${uuid}`}
                                    start={start}
                                    end={end}
                                    selected={selected}
                                    aggregated={aggregated}
                                    zoomNeeded={shouldZoom}
                                    onMouseDown={(evt: MouseEvent) => {
                                        evt.stopPropagation();
                                    }}
                                    onMouseUp={(evt: MouseEvent) => {
                                        evt.stopPropagation();
                                    }}
                                    onClick={(evt: MouseEvent) => {
                                        evt.stopPropagation();
                                        if (aggregated) {
                                            setScale(scale => {
                                                if (scale === "month") {
                                                    return "week";
                                                }

                                                return "day";
                                            });
                                            setDate(start);
                                        } else {
                                            setEducationalScenarioItemId(uuid);
                                        }
                                    }}
                                >
                                    {aggregated
                                        ? t("component.home.PedagogicalScenarioCard.Timeline.aggregatedLabel", {
                                              count: numberOfItems,
                                          })
                                        : title}
                                </PedagogicalScenarioTimelineItem>
                            </TimelineItem>
                        );
                    },
                };
            }),
        [educationalScenarioItems, educationalScenarioItemId, t],
    );

    return (
        <Card
            title={t(`component.home.PedagogicalScenarioCard.title`)}
            data-cy={`pedagogical-scenario-card`}
            helper={checkFeatureFlag(Feature.PROFESSIONAL_SITUATION_CREATE) && addProfessionalSituationButton()}
            {...rest}
        >
            <p className="sr-only" id={titleId}>
                {t(`component.home.PedagogicalScenarioCard.title`)}
            </p>
            {classroomDates ? (
                <Timeline
                    loading={educationalScenarioQuery.isFetching}
                    date={
                        isBefore(date, classroomDates.start) || isAfter(date, classroomDates.end)
                            ? classroomDates.start
                            : date
                    }
                    {...classroomDates}
                    scale={scale}
                    onScaleChange={setScale}
                    items={timelineItems}
                    cardTitleId={titleId}
                />
            ) : null}
            {/* Tabs */}
            {educationalScenarioItemId ? (
                <PedagogicalScenarioTabs
                    key={educationalScenarioItemId}
                    educationalScenarioItemId={educationalScenarioItemId}
                    educationalScenarioItems={educationalScenarioItems}
                    setTimelineStartDate={setDate}
                    setTimelineScale={setScale}
                    refreshTimeline={() => educationalScenarioQuery.refetch()}
                    classroomStartDate={startDate}
                    classroomEndDate={endDate}
                />
            ) : null}

            <ConfirmationModal
                isLoading={deleteEducationalScenarioItemMutation.isLoading}
                onCancel={() => setItemToDelete(null)}
                onConfirm={() => itemToDelete && deleteEducationalScenarioItem(itemToDelete)}
                isOpen={!!itemToDelete}
                text={t("component.home.PedagogicalScenarioCard.confirmEducationalScenarioItemDeletion")}
                confirmLabel={t("component.home.PedagogicalScenarioCard.acceptDelete")}
                cancelLabel={t("component.home.PedagogicalScenarioCard.cancelDelete")}
            />
            <ApiErrorAlert
                error={deleteEducationalScenarioItemMutation.error}
                dissmiss={deleteEducationalScenarioItemMutation.reset}
            />
        </Card>
    );
};

export default styled(PedagogicalScenarioCard)`
    ${Timeline} {
        --viewport-height: 98px;
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;
