import { transparentize } from "polished";
import React, { HTMLAttributes, ReactNode, useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import FocusTrap from "react-focus-trap";
import ModalContext from "src/contexts/ModalContext";
import styled, { css } from "styled-components";

import { useClickAway } from "@sol/uikit";

export const BlurredModalContainer = styled.div`
    ${({ theme }) => css`
        padding: ${theme.spacing[7]};
        border-radius: ${theme.shape.borderRadius.medium};
        background-color: ${theme.palette.white.base};

        transition: all ease 0.2s;
    `}
`;

const Backdrop = styled.div<{ visible: boolean }>`
    top: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => transparentize(0.8, theme.palette.black.base)};
    backdrop-filter: blur(45px);

    z-index: ${({ theme }) => theme.zIndex.overlay};
    visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
    opacity: ${({ visible }) => (visible ? 1 : 0)};

    transition: opacity ease 0.2s;

    ${BlurredModalContainer} {
        opacity: ${({ visible }) => (visible ? 1 : 0)};
        transform: ${({ visible }) => `scale(${visible ? 1 : 0})`};
    }
`;

const ContentFocusTrap = styled(FocusTrap)``;

export type SettingModalProps = {
    children: ReactNode;
    visible: boolean;
    fitContent?: boolean;
    noGutters?: boolean;
    onClose?: () => void;
    className?: string;
} & HTMLAttributes<HTMLDivElement>;

function BlurredModal({ children, visible, className, onClose, ...props }: SettingModalProps) {
    const { modalNode, register } = useContext(ModalContext);
    const modalRef = useRef<HTMLDivElement>(null);
    useClickAway(modalRef, () => onClose?.());

    useEffect(() => {
        if (visible) {
            return register();
        }
    }, [visible]);

    return modalNode && visible
        ? ReactDOM.createPortal(
              <Backdrop className={className} visible={visible} data-cy="home-setting-modal">
                  <BlurredModalContainer role="dialog" aria-modal="true" ref={modalRef} {...props}>
                      <ContentFocusTrap active={visible}>{children}</ContentFocusTrap>
                  </BlurredModalContainer>
              </Backdrop>,
              modalNode,
          )
        : null;
}

export default BlurredModal;
