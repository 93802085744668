import { memo, ComponentPropsWithoutRef, forwardRef, Ref } from "react";
import styled from "styled-components";
import { Merge } from "type-fest";

import Text from "../../../../uikit/Text";

type Props = Merge<
    {
        label: string;
    },
    ComponentPropsWithoutRef<"div">
>;

const ScaleCell = ({ label, ...props }: Props, ref: Ref<HTMLDivElement>) => {
    return (
        <div ref={ref} {...props}>
            <Text variant="subheading" aria-hidden="true">
                {label}
            </Text>
        </div>
    );
};

export default styled(memo(forwardRef(ScaleCell)))`
    border-left: 2px solid ${({ theme }) => theme.palette.grey.lightest};

    > ${Text} {
        color: ${({ theme }) => theme.palette.grey.light};
        text-transform: capitalize;
        white-space: nowrap;

        border-bottom: 1px dashed ${({ theme }) => theme.palette.grey.lighter};
        padding: 0 ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[2]};
    }
`;
