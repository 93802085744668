import { MutableRefObject } from "react";
import { useWatch, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BriefSteps } from "src/enums";
import styled from "styled-components";

import { Loading, Save } from "@sol/icons";
import { IProfessionalSituationResource } from "@sol/sdk";
import { Button } from "@sol/uikit";

import {
    CreateBriefAdditionalInformationStepPayload,
    CreateBriefDescriptionStepPayload,
    CreateBriefSkillsStepPayload,
} from "./CreateBriefFormsManager";

const DraftButton = styled(Button)`
    margin: ${({ theme }) => theme.spacing[7]} auto 0 auto;
`;
type Props = {
    isLoading: boolean;
    currentStep: string;
    isEdit: boolean;
    onClick: () => void;
    forwardRef?: MutableRefObject<null>;
    forms: {
        [key: string]: UseFormReturn<
            | CreateBriefSkillsStepPayload
            | CreateBriefDescriptionStepPayload
            | CreateBriefAdditionalInformationStepPayload
            | IProfessionalSituationResource
        >;
    };
};

const SaveDraftButton = ({ isLoading, currentStep, isEdit, onClick, forms, forwardRef }: Props) => {
    const [t] = useTranslation();
    const { description, skills } = forms;
    const { DESCRIPTION, ADDITIONAL_INFORMATION } = BriefSteps;
    // Watch if title or skills change
    const watchSkills = useWatch({ name: "skillLevels", control: skills.control });
    const hasTitle = !!useWatch({ name: "title", control: description.control });
    const hasSkillLevels = watchSkills instanceof Array && watchSkills.length > 0;
    const hasAnyOtherChange = Object.keys(forms).filter(key => forms[key].formState.isDirty).length > 0;
    const isButtonDisabled = hasAnyOtherChange && hasTitle && hasSkillLevels;

    return isEdit || currentStep === DESCRIPTION || currentStep === ADDITIONAL_INFORMATION ? (
        <DraftButton
            ref={forwardRef}
            outlined
            variant="success"
            type="button"
            data-cy="create-brief-aside-draft-button"
            aria-label={t("component.CreateBriefLayout.draft.a11y")}
            disabled={!isButtonDisabled}
            onClick={onClick}
            loading={isLoading}
        >
            {t("component.CreateBriefLayout.draft.label")}
            {isLoading ? <Loading /> : <Save />}
        </DraftButton>
    ) : null;
};

export default SaveDraftButton;
