import { motion } from "framer-motion";
import Link from "next/link";
import { MutableRefObject } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { SROnly, Text } from "src/uikit";
import styled from "styled-components";

import { useRestrictedScope } from "@sol/access-control";
import { useAuthenticatedUser } from "@sol/authentication";
import { Feature, useCheckFeatureFlag } from "@sol/features";
import { Move, Pencil, TrashCan } from "@sol/icons";
import { Route, interpolate } from "@sol/routing";
import { EducationalBriefs } from "@sol/sdk/educationalScenarioItems/IEducationalScenarioItem";

import { getUuid } from "../../../../utils/urlFormatter";

type DraggableItemContentProps = {
    isDragging: boolean;
    ref?: MutableRefObject<null>;
};

const DraggableItemContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const BriefDetailsWrapper = styled.div<DraggableItemContentProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 24px;
    width: 100%;
    border: 1px solid ${({ theme, isDragging }) => theme.palette.purple[isDragging ? "light" : "lighter"]};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    background-color: ${({ theme, isDragging }) =>
        isDragging ? theme.palette.purple.lighter : theme.palette.white.base};
`;

const BriefTitle = styled(Text)<DraggableItemContentProps>`
    margin-right: ${({ theme }) => theme.spacing[7]};
    color: ${({ theme }) => theme.palette.purple.base};
    text-decoration: ${({ isDragging }) => (isDragging ? "underline" : "none")};
    &:hover {
        text-decoration: underline;
    }
`;

const SkillsCodeWrapper = styled(Text)`
    display: inline-flex;
    gap: ${({ theme }) => theme.spacing[2]};
    color: ${({ theme }) => theme.palette.grey.base};
    font-weight: 600;
`;

const SkillCode = styled.span`
    &:not(:last-child)::after {
        content: ",";
    }
`;
const BriefReferentialsList = styled.ul`
    display: flex;
    justify-content: space-between;
    gap: 4px;
    max-height: 52px;
    padding-left: 0;
    margin: 0;
`;
const BriefReferential = styled.li`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    background: ${({ theme }) => theme.palette.white.base};
    border: 1px solid ${({ theme }) => theme.palette.yellow.base};
    border-radius: 56px;
`;
const Referential = styled(Text)`
    font-size: 12px;
    font-weight: 600;
`;
const EditLinkWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[6]};
`;
const EditLink = styled.a`
    --icon-color: ${({ theme }) => theme.palette.grey.base};
    display: inline-flex;
    align-items: center;
    height: 32px;
    width: 32px;
    padding: 4px;
    background: ${({ theme }) => theme.palette.white.base};
    border-radius: 9999px;
    border-color: transparent;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    &:hover,
    &:focus {
        background: ${({ theme }) => theme.palette.purple.base};
        --icon-color: ${({ theme }) => theme.palette.white.base};
    }
`;

const CustomMoveIcon = styled(Move)<DraggableItemContentProps>`
    --icon-color: ${({ theme, isDragging }) => (isDragging ? theme.palette.purple.base : theme.palette.grey.lighter)};
    height: 24px;
    width: 24px;
    &:hover {
        --icon-color: ${({ theme }) => theme.palette.purple.base};
    }
`;

type ProfessionalSituationBriefListItem = {
    educationalBrief: EducationalBriefs;
    trainers?: string[];
    index: number;
    className?: string;
    handleDelete: (briefId: string) => void;
    dndIconRef?: MutableRefObject<null>;
    deleteButtonRef?: MutableRefObject<null>;
};

const ProfessionalSituationBriefListItem = ({
    educationalBrief,
    trainers,
    index,
    className,
    handleDelete,
    dndIconRef,
    deleteButtonRef,
}: ProfessionalSituationBriefListItem) => {
    const [t] = useTranslation();
    const { user } = useAuthenticatedUser();
    const checkFeatureFlag = useCheckFeatureFlag();
    const scope = useRestrictedScope();

    const {
        uuid,
        brief: { title, computedSkills, "@id": id, frameworks, createdBy },
    } = educationalBrief;

    const isAuthor = createdBy === user["@id"];
    const isClassroomTrainer = trainers ? trainers.findIndex(trainer => trainer === user["@id"]) > -1 : false;

    // TODO: Need to be replace
    const { canEdit, canDelete, canReorder } = {
        canEdit: checkFeatureFlag(Feature.BRIEFS_EDIT, { scope: scope(educationalBrief.brief) }),
        // FIXME: wrong ACL here. not working as expected (use Feature.PEDAGOGICAL_SCENARIO_MANAGE ?)
        canDelete: isAuthor && checkFeatureFlag(Feature.EDUCATIONAL_BRIEF_DELETE),
        canReorder: isClassroomTrainer,
    };

    const briefId = getUuid(id) as string;

    return (
        <motion.div exit={{ opacity: 0, transition: { ease: "easeOut", duration: 0.5, delay: 0.1 } }} id={uuid}>
            <Draggable key={uuid} draggableId={uuid} index={index} isDragDisabled={!canReorder}>
                {(provided, snapshot) => (
                    <DraggableItemContent className={className} ref={provided.innerRef} {...provided.draggableProps}>
                        {canReorder && (
                            <div
                                {...provided.dragHandleProps}
                                ref={dndIconRef}
                                data-cy="pedagogical-scenario-brief-drag-button"
                            >
                                <CustomMoveIcon isDragging={snapshot.isDragging} />
                            </div>
                        )}
                        <BriefDetailsWrapper
                            isDragging={snapshot.isDragging}
                            data-cy="pedagogical-scenario-brief-list-item"
                        >
                            <Link href={interpolate(Route.BRIEFS_DETAILS, { id: briefId })} passHref>
                                <BriefTitle as="a" variant="inline-link" lines={2} isDragging={snapshot.isDragging}>
                                    {title}
                                </BriefTitle>
                            </Link>
                            <SkillsCodeWrapper>
                                {computedSkills.map((skill, index) => {
                                    const { code, "@id": id } = skill;
                                    if (index <= 3 && code) {
                                        return <SkillCode key={id}>{code}</SkillCode>;
                                    }
                                })}
                                {computedSkills.length > 4 && <Text>{`+ ${computedSkills.length - 4} autres`}</Text>}
                            </SkillsCodeWrapper>
                            <BriefReferentialsList>
                                {frameworks.map((framework, index) => {
                                    if (index === 0) {
                                        return (
                                            <BriefReferential key={framework["@id"]}>
                                                <Referential variant="p">{framework.title}</Referential>
                                            </BriefReferential>
                                        );
                                    }
                                })}
                                {frameworks.length > 1 && (
                                    <BriefReferential>
                                        <Referential variant="p">{`+ ${frameworks.length - 1}`}</Referential>
                                    </BriefReferential>
                                )}
                            </BriefReferentialsList>
                            {(canEdit || canDelete) && (
                                <EditLinkWrapper>
                                    {canEdit && (
                                        <Link href={interpolate(Route.BRIEFS_EDIT, { id: briefId })} passHref>
                                            <EditLink data-cy="pedagogical-scenario-brief-item-edit-button">
                                                <Pencil />
                                                <SROnly>
                                                    {t("component.home.PedagogicalScenarioCard.editBrief", {
                                                        briefTitle: title,
                                                    })}
                                                </SROnly>
                                            </EditLink>
                                        </Link>
                                    )}
                                    {canDelete && (
                                        <EditLink
                                            ref={deleteButtonRef}
                                            as="button"
                                            type="button"
                                            onClick={() => handleDelete(uuid)}
                                            data-cy="pedagogical-scenario-brief-item-delete-button"
                                        >
                                            <TrashCan />
                                            <SROnly>
                                                {t("component.home.PedagogicalScenarioCard.deleteBrief", {
                                                    briefTitle: title,
                                                })}
                                            </SROnly>
                                        </EditLink>
                                    )}
                                </EditLinkWrapper>
                            )}
                        </BriefDetailsWrapper>
                    </DraggableItemContent>
                )}
            </Draggable>
        </motion.div>
    );
};

export default styled(ProfessionalSituationBriefListItem)`
    display: flex;
    align-items: center;
`;
