import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import ApiErrorAlert from "src/components/ApiErrorAlert";
import EducationalBriefStatus from "src/enums/EducationalBriefStatus";
import Modal from "src/uikit/Modal";
import Text from "src/uikit/Text";
import styled from "styled-components";

import { Feature, useCheckFeatureFlag } from "@sol/features";
import { Cross } from "@sol/icons";
import { useUpdateEducationalBriefMutation } from "@sol/sdk/educationalBriefs/useUpdateEducationelBriefMutation";
import { EducationalBriefs, EducationalClassroom } from "@sol/sdk/educationalScenarioItems/IEducationalScenarioItem";
import { usePatchEducationalScenarioItemMutation } from "@sol/sdk/educationalScenarioItems/usePatchEducationalScenarioItemsMutation";
import { Button } from "@sol/uikit";

import ProfessionalSituationBriefListItem from "./ProfessionalSituationBriefListItem";

type DraggableItemProps = {
    isDragging: boolean;
};

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 589px;
    margin: 0 auto;
    padding-bottom: 70px;
    text-align: center;
`;
const BriefNumberList = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 0;
    margin: 0;
`;
const BriefNumberItem = styled(motion.li)`
    display: flex;
    align-items: center;
    position: relative;
    &::before,
    &::after {
        content: "";
        position: absolute;
        display: none;
        height: 4px;
        width: 4px;
        left: 50%;
        transform: translateX(-50%);
        background: ${({ theme }) => theme.palette.grey.lighter};
        border-radius: 9999px;
    }
    &:not(:first-of-type)::before {
        display: block;
        bottom: calc(100% - 7px);
    }
    &:not(:last-of-type)::after {
        display: block;
        top: calc(100% - 7px);
    }
`;
const BriefNumber = styled(Text)<DraggableItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    color: ${({ theme, isDragging }) => (isDragging ? theme.palette.purple.base : theme.palette.grey.base)};
    background: ${({ theme, isDragging }) => (isDragging ? theme.palette.white.base : theme.palette.grey.lightest)};
    border: 1px solid
        ${({ theme, isDragging }) => (isDragging ? theme.palette.purple.base : theme.palette.grey.lightest)};
    border-radius: 9999px;
`;
const BriefList = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
`;
const BriefsContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[5]};
    padding-left: 0;
`;
const TextWrapper = styled(Text)`
    margin-bottom: 55px;
`;
const DeleteModal = styled(Modal)`
    align-items: flex-start;
    padding-top: ${({ theme }) => theme.spacing[5]};
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(22.5px);
    text-align: center;
    > div {
        min-width: 768px;
    }
`;
const ModalTitle = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing[10]};
`;
const ModalActionBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[7]};
    margin-top: ${({ theme }) => theme.spacing[10]};
`;
const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    &:hover {
        --icon-color: ${({ theme }) => theme.palette.purple.base};
    }
`;

enum ErrorsTrigger {
    DND = "DND",
    DEL = "DELETE",
}

type ProfessionalSituationBriefs = {
    educationalBriefs?: EducationalBriefs[];
    classroom?: EducationalClassroom;
    educationalScenarioItemId: string;
    className?: string;
    handleBriefDelete: () => void;
    handleAddBrief: () => void;
    canCreateBrief: boolean;
    isClassroomTrainer: boolean;
    refetchPedagogicalScenarioItem: () => void;
};

const reorder = (list: EducationalBriefs[] | undefined, startIndex: number, endIndex: number) => {
    if (list) {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }
    return [];
};

const ProfessionalSituationBriefs = ({
    educationalBriefs,
    educationalScenarioItemId,
    className,
    handleBriefDelete,
    handleAddBrief,
    canCreateBrief,
    classroom,
    isClassroomTrainer,
    refetchPedagogicalScenarioItem,
}: ProfessionalSituationBriefs) => {
    const [t] = useTranslation();
    const checkFeatureFlag = useCheckFeatureFlag();
    const { trainers } = classroom ?? {};
    const { canCreate } = {
        canCreate: isClassroomTrainer && checkFeatureFlag(Feature.BRIEFS_CREATE),
    };
    const dndIconRef = useRef(null);
    const deleteButtonRef = useRef(null);

    const [educationalBriefsList, setEducationalBriefsList] = useState(educationalBriefs);
    const [currentDndBrief, setCurrentDndBrief] = useState<string>("");
    const [showModal, setShowModal] = useState(false);
    const [educationalBriefId, setEducationalBriefId] = useState<string | undefined>(undefined);
    const [mutationError, setMutationError] = useState(undefined);
    const [errorsTrigger, setErrorsTrigger] = useState<ErrorsTrigger | null>(null);

    useEffect(() => {
        setEducationalBriefsList(educationalBriefs);
    }, [educationalBriefs]);

    const { updateEducationalBrief } = useUpdateEducationalBriefMutation({
        onError: error => {
            setErrorsTrigger(ErrorsTrigger.DND);
            setShowModal(false);
            setMutationError(error.response);
            setEducationalBriefsList(educationalBriefs);
        },
    });

    const { updateEducationalScenarioItem } = usePatchEducationalScenarioItemMutation({
        onSuccess: () => refetchPedagogicalScenarioItem(),
        onError: error => {
            setErrorsTrigger(ErrorsTrigger.DEL);
            setMutationError(error.response);
            setEducationalBriefsList(educationalBriefs);
        },
    });

    const handleDragStart = (result: DropResult) => setCurrentDndBrief(result.draggableId);

    const handleDragEnd = (result: DropResult) => {
        setCurrentDndBrief("");

        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }

        const reorderedEducationalBriefs = reorder(
            educationalBriefsList,
            result.source.index,
            result.destination.index,
        );
        const educationalBriefsToSort = reorderedEducationalBriefs.map((brief: EducationalBriefs) => brief["@id"]);

        updateEducationalScenarioItem({
            payload: {
                educationalBriefsToSort,
            },
            educationalScenarioItemId,
        });

        setEducationalBriefsList(reorderedEducationalBriefs);
    };

    const handleDeleteButtonClick = (educationalBriefId: string) => {
        setEducationalBriefId(educationalBriefId);
        setShowModal(true);
    };
    const handlePopupClose = () => setShowModal(false);

    const handleValidateDelete = () => {
        if (educationalBriefId && educationalBriefsList) {
            const educationalBrief = educationalBriefsList.find(({ uuid }) => uuid === educationalBriefId);

            if (educationalBrief) {
                const updatedBriefsList = educationalBriefsList.filter(({ uuid }) => uuid !== educationalBriefId);
                const payload = {
                    ...educationalBrief,
                    status: EducationalBriefStatus.HIDDEN,
                };

                // Hide EducationalBrief
                updateEducationalBrief({ payload, educationalBriefId });
                setShowModal(false);
                setEducationalBriefsList(updatedBriefsList);
                handleBriefDelete();
            }
        }
    };

    if (educationalBriefsList?.length) {
        return (
            <div className={className}>
                <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                    <Droppable droppableId="briefList">
                        {provided => (
                            <BriefsContainer ref={provided.innerRef} {...provided.droppableProps}>
                                <BriefNumberList>
                                    <AnimatePresence initial={false} presenceAffectsLayout>
                                        {educationalBriefsList.map((educationalBrief, index) => (
                                            <BriefNumberItem
                                                key={educationalBrief.uuid}
                                                exit={{
                                                    opacity: 0,
                                                    transition: { ease: "easeOut", duration: 0.5, delay: 0.1 },
                                                }}
                                            >
                                                <BriefNumber
                                                    variant="p"
                                                    isDragging={currentDndBrief === educationalBrief.uuid}
                                                >
                                                    {index + 1}
                                                </BriefNumber>
                                            </BriefNumberItem>
                                        ))}
                                    </AnimatePresence>
                                </BriefNumberList>
                                <BriefList data-cy="pedagogical-scenario-brief-list">
                                    <AnimatePresence initial={false} presenceAffectsLayout>
                                        {educationalBriefsList.map((educationalBrief, index) => (
                                            <ProfessionalSituationBriefListItem
                                                key={educationalBrief.brief["@id"]}
                                                educationalBrief={educationalBrief}
                                                trainers={trainers}
                                                index={index}
                                                handleDelete={handleDeleteButtonClick}
                                                dndIconRef={dndIconRef}
                                                deleteButtonRef={deleteButtonRef}
                                            />
                                        ))}
                                    </AnimatePresence>
                                    {provided.placeholder}
                                </BriefList>
                            </BriefsContainer>
                        )}
                    </Droppable>
                </DragDropContext>
                <DeleteModal visible={showModal} data-cy="pedagogical-scenario-brief-delete-modal">
                    <CloseButton type="button" onClick={handlePopupClose}>
                        <Cross />
                    </CloseButton>
                    <ModalTitle as="h1" variant="h4">
                        {t("component.home.PedagogicalScenarioCard.confirmDeleteBrief")}
                    </ModalTitle>
                    <ModalActionBox>
                        <Button
                            variant="primary"
                            onClick={handlePopupClose}
                            data-cy="pedagogical-scenario-brief-delete-modal-cancel-button"
                        >
                            {t("component.home.PedagogicalScenarioCard.cancelDelete")}
                        </Button>
                        <Button
                            variant="primary"
                            filled={true}
                            onClick={handleValidateDelete}
                            data-cy="pedagogical-scenario-brief-delete-modal-confirmation-button"
                        >
                            {t("component.home.PedagogicalScenarioCard.acceptDelete")}
                        </Button>
                    </ModalActionBox>
                </DeleteModal>
                <ApiErrorAlert
                    error={mutationError}
                    dissmiss={() => setMutationError(undefined)}
                    triggerElement={errorsTrigger === ErrorsTrigger.DND ? dndIconRef : deleteButtonRef}
                />
            </div>
        );
    }

    return (
        <Container>
            <TextWrapper as="h2" variant="p">
                {/* TODO: refactor canCreate and canCreateBrief condition and try to handle add brief inside this component */}
                {t(`component.home.PedagogicalScenarioCard.${canCreate ? "noBrief" : "noBriefReadOnly"}`)}
            </TextWrapper>
            {canCreateBrief && (
                <Button
                    outlined={true}
                    variant="primary"
                    data-cy="pedagogical-scenario-brief-create-button"
                    onClick={handleAddBrief}
                >
                    {t("component.home.PedagogicalScenarioCard.createBrief")}
                </Button>
            )}
        </Container>
    );
};

export default styled(ProfessionalSituationBriefs)`
    padding-bottom: ${({ theme }) => theme.spacing[5]};
`;
