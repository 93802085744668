import React, { HTMLAttributes, DetailedHTMLProps } from "react";

type Props = {
    className?: string;
} & DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>;

const WorkSheetSvg = ({ className }: Props) => (
    <svg width="130" height="180" viewBox="0 0 130 180" fill="none" className={className}>
        <path
            d="M0 5.06666C0 2.26842 2.26842 0 5.06667 0H92.3684L107.763 18.609L130 43.985V174.933C130 177.732 127.732 180 124.933 180H5.06666C2.26842 180 0 177.732 0 174.933V5.06666Z"
            fill="#F0F0F0"
        />
        <path
            d="M92.3687 0L111.184 21.9925L130 43.985H94.2687C93.2193 43.985 92.3687 43.1343 92.3687 42.085V0Z"
            fill="#C4C4C4"
        />
    </svg>
);

export default WorkSheetSvg;
