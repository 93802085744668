import { ComponentPropsWithoutRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabPanel, Tabs } from "src/uikit/Tabs";
import styled from "styled-components";

import { Cross, Plus } from "@sol/icons";
import { ISkillItem, ISkillLevelItem } from "@sol/sdk";
import { IconButton } from "@sol/uikit";
import { Grid, GridColumn } from "@sol/uikit/layouts";

import { Text } from "../../uikit";
import { TabVariant } from "../../uikit/Tabs/Tab";

type CriteriaProps = {
    title: string;
    children?: string;
} & Omit<ComponentPropsWithoutRef<"div">, "children">;

const LevelControl = styled(IconButton)<{ isSelected: boolean }>`
    padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[6]};
    margin-top: ${({ theme }) => theme.spacing[5]};
    margin-left: auto;
    --text-color: ${({ theme }) => theme.palette.black.base};
    --icon-color: ${({ theme }) => theme.palette.white.base};
    --icon-bg-color: ${({ isSelected, theme }) => theme.palette[isSelected ? "red" : "purple"].base};

    &:hover {
        --text-color: ${({ isSelected, theme }) => theme.palette[isSelected ? "red" : "purple"].base};
        --icon-bg-color: ${({ isSelected, theme }) => theme.palette[isSelected ? "red" : "purple"].base} !important;
    }
`;

const Criteria = styled(({ title, children, ...rest }: CriteriaProps) => {
    return (
        <div {...rest}>
            <Text as="h4" variant="h4" lines={1}>
                {title}
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: children ?? "" }} />
        </div>
    );
})`
    background: ${({ theme }) => theme.palette.purple.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    padding: ${({ theme }) => theme.spacing[5]};

    > h4 {
        --text-color: ${({ theme }) => theme.palette.purple.base};
        margin-bottom: ${({ theme }) => theme.spacing[5]};
    }
`;

const OfficialTitle = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing[5]};
`;

type Props = {
    skill: ISkillItem;
    value?: ISkillLevelItem[];
    onLevelChange?: (skillLevels: ISkillLevelItem[]) => void;
    isReadOnly?: boolean;
    tabsVariant?: TabVariant;
    tabButtonVariant?: TabVariant;
} & ComponentPropsWithoutRef<"div">;

const SkillLevelsTabs = ({
    skill,
    value = [],
    onLevelChange,
    isReadOnly,
    tabsVariant,
    tabButtonVariant,
    ...rest
}: Props) => {
    const [t] = useTranslation();
    const skillLevels = [skill.skillLevel1, skill.skillLevel2, skill.skillLevel3];

    const [tab, setTab] = useState<number>(1);
    const tabPanelRef = useRef<HTMLDivElement>(null);

    const selectedLevel = skillLevels[tab - 1];
    const isSelected = value?.some(level => level["@id"] === selectedLevel["@id"]);

    const handleSkillLevelChange = () => {
        if (isSelected) {
            onLevelChange?.([...value?.filter(level => level["@id"] !== selectedLevel["@id"])]);
        } else {
            onLevelChange?.([
                // remove skillLevels with same skill
                ...value?.filter(level => level.skill !== skill["@id"]),
                { ...selectedLevel, skill: skill["@id"] },
            ]);
        }
    };

    const { evalCriteria, actions } = skillLevels[tab - 1] ?? {};

    return (
        <div {...rest} data-cy="skill-levels-tabs">
            <Tabs variant={tabsVariant}>
                {skillLevels.map(skillLevel => {
                    const { level } = skillLevel;

                    return (
                        <Tab
                            key={skillLevel["@id"]}
                            tabId={level}
                            isSelected={tab === level}
                            variant={tabButtonVariant}
                            onClick={() => {
                                setTab(level);
                                tabPanelRef.current?.focus();
                            }}
                        >
                            <Text as="span" textAlign="center" variant="label">
                                {t("component.shared.SkillLevelsTabs.level", { level })}
                            </Text>
                        </Tab>
                    );
                })}
            </Tabs>
            <TabPanel ref={tabPanelRef} tabId={tab} isBordered={false} className="!rounded-none !border-0">
                <OfficialTitle as="h3">{skill.officialTitle}</OfficialTitle>
                <Grid>
                    <GridColumn col={6}>
                        <Criteria title={t("component.shared.SkillLevelsTabs.actions")}>{actions}</Criteria>
                    </GridColumn>
                    <GridColumn col={6}>
                        <Criteria title={t("component.shared.SkillLevelsTabs.evalCriteria")}>{evalCriteria}</Criteria>
                    </GridColumn>
                </Grid>

                {onLevelChange && !isReadOnly && (
                    <LevelControl
                        aria-label={t(
                            isSelected
                                ? "page.briefs.create.skills.removeSkillLevel"
                                : "page.briefs.create.skills.addSkillLevel",
                            {
                                skillLevelName: skill.shortTitle,
                                skillLevelNumber: selectedLevel.level,
                            },
                        )}
                        type="button"
                        onClick={handleSkillLevelChange}
                        data-cy="add-skill-level"
                        isSelected={isSelected}
                    >
                        {isSelected ? <Cross stroke="#CE0033" /> : <Plus size={24} />}
                        {t(
                            isSelected ? "page.briefs.create.skills.removeLevel" : "page.briefs.create.skills.addLevel",
                            {
                                skillLevelName: skill.shortTitle,
                                skillLevelNumber: selectedLevel.level,
                            },
                        )}
                    </LevelControl>
                )}
            </TabPanel>
        </div>
    );
};

export default styled(SkillLevelsTabs)`
    display: flex;
    flex-direction: column;
    border-radius: 0 ${({ theme }) => theme.shape.borderRadius.medium} ${({ theme }) => theme.shape.borderRadius.medium}
        0;

    ${Tab} {
        min-width: min-content;
        ${Text} {
            width: 100%;
        }
    }

    ${TabPanel} {
        flex: 1;
        min-height: 0;

        display: flex;
        flex-direction: column;

        padding: 0 ${({ theme }) => theme.spacing[5]} ${({ theme }) => theme.spacing[5]};

        ${Grid} {
            flex: 1;
            margin-top: ${({ theme }) => theme.spacing[5]};
            min-height: 0;
            grid-auto-rows: auto;
        }

        ${GridColumn} {
            overflow: hidden;
            min-height: 0;
        }

        ${Criteria} {
            overflow: auto;
            min-height: 0;
            height: 100%;
        }
    }
`;
