import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

const ProgressBar = styled(animated.div)<{ value: number }>`
    background-color: ${({ theme }) => theme.palette.purple.base};
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    will-change: transform;
    transform-origin: left;
    transform: ${({ value }) => `scaleX(${value})`};
`;

type Props = {
    value: number;
    className?: string;
};

const LinearProgress = ({ value, className }: Props) => {
    const [progressValue, setProgressValue] = useState(0);

    const progress = useSpring({ transform: `scaleX(${progressValue})` });

    useEffect(() => {
        setProgressValue(value / 100);
    }, [value]);

    return (
        <div className={className}>
            <ProgressBar value={value} style={progress} />
        </div>
    );
};

export default styled(LinearProgress)`
    height: 4px;
    position: relative;
    overflow: hidden;
    width: 50%;
    border-radius: ${({ theme }) => theme.shape.borderRadius.small};
    margin-top: ${({ theme }) => theme.spacing[6]};
    background-color: ${({ theme }) => theme.palette.purple.lighter};
`;
