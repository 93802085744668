import { useReducer } from "react";
import palette from "src/uikit/theme/palette";

import { IDatavizLineResource } from "@sol/sdk";

type DefaultState = {
    mode: BarMode;
    cumulatedNumber: Cumulated;
};

type HookPayload = {
    classroomId: string;
    learners: string[];
    fromDate?: string;
    toDate?: string;
};

export type DatavizFiltersState = DefaultState & HookPayload & { byLearner?: boolean };

enum ActionTypes {
    SET_DATES = "SET_DATES",
    SET_MODE = "SET_MODE",
    SET_LEARNERS = "SET_LEARNERS",
    TOGGLE_CUMULATED = "TOGGLE_CUMULATED",
    SET_CUMULATED = "SET_CUMULATED",
}

// action payloads types
type SetDatesActionPayload = { startDate: string; endDate: string };
type SetLearnersActionPayload = { learners: string[] };
type SetModePayload = { mode: "levels" | "works" };
type ToggleCumulatedPayload = { cumulatedNumber: Cumulated };

//  action types
type SetDatesAction = { type: ActionTypes.SET_DATES; payload: SetDatesActionPayload };
type SetLearnersAction = { type: ActionTypes.SET_LEARNERS; payload: SetLearnersActionPayload };
type SetMode = { type: ActionTypes.SET_MODE; payload: SetModePayload };
type ToggleCumulatedAction = { type: ActionTypes.TOGGLE_CUMULATED; payload: ToggleCumulatedPayload };

type Action = SetDatesAction | SetLearnersAction | SetMode | ToggleCumulatedAction;

type BarMode = {
    id: "levels" | "works";
    yAxisLabel: string;
    values: {
        action: IDatavizLineResource["id"];
        color: string;
        label: string;
    }[];
};

const BAR_MODES: BarMode[] = [
    {
        id: "levels",
        yAxisLabel: "component.dataviz.ClassroomDataviz.leftAxis.levels",
        values: [
            {
                action: "assignedLevels",
                color: palette.purple.base,
                label: "component.dataviz.ClassroomDataviz.assignedLevels",
            },
            {
                action: "validations",
                color: palette.green.base,
                label: "component.dataviz.ClassroomDataviz.validatedLevels",
            },
        ],
    },
    {
        id: "works",
        yAxisLabel: "component.dataviz.ClassroomDataviz.leftAxis.works",
        values: [
            {
                action: "works",
                color: palette.red.base,
                label: "component.dataviz.ClassroomDataviz.works",
            },
            {
                action: "corrections",
                color: palette.yellow.base,
                label: "component.dataviz.ClassroomDataviz.correctedWorks",
            },
        ],
    },
];

type Cumulated = "cumulated" | "uncumulated";

// action creators
const setDates = (payload: SetDatesActionPayload): SetDatesAction => ({
    type: ActionTypes.SET_DATES,
    payload,
});
const setMode = (payload: SetModePayload): SetMode => ({ type: ActionTypes.SET_MODE, payload });
const setLearners = (payload: SetLearnersActionPayload): SetLearnersAction => ({
    type: ActionTypes.SET_LEARNERS,
    payload,
});
const toggleCumulated = (payload: ToggleCumulatedPayload): ToggleCumulatedAction => ({
    type: ActionTypes.TOGGLE_CUMULATED,
    payload,
});

// default state + hook payload = initial state object
const initialState: DefaultState = {
    mode: BAR_MODES[0],
    cumulatedNumber: "cumulated",
};

const reducer = (state: DatavizFiltersState, action: Action): DatavizFiltersState => {
    switch (action.type) {
        case ActionTypes.TOGGLE_CUMULATED:
            return { ...state, cumulatedNumber: action.payload.cumulatedNumber };

        case ActionTypes.SET_LEARNERS:
            return { ...state, learners: action.payload.learners };

        case ActionTypes.SET_DATES:
            return { ...state, fromDate: action.payload.startDate, toDate: action.payload.endDate };

        case ActionTypes.SET_MODE:
            return { ...state, mode: BAR_MODES.find(mode => mode.id === action.payload.mode) || initialState.mode };

        default:
            throw new Error("An error occured while updating dataviz request filters");
    }
};

export { setDates, setMode, setLearners, toggleCumulated };

export const useDatavizFilters = (initialValues: HookPayload) =>
    useReducer(reducer, { ...initialState, ...initialValues });
