import {
    format,
    setHours,
    getHours,
    parse,
    startOfDay,
    endOfDay,
    set,
    isMatch,
    isWithinInterval,
    isValid,
} from "date-fns";
import { enUS, es, fr } from "date-fns/locale";
import { transparentize } from "polished";
import { ChangeEvent, ComponentProps, useState, useEffect, useMemo } from "react";
import { useId } from "react-aria";
import { DateRange as _ReactDateRange, OnDateRangeChangeProps, Range } from "react-date-range";
import { useTranslation } from "react-i18next";
import TextField from "src/components/shared/TextField";
import { BlockSkeleton } from "src/components/skeletons";
import i18n from "src/i18n";
import SegmentedControls from "src/uikit/SegmentedControls";
import { InputStatus } from "src/uikit/TextInput/utils";
import palette from "src/uikit/theme/palette";
import styled from "styled-components";

import { Button } from "@sol/uikit";

import { DayPeriod } from "../ProfessionalSituation/ProfessionalSituationDatePicker";

const ReactDateRange = styled(_ReactDateRange)`
    width: 100%;

    ${({ theme }) => theme.typography.p}

    .rdrMonth {
        flex: 1;
    }

    .rdrMonthsHorizontal {
        justify-content: space-around;
    }

    .rdrMonthName {
        text-align: center;
        ${({ theme }) => theme.typography.h4}
        text-transform: lowercase;
    }

    .rdrWeekDays {
        margin-bottom: ${({ theme }) => theme.spacing[5]};

        .rdrWeekDay {
            ${({ theme }) => theme.typography.label}
        }
    }

    .rdrDateDisplayWrapper {
        background-color: ${({ theme }) => theme.palette.white.base};

        .rdrDateDisplay {
            margin: ${({ theme }) => `${theme.spacing[4]} 0`};
        }
    }

    .rdrDateInput {
        background-color: ${({ theme }) => theme.palette.white.base};
        border: 1px solid ${({ theme }) => theme.palette.grey.base};
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        padding: ${({ theme }) => theme.spacing[4]};
        text-align: left;

        input {
            height: auto;
            ${({ theme }) => theme.typography.p}

            &::before {
                content: " ";
                display: block;
                background-image: url("/static/icons/calendar-icon.svg");
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
            }
        }
    }

    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
        top: 2px;
        bottom: 2px;
    }

    .rdrStartEdge {
        border-top-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }

    .rdrEndEdge {
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }

    .rdrDayEndOfWeek,
    .rdrDayEndOfMonth {
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};

        .rdrInRange,
        .rdrDayInPreview {
            border-top-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
            border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        }
    }

    .rdrDayStartOfWeek,
    .rdrDayStartOfMonth {
        border-top-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};

        .rdrInRange,
        .rdrDayInPreview {
            border-top-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
            border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        }
    }

    .rdrDayToday .rdrDayNumber span {
        &:after {
            content: "";
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 18px;
            height: 2px;
            border-radius: 2px;
            background: ${({ theme }) => theme.palette.purple.lighter};
        }
    }

    .rdrDayHovered {
        .rdrDayNumber {
            background: ${({ theme }) => theme.palette.purple.lighter};
            border-radius: ${({ theme }) => theme.shape.borderRadius.large};
            border: 0;
            top: 0;
            bottom: 0;
            border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        }
    }

    .rdrDayInPreview {
        background: ${({ theme }) => transparentize(0.7, theme.palette.purple.base)};
        border: 0;
    }

    .rdrDayStartPreview {
        border-top-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border: 0;
    }

    .rdrDayEndPreview {
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border: 0;
    }

    .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
        border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }

    .rdrNextPrevButton,
    .rdrPprevButton {
        border-radius: 50%;
    }
`;

const TextFieldWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex: 1;
    gap: ${({ theme }) => theme.spacing[3]};
    height: 96px;
`;

const InputsContainer = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[4]};

    ${TextField} {
        flex: 1;

        & + ${TextField} {
            margin-left: ${({ theme }) => theme.spacing[6]};
        }

        input {
            padding-left: 44px;
            background: url("/static/icons/calendar-icon.svg") no-repeat scroll 24px 24px;
            background-position: 12px 50%;
        }
    }
`;

const SegmentedControlsContainer = styled.div`
    flex: 1;
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.palette.purple.lighter};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    margin-bottom: 6px;
    padding: ${({ theme }) => theme.spacing[1]};
    margin-top: 30px;
`;

const Loader = styled(BlockSkeleton)`
    height: 475px;
    width: 100%;
`;

type Props = ComponentProps<typeof ReactDateRange> & {
    range?: Range;
    onChange: (range: Range) => void;
    getUserInputValidation: (isValid: boolean) => boolean;
    isLoading?: boolean;
    classroomStartDate: string;
    classroomEndDate: string;
};

const SUPPORTED_LANGUAGES: { [key: string]: { locale: Locale; format: string } } = {
    en: { locale: enUS, format: "yyyy-MM-dd" },
    fr: { locale: fr, format: "dd-MM-yyyy" },
    es: { locale: es, format: "dd-MM-yyyy" },
};

const useDateInput = ({
    date,
    onChange,
    classroomDateRange,
}: {
    date?: Date;
    onChange: (date: Date) => void;
    classroomDateRange: { classroomStartDate: string; classroomEndDate: string };
}) => {
    const [t] = useTranslation();
    const { classroomStartDate, classroomEndDate } = classroomDateRange;
    const language = SUPPORTED_LANGUAGES[i18n.language] ?? SUPPORTED_LANGUAGES["fr"];
    const formatedDate =
        date && isValid(new Date(date)) ? format(date, language.format, { locale: language.locale }) : "";

    const validateUserInput = useMemo(
        () => (date: string) => {
            const classroomDateToLocal = (date: string): string => {
                const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
                return format(new Date(parsedDate), language.format, { locale: language.locale });
            };

            const { localClassroomStartDate, localClassroomEndDate } = {
                localClassroomStartDate: classroomDateToLocal(classroomStartDate),
                localClassroomEndDate: classroomDateToLocal(classroomEndDate),
            };

            if (!isMatch(date, language.format, { locale: language.locale })) {
                return t("component.home.PedagogicalScenarioCard.invalidDateFormat");
            }

            if (
                !isWithinInterval(parse(date, language.format, new Date(), { locale: language.locale }), {
                    start: parse(localClassroomStartDate, language.format, new Date(), { locale: language.locale }),
                    end: parse(localClassroomEndDate, language.format, new Date(), { locale: language.locale }),
                })
            ) {
                return t("component.home.PedagogicalScenarioCard.outOfRange");
            }

            return null;
        },
        [t, classroomStartDate, classroomEndDate, language],
    );

    const [value, setValue] = useState<string>(formatedDate);
    const [error, setError] = useState<string | null>(validateUserInput(formatedDate));

    useEffect(() => {
        setValue(formatedDate);
        setError(validateUserInput(formatedDate));
    }, [formatedDate, validateUserInput]);

    const handleChange = (date: string): void => {
        setValue(date);
        const error = validateUserInput(date);
        setError(error);

        if (!error) {
            onChange(parse(date, language.format, new Date(), { locale: language.locale }));
        }
    };

    return { value, onChange: handleChange, error };
};

const DateRange = ({
    range,
    onChange,
    className,
    isLoading = false,
    classroomStartDate,
    classroomEndDate,
    getUserInputValidation,
    ...dateRangeProps
}: Props) => {
    const [t] = useTranslation();
    const startDateInputId = useId();
    const endDateInputId = useId();

    const startDateInput = useDateInput({
        date: range?.startDate,
        onChange: date => {
            onChange({ ...range, startDate: date });
        },
        classroomDateRange: { classroomStartDate, classroomEndDate },
    });
    const endDateInput = useDateInput({
        date: range?.endDate,
        onChange: date => {
            onChange({ ...range, endDate: date });
        },
        classroomDateRange: { classroomStartDate, classroomEndDate },
    });

    const language = SUPPORTED_LANGUAGES[i18n.language ?? "fr"];

    const startPeriodOptions = [
        {
            id: DayPeriod.MORNING,
            label: t("component.home.PedagogicalScenarioCard.dayPeriod.morning"),
        },
        {
            id: DayPeriod.MIDDAY,
            label: t("component.home.PedagogicalScenarioCard.dayPeriod.midday"),
        },
    ];

    const endPeriodOptions = [
        {
            id: DayPeriod.MIDDAY,
            label: t("component.home.PedagogicalScenarioCard.dayPeriod.midday"),
        },
        {
            id: DayPeriod.NIGHT,
            label: t("component.home.PedagogicalScenarioCard.dayPeriod.night"),
        },
    ];

    useEffect(() => {
        if (!startDateInput.error && !endDateInput.error) {
            getUserInputValidation(true);
        } else {
            getUserInputValidation(false);
        }
    }, [startDateInput, endDateInput]);

    const labelDateFormat = format(new Date("12-31-2042"), language.format, {
        locale: language.locale,
    });

    return (
        <div className={className}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <InputsContainer>
                        <TextFieldWrapper>
                            <TextField
                                id={startDateInputId}
                                type="text"
                                label={`${t("component.shared.DatePicker.startDateLabel")} - ${t(
                                    "component.home.PedagogicalScenarioCard.datePickerInputeDateFormat",
                                    {
                                        format: labelDateFormat,
                                    },
                                )}`}
                                placeholder={t("component.shared.DatePicker.startPlaceholder")}
                                value={startDateInput.value}
                                onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
                                    startDateInput.onChange(currentTarget.value);
                                }}
                                feedback={startDateInput.error}
                                status={startDateInput.error ? InputStatus.ERROR : InputStatus.DEFAULT}
                                data-cy="range-start-date"
                            />
                            <SegmentedControlsContainer>
                                <SegmentedControls
                                    variant="primary"
                                    data-cy="startType"
                                    orientation="horizontal"
                                    onChange={value => {
                                        if (!range?.startDate) {
                                            return;
                                        }
                                        if (value === DayPeriod.MORNING) {
                                            onChange({
                                                ...range,
                                                startDate: startOfDay(range.startDate),
                                            });
                                        } else {
                                            onChange({
                                                ...range,
                                                startDate: setHours(range.startDate, 12),
                                            });
                                        }
                                    }}
                                    value={
                                        range?.startDate &&
                                        (getHours(range?.startDate) === 12 ? DayPeriod.MIDDAY : DayPeriod.MORNING)
                                    }
                                    title={t("component.home.PedagogicalScenarioCard.Timeline.Toolbar.scale.title")}
                                    options={startPeriodOptions}
                                />
                            </SegmentedControlsContainer>
                        </TextFieldWrapper>
                        <TextFieldWrapper>
                            <TextField
                                id={endDateInputId}
                                type="text"
                                label={`${t("component.shared.DatePicker.endDateLabel")} - ${t(
                                    "component.home.PedagogicalScenarioCard.datePickerInputeDateFormat",
                                    {
                                        format: labelDateFormat,
                                    },
                                )}`}
                                placeholder={t("component.shared.DatePicker.endPlaceholder")}
                                value={endDateInput.value}
                                onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
                                    endDateInput.onChange(currentTarget.value);
                                }}
                                feedback={endDateInput.error}
                                status={endDateInput.error ? InputStatus.ERROR : InputStatus.DEFAULT}
                                data-cy="range-end-date"
                            />
                            <SegmentedControlsContainer>
                                <SegmentedControls
                                    variant="primary"
                                    data-cy="startType"
                                    orientation="horizontal"
                                    onChange={value => {
                                        if (!range?.endDate) {
                                            return;
                                        }
                                        if (value === DayPeriod.MIDDAY) {
                                            onChange({
                                                ...range,
                                                endDate: set(range.endDate, { hours: 11, minutes: 59, seconds: 59 }),
                                            });
                                        } else {
                                            onChange({
                                                ...range,
                                                endDate: endOfDay(range.endDate),
                                            });
                                        }
                                    }}
                                    value={
                                        range?.endDate &&
                                        (getHours(range?.endDate) <= 11 ? DayPeriod.MIDDAY : DayPeriod.NIGHT)
                                    }
                                    title={t("component.home.PedagogicalScenarioCard.Timeline.Toolbar.scale.title")}
                                    options={endPeriodOptions}
                                />
                            </SegmentedControlsContainer>
                        </TextFieldWrapper>
                    </InputsContainer>
                    <ReactDateRange
                        {...dateRangeProps}
                        ranges={
                            range?.startDate || range?.endDate
                                ? [{ ...range }]
                                : [{ ...range, startDate: new Date(), endDate: new Date() }]
                        }
                        onChange={(ranges: OnDateRangeChangeProps) => {
                            if (!range?.key) {
                                return;
                            }
                            const currentRange = ranges[range.key];
                            const startDateHours = getHours(parse(startDateInput.value, language.format, new Date()));
                            const endDateHours = getHours(parse(endDateInput.value, language.format, new Date()));
                            onChange({
                                ...currentRange,
                                startDate: currentRange.startDate
                                    ? set(currentRange.startDate, { hours: startDateHours })
                                    : new Date(),
                                endDate: currentRange.endDate
                                    ? set(currentRange?.endDate, {
                                          hours: endDateHours > 0 ? endDateHours : 23,
                                          minutes: endDateHours > 0 ? endDateHours : 59,
                                          seconds: endDateHours > 0 ? endDateHours : 59,
                                      })
                                    : new Date(),
                            });
                        }}
                        months={2}
                        direction="horizontal"
                        rangeColors={[palette.purple.base]}
                        monthDisplayFormat="MMMM"
                        weekdayDisplayFormat="EEEEE"
                        weekStartsOn={1}
                        locale={language.locale}
                        dateDisplayFormat={language.format}
                        showDateDisplay={false}
                        endDatePlaceholder={t("component.shared.DatePicker.endPlaceholder")}
                        minDate={new Date(classroomStartDate)}
                        maxDate={new Date(classroomEndDate)}
                    />
                </>
            )}
        </div>
    );
};

export default styled(DateRange)`
    display: flex;
    flex-direction: column;

    ${Button} {
        margin-right: 0;
        margin-left: auto;
    }
`;
