import { TFunction } from "i18next";
import React, { ComponentPropsWithoutRef, MutableRefObject, useRef, useState } from "react";
import { useId } from "react-aria";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { SROnly, Text } from "src/uikit";
import theme from "src/uikit/theme";
import styled from "styled-components";

import { Cross } from "@sol/icons";
import { IMediaResource, IMediaSecuredResource, MediaType as MediaResourceType } from "@sol/sdk";
import useMediaUpload from "@sol/sdk/media/useMediaUpload";
import { IconButton } from "@sol/uikit";
import Button from "@sol/uikit/buttons/Button";

import ApiErrorAlert from "./ApiErrorAlert";
import FieldErrorMessage from "./FieldErrorMessage";
import LinearProgress from "./LinearProgress";
import { BlockSkeleton } from "./skeletons";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 507px;
    border: 1px solid ${({ theme }) => theme.palette.grey.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    background-color: ${({ theme }) => theme.palette.purple.lightest};
`;

const ImageDropzone = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Text} + ${Text} {
        margin-top: ${({ theme }) => theme.spacing[3]};
    }
`;

const ImportButton = styled(Button)`
    margin: ${({ theme }) => theme.spacing[5]} 0;
`;

const PREVIEW_PADDING = theme.spacing[5];

const Preview = styled.div`
    padding: ${PREVIEW_PADDING};
    width: 100%;
    height: 100%;
`;

const LoadedImage = styled.img`
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    border: 2px dashed ${({ theme }) => theme.palette.purple.base};
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const RemoveButton = styled(IconButton)`
    position: absolute;
    top: ${({ theme }) => `calc(${theme.spacing[3]} + ${PREVIEW_PADDING})`};
    right: ${({ theme }) => `calc(${theme.spacing[3]} + ${PREVIEW_PADDING})`};
`;

const LazyLoading = styled.img`
    display: none;
`;

const LazyImagePreview = (props: ComponentPropsWithoutRef<"img">) => {
    const [isLoading, setIsLoading] = useState(true);

    if (isLoading) {
        return (
            <>
                <LazyLoading {...props} onLoad={() => setIsLoading(false)} />
                <BlockSkeleton />
            </>
        );
    }

    return (
        <Preview>
            <LoadedImage {...props} />
        </Preview>
    );
};

type RenderImagePreviewProps = {
    data: IMediaResource | IMediaSecuredResource | null;
    isLoading: boolean;
    maxSize: number;
    onOpenClick: () => void;
    onRemoveClick: () => void;
    progress: number;
    supportedMIME: string;
    importButtonRef?: MutableRefObject<null>;
    titleSectionId?: string;
};

const renderImagePreview = ({
    data,
    isLoading,
    onOpenClick,
    onRemoveClick,
    progress,
    supportedMIME,
    importButtonRef,
    titleSectionId,
}: RenderImagePreviewProps) => {
    const [t] = useTranslation();
    const supportedMIMEId = useId();

    if (isLoading) {
        return (
            <>
                <Text variant="label">{t("input.image.progress")}</Text>
                <LinearProgress value={progress} />
            </>
        );
    }

    if (data) {
        return (
            <>
                <LazyImagePreview alt={data.title} src={data.url} />
                <RemoveButton type="button" onClick={onRemoveClick}>
                    <Cross />
                    <SROnly>{t("input.image.delete")}</SROnly>
                </RemoveButton>
            </>
        );
    }

    return (
        <ImageDropzone>
            <Text variant="subheading" id={supportedMIMEId}>
                {supportedMIME && `${t("input.image.supportedMIME")}:  ${supportedMIME}`}.
            </Text>
            <Text variant="label">{t("input.image.instructions.text")}</Text>
            <ImportButton
                aria-describedby={`${supportedMIMEId} ${titleSectionId}`}
                ref={importButtonRef}
                type="button"
                variant="primary"
                outlined
                onClick={onOpenClick}
            >
                {t("input.image.instructions.button")}
            </ImportButton>
        </ImageDropzone>
    );
};

const MAX_FILE_SIZE = 10000000;

const getErrorMessage = (t: TFunction, code?: string) => {
    if (code === "file-too-large") {
        return t("component.AddMediaForm.fileTooLargeErrorMessage", { maxSize: `${MAX_FILE_SIZE / 1000000} Mo` });
    } else {
        return t("component.AddMediaForm.defaultErrorMessage");
    }
};

type MediaType = IMediaResource | IMediaSecuredResource | null;

type Props = {
    input: {
        value: MediaType;
        onChange: (media: MediaType | undefined) => void;
    };
    maxFileSize?: number;
    supportedMIMETypes: string[];
    titleSectionId?: string;
};

const ImageInput = ({
    input: { value, onChange },
    maxFileSize = MAX_FILE_SIZE,
    supportedMIMETypes,
    titleSectionId,
}: Props) => {
    const [t] = useTranslation();
    const importButtonRef = useRef(null);

    const [errorMessage, setErrorMessage] = useState("");

    const getTranslation = (MIMETypes: string[]) => {
        const uppercaseMIMETypes = MIMETypes.map(type => {
            const formatted = type.split("/")[1];
            return formatted.toUpperCase();
        });

        return uppercaseMIMETypes.join(` ${t("component.AddMediaForm.separator")} `);
    };

    const [uploadAttachmentMutation, { isLoading, progress, error, reset }] = useMediaUpload({
        onSuccess: ({ data }) => onChange(data),
    });

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        accept: supportedMIMETypes,
        onDropAccepted: async (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            uploadAttachmentMutation({ payload: { secured: false, type: MediaResourceType.FILE, file } });
        },
        onDrop: (_, rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                const errorMessage = getErrorMessage(t, rejectedFiles[0].errors[0].code);
                setErrorMessage(errorMessage);
            }
        },
    });

    return (
        <>
            <Container {...getRootProps()} empty={!value}>
                <input {...getInputProps()} />
                {renderImagePreview({
                    isLoading,
                    data: value,
                    progress,
                    maxSize: maxFileSize,
                    supportedMIME: getTranslation(supportedMIMETypes),
                    onRemoveClick: () => {
                        onChange(null);
                    },
                    onOpenClick: () => {
                        open();
                    },
                    importButtonRef,
                    titleSectionId,
                })}
            </Container>
            {errorMessage && <FieldErrorMessage message={getErrorMessage(t, errorMessage)} />}
            <ApiErrorAlert error={error} dissmiss={reset} triggerElement={importButtonRef} />
        </>
    );
};

export default ImageInput;
