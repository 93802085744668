import qs from "qs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useLocale from "src/hooks/useLocale";
import useToken from "src/hooks/useToken";

import { Feature, FeatureGuard } from "@sol/features";
import { Download, EyeOn, EyeOff } from "@sol/icons";
import { getResourceUuid, IClassroom } from "@sol/sdk";
import { useClassroomMetricsQuery } from "@sol/sdk/classrooms/queries/classroomMetricsQuery";
import { Button, IconButton } from "@sol/uikit";

import Card from "../home/Card";
import { sanitizeFilename } from "../shared/utils";
import LearnerClassroomMetrics from "./LearnerClassroomMetrics";
import TrainerClassroomMetrics from "./TrainerClassroomMetrics";

type Props = {
    id: Feature;
    classroom?: IClassroom;
    isTrainer: boolean;
    isLearner: boolean;
};

const ClassroomMetricsSection = ({ classroom, isTrainer, isLearner, ...rest }: Props) => {
    const [locale] = useLocale();
    const token = useToken();
    const [t] = useTranslation();

    const [showHelper, setShowHelper] = useState(false);

    const classroomId = classroom && getResourceUuid(classroom);

    const { data: classroomMetrics, isLoading } = useClassroomMetricsQuery({ classroom }, { enabled: !!classroom });

    const toggleHelper = () => setShowHelper(!showHelper);

    return (
        <Card
            {...rest}
            title={t("component.ClassroomMetricsSection.title")}
            helper={
                <>
                    <IconButton onClick={toggleHelper} variant="primary" disabled={isLoading}>
                        {t(`component.ClassroomMetricsSection.${showHelper ? "hideHelper" : "showHelper"}`)}
                        {showHelper ? <EyeOff /> : <EyeOn />}
                    </IconButton>

                    <FeatureGuard feature={Feature.EXPORT_CLASSROOM_STATISTICS}>
                        <Button
                            data-cy="export-classroom-stats-button"
                            as="a"
                            href={`/api/classrooms/${classroomId}/stats?${qs.stringify({
                                token,
                                locale,
                            })}`}
                            download={sanitizeFilename(
                                t("component.ClassroomMetricsSection.exportCsvButton.filename", {
                                    date: new Date(),
                                }),
                            )}
                            outlined
                            variant="primary"
                        >
                            {t("component.ClassroomMetricsSection.exportCsvButton.exportCsv")}
                            <Download />
                        </Button>
                    </FeatureGuard>
                </>
            }
        >
            {isTrainer && classroom && (
                <TrainerClassroomMetrics
                    showHelper={showHelper}
                    isLoading={isLoading}
                    metrics={classroomMetrics}
                    learnersCount={classroom.nbLearners}
                />
            )}
            {isLearner && (
                <LearnerClassroomMetrics showHelper={showHelper} isLoading={isLoading} metrics={classroomMetrics} />
            )}
        </Card>
    );
};

export default ClassroomMetricsSection;
