import React, { FC } from "react";
import styled from "styled-components";

import { Text } from "../../../../uikit";

type Props = {
    title: string;
    content: string;
    className?: string;
    visible?: boolean;
};

const HelperContainer = styled.div<Pick<Props, "visible">>`
    background-color: ${({ theme }) => theme.palette.purple.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    padding: ${({ theme }) => theme.spacing[5]};
    z-index: ${({ theme }) => theme.zIndex.overlay};
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    ${({ visible }) => (visible ? { opacity: 1, visibility: "visible" } : { opacity: 0, visibility: "hidden" })};

    ${Text} {
        color: ${({ theme }) => theme.palette.white.base};

        & + ${Text} {
            margin-top: ${({ theme }) => theme.spacing[6]};
            flex: 1;
            overflow: auto;
            min-height: 0;
        }
    }
`;

const MetricsCardHelper: FC<Props> = ({ className, title, content, visible, children }) => (
    <div className={className}>
        <HelperContainer visible={visible}>
            <Text variant="label" as="h2">
                {title}
            </Text>
            <Text preserve>{content}</Text>
        </HelperContainer>
        <div aria-hidden={visible}>{children}</div>
    </div>
);

export default styled(MetricsCardHelper)`
    width: 100%;
    height: 100%;
    position: relative;

    > div {
        height: 100%;
        width: 100%;
        overflow: ${({ visible }) => (visible ? "hidden" : "initial")};
    }
`;
