import Link from "next/link";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUuid } from "src/utils/urlFormatter";
import styled from "styled-components";

import { Feature, FeatureGuard } from "@sol/features";
import { Download, Pencil, Loading } from "@sol/icons";
import { Route } from "@sol/routing";
import { useApi, IClassroom, IClassroomUser, IUser } from "@sol/sdk";
import Button from "@sol/uikit/buttons/Button";

import ApiErrorAlert from "../../ApiErrorAlert";
import { TextSkeleton } from "../../skeletons";
import Card from "../Card";
import LearnersExplorer from "./LearnersExplorer";

type Props = {
    id: Feature;
    isPending: boolean;
    learners: IClassroomUser[] | IUser[];
    classroom?: IClassroom;
};

const ClassroomLearnersCard = ({ isPending, learners, classroom, ...rest }: Props) => {
    const [t] = useTranslation();
    const classroomId = classroom ? (getUuid(classroom["@id"]) as string) : undefined;
    const api = useApi();
    const downloadButtonRef = useRef(null);

    const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleDownloadClick = async () => {
        setIsDownloadButtonLoading(true);
        try {
            const date = new Date();
            const filename = `${date.toLocaleDateString().split("/").join("")}-${classroom?.title
                .toLowerCase()
                .split(" ")
                .join("-")}-balance-sheet.csv`;
            const { data: csv } = await api.get(`/classrooms/${classroomId}/balance-sheet.csv`);
            const file = URL.createObjectURL(new Blob([csv]));
            const linkElement = document.createElement("a");
            linkElement.href = file;
            linkElement.download = filename;
            linkElement.click();
            URL.revokeObjectURL(file);
            setIsDownloadButtonLoading(false);
        } catch (e) {
            setError(e);
            setIsDownloadButtonLoading(false);
        }
    };
    return (
        <Card
            {...rest}
            data-cy="learners-card"
            title={isPending ? <TextSkeleton width="40%" /> : t("page.classrooms.home.learners.title")}
            helper={
                classroom &&
                !isPending && (
                    <>
                        <FeatureGuard feature={Feature.CLASSROOM_UPDATE_LEARNERS}>
                            <Link href={Route.CLASSROOMS_LEARNERS} passHref>
                                <Button
                                    outlined
                                    variant="primary"
                                    data-cy="classroom-learners-update-button"
                                    as="a"
                                    title={t("component.classroomLearnersCard.editButton.title", {
                                        classroomTitle: classroom.title,
                                    })}
                                >
                                    {t("component.classroomLearnersCard.editButton.text")} <Pencil />
                                </Button>
                            </Link>
                        </FeatureGuard>
                        <FeatureGuard feature={Feature.CLASSROOM_DOWNLOAD_BALANCE_SHEET}>
                            <Button
                                ref={downloadButtonRef}
                                outlined
                                variant="primary"
                                data-cy="classroom-learners-download-balance-sheet"
                                onClick={handleDownloadClick}
                                loading={isDownloadButtonLoading}
                            >
                                {t("component.classroomLearnersCard.downloadButton.text")}
                                {isDownloadButtonLoading ? <Loading /> : <Download />}
                            </Button>
                        </FeatureGuard>
                    </>
                )
            }
        >
            <LearnersExplorer isPending={isPending} learners={learners} />
            <ApiErrorAlert error={error} dissmiss={() => setError(null)} triggerElement={downloadButtonRef} />
        </Card>
    );
};

export default styled(ClassroomLearnersCard)`
    ${Button} {
        margin: 0;
    }
`;
