import { transparentize } from "polished";
import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { ArrowProps } from "react-multi-carousel";
import styled from "styled-components";

import { Chevron } from "@sol/icons";

import IconButton from "../buttons/IconButton";

const MAP_DIRECTION_WITH_FUNCTION = {
    right: "next",
    left: "previous",
};

type Props = {
    direction: "right" | "left";
    className?: string;
} & ComponentProps<typeof IconButton> &
    ArrowProps;

const CarouselArrow = ({ direction, className, ...rest }: Props) => {
    const [t] = useTranslation();

    return (
        <IconButton
            {...rest}
            className={className}
            data-cy={`carousel-${MAP_DIRECTION_WITH_FUNCTION[direction]}`}
            aria-label={t(`component.pagination.${MAP_DIRECTION_WITH_FUNCTION[direction]}`)}
        >
            <Chevron direction={direction} />
        </IconButton>
    );
};

export default styled(CarouselArrow)`
    position: absolute;
    left: ${({ theme, direction }) => direction === "left" && `-${theme.spacing[5]}`};
    right: ${({ theme, direction }) => direction === "right" && `-${theme.spacing[5]}`};
    z-index: ${({ theme }) => theme.zIndex.navigation};

    box-shadow: 0px 0px 10px ${({ theme }) => transparentize(0.75, theme.palette.black.base)};
`;
