import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import { CircularGauge } from "src/modules/uikit/molecules/CircularGauge";

import useScroll from "../../../hooks/useScroll";

export enum ProgressbarColor {
    GREEN = "green-base",
    YELLOW = "yellow-base",
}

type Props = {
    value?: number;
    total?: number;
    color?: ProgressbarColor;
    showDetails?: boolean;
};

const MetricsCircularProgressbar = ({ value = 0, total = 0, color = ProgressbarColor.GREEN, showDetails }: Props) => {
    const [active, setActive] = useState(true);

    const percentage = total > 0 ? Math.round((value * 100) / total) : 0;
    const scroll = useScroll();

    return (
        <Waypoint scrollableAncestor={scroll} onEnter={active ? () => setActive(false) : undefined}>
            <div>
                <CircularGauge
                    value={!active ? percentage : 0}
                    total={total}
                    formatOptions={{ style: "unit", unit: "percent" }}
                    showDetails={showDetails}
                    progressRingColor={color}
                />
            </div>
        </Waypoint>
    );
};

export default MetricsCircularProgressbar;
