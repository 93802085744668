import React, { ReactNode } from "react";
import { useId } from "react-aria";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Move, SvgIcon } from "@sol/icons";
import { IUserSettings } from "@sol/sdk";

import { Text } from "../../../uikit";

type DraggableItemContentProps = {
    isDragging: boolean;
};

const DraggableItemContent = styled.div<DraggableItemContentProps>`
    display: flex;
    align-items: center;

    --icon-color: ${({ theme }) => theme.palette.purple.base};
    --text-color: ${({ theme }) => theme.palette.purple.base};

    > :last-child {
        ${SvgIcon} {
            height: 16px;
            width: 16px;
        }

        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[2]}`};
        border: 2px solid ${({ theme, isDragging }) => (isDragging ? "transparent" : theme.palette.purple.base)};
        border-radius: ${({ theme }) => theme.shape.borderRadius.small};
        background-color: ${({ theme, isDragging }) =>
            isDragging ? theme.palette.yellow.base : theme.palette.purple.lightest};
    }
`;

type Option = {
    id: IUserSettings["homecards"][number];
    icon: ReactNode;
    label: ReactNode;
};

const reorder = (list: Option[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

type ItemProps = {
    item: Option;
    index: number;
    descriptionMessageId: string;
};

const DraggableItem = ({ item, index, descriptionMessageId }: ItemProps) => {
    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided, snapshot) => (
                <DraggableItemContent
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    aria-describedby={descriptionMessageId}
                    isDragging={snapshot.isDragging}
                >
                    <Move />
                    <div>
                        {item.icon}
                        <Text variant="subheading" lines={1}>
                            {item.label}
                        </Text>
                    </div>
                </DraggableItemContent>
            )}
        </Draggable>
    );
};

type Props = {
    className?: string;
    navOrder: Option[];
    setNavOrder: (navOrder: Option[]) => void;
};

const HomeNavigationDnd = ({ className, navOrder, setNavOrder }: Props) => {
    const [t] = useTranslation();
    const descriptionMessageId = useId();
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const newNavOrder = reorder(navOrder, result.source.index, result.destination.index);

        setNavOrder(newNavOrder);
    };

    return (
        <div className={className} data-cy="draggable-navigation">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps} data-cy="draggable-navigation-list">
                            {navOrder.map((item: any, index: number) => (
                                <>
                                    <DraggableItem
                                        item={item}
                                        index={index}
                                        key={item.id}
                                        descriptionMessageId={descriptionMessageId}
                                    />
                                    <div className="sr-only">{index + 1}</div>
                                </>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="hidden" id={descriptionMessageId}>
                {t("page.home.modal.a11yDescriptionMessage")}
            </div>
        </div>
    );
};

export default styled(HomeNavigationDnd)`
    > div > div {
        margin-bottom: ${({ theme }) => theme.spacing[2]};
    }
`;
