import React from "react";

import { SimplePopup, PopupType, PopupMessageType } from "@sol/uikit/molecules/SimplePopup";

type Props = {
    onCancel?: () => void;
    onConfirm?: () => void;
    isLoading?: boolean;
    isOpen?: boolean;
    text: string;
    cancelLabel?: string;
    confirmLabel?: string;
};

const ConfirmationModal = ({ onCancel, onConfirm, isLoading, isOpen, text, confirmLabel, cancelLabel }: Props) => {
    return (
        <SimplePopup
            popupType={PopupType.CONFIRM}
            messageType={PopupMessageType.INFO}
            open={!!isOpen}
            label={text}
            onClickConfirm={onConfirm}
            onClickCancel={onCancel}
            isConfirmLoading={isLoading}
            confirmText={confirmLabel}
            cancelText={cancelLabel}
        />
    );
};

export default ConfirmationModal;
