/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: enable typing after rework of form wizards
import { yupResolver } from "@hookform/resolvers/yup";
import { EditorState } from "draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { OrderedMap } from "immutable";
import { isArray, isEqual } from "lodash";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ProfessionalSituationsPicker, {
    ProfessionalSituationPickerContext,
} from "src/components/shared/ProfessionalSituations/ProfessionalSituationsPicker";
import { BriefSteps } from "src/enums";
import { Merge } from "type-fest";

import {
    ILanguageResource,
    IMediaResource,
    IProfessionalSituationResource,
    ISkillLevelItem,
    ITagResource,
} from "@sol/sdk";

import { CreateBriefStep, CreateBriefStepWithSummary } from "./constants";
import { AddAdditionalInformationForm, AddBriefDescriptionForm, AddBriefSkillLevelsForm } from "./forms";
import { BriefStepsStatusObject, BriefStepStatus, getBriefSchemas, getBriefStepsStatuses } from "./forms/validations";

const unCapitalize = (value?: string) => (value ? value.charAt(0).toLowerCase() + value.slice(1) : "");

export type CreateBriefSkillsStepPayload = {
    skillLevels: Omit<Merge<{ skillTitle: string }, ISkillLevelItem>, "actions" | "evalCriteria">[] | [];
};

export type CreateBriefDescriptionStepPayload = {
    context: EditorState;
    description: string;
    evaluationModalities: string;
    expectedWork: string;
    pedagogicModalities: EditorState;
    performanceCriteria: string;
    title: string;
    isCorrected?: boolean;
    language?: ILanguageResource;
};

export type CreateBriefAdditionalInformationStepPayload = {
    resources: IMediaResource[];
    tags: ITagResource[];
    image?: IMediaResource;
};

export type CreateBriefFormValues = {
    "professional-situation"?: IProfessionalSituationResource;
    skills: CreateBriefSkillsStepPayload;
    description: CreateBriefDescriptionStepPayload;
    "additional-information": CreateBriefAdditionalInformationStepPayload;
};

const initialState: CreateBriefFormValues = {
    "professional-situation": undefined,
    skills: { skillLevels: [] },
    description: {
        context: EditorState.createEmpty(),
        description: "",
        evaluationModalities: "",
        expectedWork: "",
        pedagogicModalities: EditorState.createEmpty(),
        performanceCriteria: "",
        title: "",
    },
    "additional-information": {
        resources: [],
        tags: [],
        image: undefined,
    },
};

type StepFormData = {
    index: number;
    subtitle: string;
    form: ReactNode;
    skeleton?: ReactNode;
};

type ChildrenPayload = {
    currentStepData: StepFormData;
    stepStatuses: BriefStepsStatusObject;
    formValues: CreateBriefFormValues;
    forms: Record<
        CreateBriefStep,
        UseFormReturn<
            | CreateBriefSkillsStepPayload
            | CreateBriefDescriptionStepPayload
            | CreateBriefAdditionalInformationStepPayload
            | IProfessionalSituationResource
        >
    >;
};

type Props = {
    currentStep: CreateBriefStepWithSummary;
    onStepChange: (step: CreateBriefStepWithSummary) => void;
    defaultValues?: CreateBriefFormValues;
    children: (stepData: ChildrenPayload) => ReactNode;
    isEdit: boolean;
};

const CreateBriefFormsManager = ({ isEdit, currentStep, onStepChange, defaultValues, children }: Props) => {
    const [t] = useTranslation();
    const [formValues, setFormValues] = useState<CreateBriefFormValues>(initialState);
    const { query } = useRouter();
    const { SKILLS, DESCRIPTION, ADDITIONAL_INFORMATION, SUMMARY, PROFESSIONAL_SITUATION } = BriefSteps;
    const briefId = isArray(query.id) ? query.id[0] : query.id;
    const { BRIEF } = ProfessionalSituationPickerContext;

    const CREATE_BRIEF_STEPS = useMemo(() => {
        return OrderedMap<CreateBriefStep, StepFormData>({
            "professional-situation": {
                subtitle: t("component.CreateBriefLayout.steps.professionalSituation.subtitle"),
                index: 1,
                form: (
                    <ProfessionalSituationsPicker
                        onProfessionalSituationSelect={professionalSituation => {
                            setFormValues({
                                ...formValues,
                                "professional-situation": professionalSituation,
                            });
                            onStepChange(SKILLS);
                        }}
                        briefSkills={formValues.skills.skillLevels.map(skillLevel => skillLevel.skill)}
                        briefLanguage={formValues.description.language?.["@id"]}
                        briefId={briefId}
                        isEdit={isEdit}
                        professionalSituationId={formValues[PROFESSIONAL_SITUATION]?.["@id"]}
                        onNextCLick={() => onStepChange(SKILLS)}
                        context={BRIEF}
                        showPlaceBriefButton={false}
                    />
                ),
            },
            skills: {
                subtitle: t("component.CreateBriefLayout.steps.skills.subtitle"),
                index: 2,
                skeleton: (
                    <AddBriefSkillLevelsForm
                        professionalSituationId={formValues[PROFESSIONAL_SITUATION]?.["@id"]}
                        briefSkills={formValues.skills.skillLevels.map(skillLevel => skillLevel.skill)}
                        onBackClick={() => onStepChange(PROFESSIONAL_SITUATION)}
                        onStepSubmit={skillLevels => {
                            setFormValues({
                                ...formValues,
                                skills: {
                                    skillLevels,
                                },
                            });
                            onStepChange(DESCRIPTION);
                        }}
                        isLoading={true}
                        isReadOnly={formValues.description?.isCorrected}
                        isEdit={isEdit}
                    />
                ),
                form: (
                    <AddBriefSkillLevelsForm
                        professionalSituationId={formValues[PROFESSIONAL_SITUATION]?.["@id"]}
                        briefSkills={formValues.skills.skillLevels.map(skillLevel => skillLevel.skill)}
                        onBackClick={() => onStepChange(PROFESSIONAL_SITUATION)}
                        onStepSubmit={skillLevels => {
                            setFormValues({
                                ...formValues,
                                skills: {
                                    skillLevels,
                                },
                            });
                            onStepChange(DESCRIPTION);
                        }}
                        isLoading={false}
                        isReadOnly={formValues.description?.isCorrected}
                        isEdit={isEdit}
                    />
                ),
            },
            description: {
                subtitle: t("component.CreateBriefLayout.steps.description.subtitle"),
                index: 3,
                form: (
                    <AddBriefDescriptionForm
                        onBackClick={() => onStepChange(SKILLS)}
                        onStepSubmit={description => {
                            setFormValues({
                                ...formValues,
                                description,
                            });
                            onStepChange(ADDITIONAL_INFORMATION);
                        }}
                    />
                ),
            },
            "additional-information": {
                subtitle: t("component.CreateBriefLayout.steps.additionalInformation.subtitle"),
                index: 4,
                form: (
                    <AddAdditionalInformationForm
                        onBackClick={() => onStepChange(DESCRIPTION)}
                        onStepSubmit={additionalInformation => {
                            setFormValues({
                                ...formValues,
                                "additional-information": additionalInformation,
                            });
                            onStepChange(SUMMARY);
                        }}
                    />
                ),
            },
        });
    }, [formValues, t]);

    // currentStep value cannot be "summary" here
    const currentStepData = CREATE_BRIEF_STEPS.get(currentStep as CreateBriefStep);

    // generate forms in order to get each form's default value and validation schema
    // @ts-ignore
    const forms: ChildrenPayload["forms"] = CREATE_BRIEF_STEPS.map((_, key: CreateBriefStep) => {
        return useForm({
            resolver: yupResolver(getBriefSchemas(t)[key]),
            // @ts-ignore
            defaultValues: initialState[key],
            shouldUnregister: false,
            mode: "onSubmit",
        });
    }).toObject();

    useEffect(() => {
        if (defaultValues) {
            setFormValues(defaultValues);

            // @ts-ignore
            forms[PROFESSIONAL_SITUATION].reset(defaultValues[PROFESSIONAL_SITUATION]);
            // @ts-ignore
            forms[SKILLS].reset(defaultValues.skills);
            // @ts-ignore
            forms[DESCRIPTION].reset(defaultValues.description);
            // @ts-ignore
            forms[ADDITIONAL_INFORMATION].reset(defaultValues[ADDITIONAL_INFORMATION]);
        }
    }, [defaultValues]);

    const selectedProfessionalSituation = formValues[PROFESSIONAL_SITUATION];

    useEffect(() => {
        const defaultContextValue = initialState[DESCRIPTION].context;

        if (defaultValues?.description) {
            // @ts-ignore
            forms[DESCRIPTION].reset(defaultValues.description);
        } else {
            // set default context field value if default context is not already filled
            if (selectedProfessionalSituation && !defaultContextValue.getCurrentContent().hasText()) {
                const context = t("component.AddBriefDescriptionForm.defaultContext", {
                    targetedDomain: unCapitalize(selectedProfessionalSituation.targetedDomain),
                    description: unCapitalize(selectedProfessionalSituation.description),
                });

                // @ts-ignore
                forms[DESCRIPTION].reset({
                    context: EditorState.createWithContent(stateFromMarkdown(context)),
                });
            }
        }
    }, [selectedProfessionalSituation]);

    const additionalInformationNotCurrentStatus = isEqual(
        formValues[ADDITIONAL_INFORMATION],
        initialState[ADDITIONAL_INFORMATION],
    )
        ? "disabled"
        : "completed";

    const additionalInformationStatus: BriefStepStatus =
        currentStep === ADDITIONAL_INFORMATION ? "current" : additionalInformationNotCurrentStatus;

    const stepStatuses = {
        ...getBriefStepsStatuses(formValues, currentStep, t),
        "additional-information": additionalInformationStatus,
    };

    return (
        // @ts-ignore
        <FormProvider {...forms[currentStep]}>
            {children({
                currentStepData,
                stepStatuses,
                formValues,
                forms,
            })}
        </FormProvider>
    );
};
export default CreateBriefFormsManager;
