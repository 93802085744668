import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React from "react";
import { useId } from "react-aria";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SROnly } from "src/uikit";
import styled from "styled-components";
import * as yup from "yup";

import { Plus } from "@sol/icons";
import { IMediaResource, MediaType } from "@sol/sdk";
import { useMediaUpload } from "@sol/sdk/media";
import { IconButton, InputDecorator, TextInput } from "@sol/uikit";

const URLValidation = styled.div<{ showTitle?: boolean }>`
    display: flex;
    align-items: flex-end;
    margin-top: ${({ showTitle, theme }) => showTitle && theme.spacing[6]};

    ${InputDecorator} {
        flex-grow: 1;
    }
`;

const getSchema = (t: TFunction, medias: IMediaResource[]) => {
    return yup.object().shape({
        title: yup.string(),
        url: yup
            .string()
            .url()
            .test("unique", t("component.AddLinkForm.uniqueLinkErrorMessage"), value => {
                return !medias.some(media => media.url === value);
            })
            .required(),
    });
};

const defaultValues = { title: "", url: "" };

type FormValues = {
    title?: string;
    url: string;
};

type Props = {
    links: IMediaResource[];
    disabled?: boolean;
    onSubmit: (newLink: IMediaResource) => void;
    showTitleField?: boolean;
    className?: string;
};

const AddLinkForm = ({ links, disabled, onSubmit, showTitleField, className }: Props) => {
    const titleInputId = useId();
    const urlInputId = useId();
    const [t] = useTranslation();
    const [upload] = useMediaUpload({ onSuccess: ({ data }) => onSubmit(data as IMediaResource) });

    const { control, handleSubmit, trigger, reset } = useForm({
        defaultValues,
        resolver: yupResolver(getSchema(t, links)),
    });

    const onLinkSubmit = async ({ title, url }: FormValues) => {
        trigger(["url", "title"]); // trigger validation

        upload({ payload: { secured: false, type: MediaType.URL, url, title } });

        reset(defaultValues); // reset fields
    };

    return (
        <div className={className}>
            {showTitleField && (
                <Controller
                    control={control}
                    name="title"
                    render={({ field: { name, onChange, value, ...field }, fieldState: { invalid, error } }) => (
                        <InputDecorator
                            label={t("component.AddLinkForm.titleLabel")}
                            error={error}
                            htmlFor={titleInputId}
                        >
                            {({ errorId }) => (
                                <TextInput
                                    {...field}
                                    id={titleInputId}
                                    data-cy="title-field"
                                    value={value}
                                    onChange={onChange}
                                    aria-describedby={errorId}
                                    aria-invalid={invalid}
                                    variant={invalid ? "error" : "default"}
                                    autoFocus
                                />
                            )}
                        </InputDecorator>
                    )}
                />
            )}
            <URLValidation showTitle={showTitleField}>
                <Controller
                    control={control}
                    name="url"
                    render={({ field: { name, onChange, value, ...field }, fieldState: { invalid, error } }) => (
                        <InputDecorator
                            label={t("component.AddLinkForm.urlLabel")}
                            helper={t("component.AddLinkForm.urlFormatHelper")}
                            error={error}
                            htmlFor={urlInputId}
                        >
                            {({ errorId }) => (
                                <TextInput
                                    {...field}
                                    id={urlInputId}
                                    data-cy="url-field"
                                    value={value}
                                    onChange={onChange}
                                    placeholder={
                                        disabled
                                            ? t("component.AddLinkForm.placeholderDisabled")
                                            : t("component.AddLinkForm.urlPlaceholder")
                                    }
                                    aria-describedby={errorId}
                                    aria-invalid={invalid}
                                    variant={invalid ? "error" : "default"}
                                    autoFocus
                                />
                            )}
                        </InputDecorator>
                    )}
                />
                <IconButton
                    data-cy="add-link"
                    size="large"
                    type="button"
                    onClick={handleSubmit(onLinkSubmit)}
                    disabled={disabled}
                >
                    <Plus />
                    <SROnly>{t("component.AddLinkForm.addLinkSrOnly")}</SROnly>
                </IconButton>
            </URLValidation>
        </div>
    );
};

export default styled(AddLinkForm)`
    ${IconButton} {
        margin-top: ${({ theme }) => theme.spacing[7]};
    }

    ${InputDecorator} + ${InputDecorator} {
        margin-top: ${({ theme }) => theme.spacing[9]};
    }
`;
