import { FC, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import { BriefStatus } from "src/enums";
import { Text } from "src/uikit";
import styled from "styled-components";

import { Arrow, Check, Loading, Save } from "@sol/icons";
import { Button, Grid, GridColumn } from "@sol/uikit";

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const Actions = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[5]};
    margin-left: auto;
`;

const BottomActions = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[5]};
    justify-content: space-between;
`;

type Props = {
    onBackClick: () => void;
    onBriefSave: () => void;
    onDraftSave: () => void;
    className?: string;
    isDraftButtonLoading?: boolean;
    isSaveButtonLoading?: boolean;
    briefStatus: BriefStatus | undefined;
    isEdit: boolean;
    isCorrectedBriefSaveable: boolean;
    saveBriefButtonRef?: MutableRefObject<null>;
};

const CreateBriefSummaryLayout: FC<Props> = ({
    onBriefSave,
    onDraftSave,
    onBackClick,
    children,
    className,
    isDraftButtonLoading,
    isSaveButtonLoading,
    briefStatus,
    isEdit,
    isCorrectedBriefSaveable,
    saveBriefButtonRef,
}) => {
    const [t] = useTranslation();
    const { VALIDATED, DRAFT } = BriefStatus;
    const showDraftButton = briefStatus === DRAFT || !isEdit;
    const showRegisterButton = briefStatus === VALIDATED && !isCorrectedBriefSaveable;

    return (
        <div className={className}>
            <Heading>
                <Text as="h1" variant="h2">
                    {t("component.CreateBriefSummaryLayout.title")}
                </Text>
                <Actions>
                    {showDraftButton && (
                        <Button outlined variant="success" onClick={onDraftSave} loading={isDraftButtonLoading}>
                            {t("component.CreateBriefSummaryLayout.saveAsDraft")}
                            {isDraftButtonLoading ? <Loading /> : <Save />}
                        </Button>
                    )}
                    <Button filled variant="primary" onClick={onBriefSave} loading={isSaveButtonLoading}>
                        {t(`component.CreateBriefSummaryLayout.${!showRegisterButton ? "save" : "register"}`)}
                        {isSaveButtonLoading ? <Loading /> : <Check />}
                    </Button>
                </Actions>
            </Heading>

            {children}
            <Grid>
                <GridColumn as={BottomActions} col={8}>
                    <Button outlined variant="primary" type="button" onClick={onBackClick}>
                        <Arrow direction="left" />
                        {t("component.CreateBriefSummaryLayout.previousStep")}
                    </Button>
                    <Actions>
                        {showDraftButton && (
                            <Button outlined variant="success" onClick={onDraftSave}>
                                {t("component.CreateBriefSummaryLayout.saveAsDraft")}
                                <Save />
                            </Button>
                        )}
                        <Button
                            filled
                            ref={saveBriefButtonRef}
                            variant="primary"
                            data-cy="create-brief-bottom-save-button"
                            onClick={onBriefSave}
                            loading={isSaveButtonLoading}
                        >
                            {t(`component.CreateBriefSummaryLayout.${showRegisterButton ? "register" : "save"}`)}
                            {isSaveButtonLoading ? <Loading /> : <Check />}
                        </Button>
                    </Actions>
                </GridColumn>
            </Grid>
        </div>
    );
};

export default styled(CreateBriefSummaryLayout)`
    ${BottomActions} {
        margin-top: ${({ theme }) => theme.spacing[5]};
    }
`;
