import { useMemo } from "react";

import { useActiveClassroom } from "@sol/classrooms";
import { useFrameworks } from "@sol/sdk";

const useActiveClassroomFrameworks = () => {
    const { activeClassroom } = useActiveClassroom();

    const frameworksIds = useMemo(() => {
        if (!activeClassroom) {
            return [];
        }
        return activeClassroom.frameworks.map(f => f["@id"]);
    }, [activeClassroom]);

    const { data: frameworks } = useFrameworks({});

    return frameworks ? frameworks["hydra:member"].filter(f => frameworksIds.includes(f["@id"])) : [];
};

export default useActiveClassroomFrameworks;
