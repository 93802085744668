import React, { ComponentPropsWithoutRef } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Merge } from "type-fest";

import { Cross, Link as LinkIcon, Paperclip } from "@sol/uikit/core/icons";
import { Typography } from "@sol/uikit/core/Typography";
import { IconButton } from "@sol/uikit/molecules/IconButton";

type Props = Merge<
    ComponentPropsWithoutRef<"div">,
    {
        kind: "media" | "link";
        title?: string;
        size?: number;
        url?: string;
        onClose?: () => void;
    }
>;

const Attachment = ({ kind, onClose, url, title, size, ...props }: Props) => {
    const [t] = useTranslation();
    const attachmentTitleId = useId();

    return (
        <div
            {...props}
            className="flex h-14 max-w-full items-center gap-3 rounded-full border border-grey-lightest bg-white-base p-4"
        >
            {kind === "link" ? (
                <LinkIcon stroke="purple-base" fill="transparent-base" className="!bg-grey-lightest" />
            ) : (
                <Paperclip stroke="purple-base" fill="transparent-base" className="!bg-grey-lightest" />
            )}
            <div className="flex flex-1 items-center">
                <Typography
                    tag="a"
                    title={title ?? url}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={url}
                    variant="subHeading"
                    {...(kind === "link" ? undefined : { download: title })}
                    id={attachmentTitleId}
                    className="focus-classes line-clamp-2 w-fit break-all rounded-md text-purple-base outline-none hover:underline"
                >
                    {title ?? url}
                </Typography>
            </div>
            {size && (
                <Typography tag="span" variant="subHeading" className="text-grey-base">
                    {t("component.workspaces.FeedItemAttachment.size", { size })}
                </Typography>
            )}
            {onClose && (
                <IconButton
                    type="button"
                    variant="secondary"
                    onPress={() => onClose()}
                    label={t("component.workspaces.FeedItemAttachment.onClose")}
                    aria-describedby={attachmentTitleId}
                    labelVisible={false}
                >
                    <Cross fill="purple-base" stroke="purple-base" />
                </IconButton>
            )}
        </div>
    );
};

export default styled(Attachment)``;
